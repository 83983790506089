import {DraftForm, DraftsFilters} from "../types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";

export interface DraftsInitialState {
    filters: DraftsFilters;
    draftForm: DraftForm;
}

export const initialDraftFormState: DraftsInitialState = {
    filters: {
        page: 1,
        nameCont: '',
        mediaFormatIn: [],
        agglomerationsNameIn: [],
        tagsNameIn: [],
        citiesNameIn: [],
        mediaAddressIn: [],
        mediaAsIdIn: [],
        buildingsNameIn: []
    },
    draftForm: {
        id: null,
        name: '',
        mediaFormat: undefined,
        tagList: [],
        adTags: [],
        draftPropertiesAttributes: [],
        localisablesMediatablesAttributes: [],
        hostContent: false
    }
}

export const draftsSlice = createSlice({
    name: "drafts",
    initialState: initialDraftFormState,
    reducers: {
        setFilters: (state, action: PayloadAction<DraftsFilters>) => {
            state.filters = action.payload
        },
        setDraftForm: (state, action: PayloadAction<DraftForm>) => {
            state.draftForm = action.payload
        }
    }
})

export const {setFilters, setDraftForm} = draftsSlice.actions;

export const selectCurrentFiltersState = (state: RootState) => state.drafts.filters;
export const selectDraftForm = (state: RootState) => state.drafts.draftForm;

