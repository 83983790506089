import {baseApi} from "../../app/api/baseApi";
import {AuthResponse, LoginFetchArgs, LoginPayload} from "./types";
import {setCredentials} from "./authSlice";
import {store} from "../../store";


export const authApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<AuthResponse, LoginPayload>({
            query: (authPayload: LoginPayload): LoginFetchArgs => {
                return {
                    url: '/oauth/token',
                    method: 'POST',
                    body: {...authPayload, grantType: 'password'}
                }
            },
            transformResponse: (loginResponse: AuthResponse) => {
                return loginResponse
            }
        }),
        refreshToken: builder.mutation<AuthResponse, string>({
            query: (refreshToken)  => {
                return {
                    url: '/oauth/token',
                    method: 'POST',
                    body: {refresh_token: refreshToken, grant_type: 'refresh_token'}
                }
            },
            transformResponse: (authResponse: AuthResponse, meta) => {
                const {refreshToken, accessToken, expiresIn, createdAt, profile} = authResponse;
                if (authResponse) {
                    store.dispatch(setCredentials({ accessToken, refreshToken, expiresIn, createdAt, profile}));
                }
                return authResponse
            }
        }),
    })
})

export const {useLoginMutation, useRefreshTokenMutation} = authApiSlice