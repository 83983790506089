import React, {useState} from "react";
import {VoohDragAndDropTableProps} from "./types";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import {ADVERTISEMENTS_COLOR, BUMPER_COLOR, MIN_ADVERTISEMENTS, VOOH_SCHEDULE_HEIGHT} from "../../../config/vooh";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {VoohItemType} from "../types";
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    ListItemButton,
    ListItemText,
    SelectChangeEvent,
    TableSortLabel,
    TextField,
    Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Link from "@mui/material/Link";
import {MediabankResource, MediabankResourceTableRow, ResourceType} from "../../mediabank/types";
import {useTranslation} from "react-i18next";
import {BreadCrumb} from "../../mediabank/store/mediabankResourcesSlice";
import {useGetMediabankResourcesForSelectQuery} from "../../mediabank/api/mediabankResourcesApiSlice";
import {visuallyHidden} from "@mui/utils";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {Thumbnail} from "../../../config/images";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import dayjs from "dayjs";
import {formatDuration} from "../../../config/general";
import {draggingOverStyle} from "../../../config/table";

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof MediabankResourceTableRow) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof MediabankResourceTableRow;
    label: string;
    numeric: boolean;
    sort: boolean;
    width: string;
}

const VoohDragAndDropTable: React.FC<VoohDragAndDropTableProps> = ({
                                                                       handleDragEnd,
                                                                       scheduleRows,
                                                                       setVoohItems,
                                                                       setRollbackAdvNumber,
                                                                       setAdvertisingBlocksNumber,
                                                                       voohCategories,
                                                                       advertisingBlocksNumber,
                                                                       onFullScreenClick,
                                                                       hideMediabank,
                                                                       fullScreenMode = false,
                                                                       bumper

                                                                   }) => {

    const mediabankBreadcrumbsInitialState = [{
        dirName: 'Katalog główny',
        resourceId: null
    }]
    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Nazwa folderu',
            sort: true,
            width: '34'
        },
        {
            id: 'byteSize',
            numeric: false,
            disablePadding: true,
            label: 'Rozmiar',
            sort: true,
            width: '3'
        },
        {
            id: 'metadata',
            numeric: false,
            disablePadding: true,
            label: 'Rozdzielczość',
            sort: false,
            width: '8'
        },
        {
            id: 'tagList',
            numeric: true,
            disablePadding: false,
            label: 'Tagi',
            sort: false,
            width: '15'
        },
        {
            id: 'categoryId',
            numeric: true,
            disablePadding: false,
            label: 'Kategoria',
            sort: false,
            width: '20'
        },
        {
            id: 'updatedAt',
            numeric: true,
            disablePadding: false,
            label: 'Data wgrania',
            sort: true,
            width: '15'
        },
        {
            id: 'used',
            numeric: true,
            disablePadding: false,
            label: '',
            sort: true,
            width: '4'
        },
    ];


    const [showFileExplorer, setShowFileExplorer] = useState<boolean>(true);
    const [selected, setSelected] = React.useState<number[]>([]);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof MediabankResourceTableRow>('name');
    const [breadcrumbsForMediabankDialog, setBreadcrumbsForMediabankDialog] = React.useState<BreadCrumb[]>(mediabankBreadcrumbsInitialState);
    const [parentIdForForMediabankDialog, setParentIdForMediabankDialog] = React.useState<string | null>(null);

    const {t} = useTranslation();

    const getItemStyle = (isDragging: any, draggableStyle: any, voohItemType: VoohItemType, categoryId?: number | null) => {

        let voohRowColor;

        if (voohItemType === VoohItemType.Advertisement) {
            voohRowColor = ADVERTISEMENTS_COLOR
        } else if (voohItemType === VoohItemType.Bumper) {
            voohRowColor = BUMPER_COLOR
        } else {
            voohRowColor = voohCategories?.find(el => el.id === categoryId)?.color
        }

        return {
            // some basic styles to make the items look a bit nicer
            userSelect: "none",

            // change background colour if dragging
            background: isDragging ? (voohRowColor || '#e5e6eb') : (voohRowColor || '#ffffff'),
            display: isDragging ? 'table' : 'table-row',
            textAlign: isDragging ? 'right' : 'none',

            // styles we need to apply on draggables
            ...draggableStyle
        }
    }
    const handleCheck = (event: React.MouseEvent<unknown> | null, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const wieghtSum = Math.round(scheduleRows?.reduce((sum, current) => sum + current.byteSize!, 0)! / 10000) / 100
    const sumSec = scheduleRows?.reduce((sum, current) => sum + (current?.duration || 0), 0)!

    function secondsToTime(secondsTotal: number) {
        const m = Math.floor(secondsTotal % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(secondsTotal % 60).toString().padStart(2, '0');

        return m + ':' + s;
    }

    function getTimePeriod(index: number) {
        let slicedSchedulePeriodLeft = scheduleRows.slice(0, (index));
        let slicedSchedulePeriodRight = scheduleRows.slice(0, (index + 1));
        const startAt = slicedSchedulePeriodLeft?.reduce((sum, current) => sum + (current?.duration || 0), 0) || 0
        const endAt = slicedSchedulePeriodRight?.reduce((sum, current) => sum + (current?.duration || 0), 0) || 0
        if (index === 0) {
            return '00:00' + ' - ' + secondsToTime(endAt)
        }
        return secondsToTime(startAt) + '  -  ' + secondsToTime(endAt)
    }

    const getVoohListItemCellStyle = (isDragging: boolean) => ({
        width: isDragging ? '14.3%' : 0,
    });

    function getComparator<Key extends keyof any>(order: Order, orderBy: Key,): (a: any, b: any) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }


    const getMediabankItemStyle = (isDragging: any, draggableStyle: any) => ({

        transform: 'none !important',
        // change background colour if dragging
        background: isDragging ? 'lightgreen' : null,
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getMediabankListItemCellStyle = (isDragging: any, widthPercentage: number) => ({
        borderBottom: isDragging ? 0 : '1px solid rgba(224, 224, 224, 1)',
        width: widthPercentage + '%',
    });

    const getResourceImage = (row: MediabankResource) => {
        if (row.resourceType === ResourceType.Dir) {
            return <FolderOutlinedIcon fontSize="large"/>
        }

        if (row.resourceType === ResourceType.File && !row.thumbnailUrl) {
            return <DescriptionOutlinedIcon fontSize="large"/>
        }

        return <Box sx={{
            height: Thumbnail.width,
            width: Thumbnail.width,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: Thumbnail.background
        }}><img
            width={Thumbnail.width}
            src={`${row.thumbnailUrl}`}
            alt={row.name}
            loading="lazy"/>
        </Box>
    }

    const {isLoading: loadingResourcesForMediabankDialog, data: resourcesDataForMediabankDialog, isFetching} =
        useGetMediabankResourcesForSelectQuery(parentIdForForMediabankDialog);
    const mediabankResources = resourcesDataForMediabankDialog?.data;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && mediabankResources && mediabankResources.some(el => el.resourceType === ResourceType.File)) {
            const newSelected = mediabankResources?.filter(el => el.resourceType === ResourceType.File).map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof MediabankResourceTableRow,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = React.useMemo(
        () =>
            mediabankResources ? [...mediabankResources]
                .sort(getComparator(order, orderBy)) : [],
        [order, orderBy, mediabankResources]
    );


    function EnhancedTableHead(props: EnhancedTableProps) {
        const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
            props;
        const createSortHandler =
            (property: keyof MediabankResourceTableRow) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead>
                <TableRow>
                    <TableCell size="small" padding="checkbox" sx={{width: '1%'}}>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell size="small"
                                   width={headCell.width}
                                   key={headCell.id}
                                   align={'left'}
                                   sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sort ? (<TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>) : headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const mediabankDraggableItem = (provider: any, snapshot: any, row: any, isItemSelected: boolean, labelId: string, copy: boolean) => {

        const draggableProps = copy ? {} : provider.draggableProps;
        const dragHandleProps = row.resourceType === ResourceType.Dir ? {} : provider.dragHandleProps;

        return (<TableRow
            className={{
                dragging: snapshot.isDragging,
                ['dragging-multiple']: snapshot.isDragging && !copy && selected.length > 0 && isItemSelected
            }}
            key={`${copy ? row.id + '_copy' : row.id}`}
            {...draggableProps}
            hover
            onClick={(event: React.ChangeEvent<any>) => {
                if (row.resourceType === ResourceType.Dir) {
                    setParentIdForMediabankDialog(row.id.toString())
                    setBreadcrumbsForMediabankDialog([...breadcrumbsForMediabankDialog, {
                        dirName: row.name,
                        resourceId: row.id.toString()
                    }])
                    setSelected([])
                } else {
                    handleCheck(null, row.id)
                }
            }}
            onDrag={() => {
                if (row.resourceType === ResourceType.File) {
                    handleCheck(null, row.id)
                }
            }}
            role="row"
            data-content={snapshot.isDragging && !copy && selected.length > 0 ? selected.length : null}
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            style={copy ? null : getMediabankItemStyle(
                snapshot.isDragging,
                provider.draggableProps.style,
            )}
            sx={{
                "&.MuiTableRow-hover:hover": {
                    backgroundColor: voohCategories?.filter(el => el.id === row.categoryId)[0]?.color || '#f3f4f7',
                    opacity: '0.8'
                },
                cursor: 'pointer',
                backgroundColor: voohCategories?.filter(el => el.id === row.categoryId)[0]?.color || 'inherit'
            }}
            ref={copy ? null : provider.innerRef}
        >
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                           1
                       )}
                       padding="checkbox"   {...dragHandleProps}>
                {row.resourceType === ResourceType.File ? (
                    <Checkbox
                        color="primary"
                        onClick={(event) => handleCheck(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                    />) : (<SubdirectoryArrowRightIcon
                    sx={{paddingLeft: '10px'}}></SubdirectoryArrowRightIcon>)}
            </TableCell>
            <TableCell size="small" component="th" scope="row"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                           34
                       )}
                       id={labelId}
                       {...dragHandleProps}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                }}>{getResourceImage(row)}<Typography sx={{maxWidth: '120px', fontSize: '14px'}}>{row.name}</Typography>
                </Box>
            </TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                           3
                       )}
                       align="left"
                       {...dragHandleProps}
            >{row.byteSizeHumanized}</TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                           8
                       )}
                       align="left"
                       {...dragHandleProps}
            >{row.metadata?.height ? (`${row.metadata?.height} x ${row.metadata?.width} px`) : ''}</TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                           15
                       )}
                       align="right"
                       {...dragHandleProps}> {row.tagList.length > 0 ? row.tagList.join(', ') : ''}</TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                           20
                       )}
                       align="right"
                       {...dragHandleProps}>{voohCategories?.filter(el => el.id === row.categoryId)[0]?.name} </TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                           15
                       )}
                       align="right"
                       {...dragHandleProps}>{row.updatedAt ? row.updatedAt : ''}</TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                           4
                       )}
                       align="right"
                       {...dragHandleProps}>
                {(row.resourceType === ResourceType.File && scheduleRows.some(el => el.mediabankResourceId === row.id && !el._destroy)) && (
                    <CheckCircleIcon sx={{color: '#29ccc0', fontSize: '24px', fontWeight: 'bold'}}></CheckCircleIcon>
                )}


            </TableCell>


        </TableRow>)
    }


    return (<>
            {mediabankResources && (<DragDropContext onDragEnd={(event) => {
                handleDragEnd(event, visibleRows, mediabankResources, selected);
            }}>
                <TableContainer component={Paper} sx={{height: fullScreenMode ? '100%' : (bumper ? `calc(${VOOH_SCHEDULE_HEIGHT} + 48px)` : VOOH_SCHEDULE_HEIGHT)}}>
                    <Table stickyHeader className={'VoohForm__droppable-table'}
                           sx={{minWidth: 650, height: scheduleRows.length === 0 ? '100%' : 'auto'}}
                           aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={'2%'} size="small">
                                    {!fullScreenMode && (<IconButton aria-label="fullscreen"
                                                                     style={{padding: '1%'}}
                                                                     onClick={onFullScreenClick}
                                                                     color="primary">

                                        <FullscreenIcon/>
                                    </IconButton>)}</TableCell>
                                <TableCell width={'25%'} size="small">Nazwa</TableCell>
                                <TableCell width={'9%'} size="small" align="right">Suma   <b>{wieghtSum || ''} MB</b></TableCell>
                                <TableCell width={'14%'} size="small" align="right">Przedział czasowy</TableCell>
                                <TableCell width={'15%'} size="small"
                                           align="right">Suma <br/> <b>{sumSec ? (formatDuration(sumSec)) : ''}</b></TableCell>
                                <TableCell width={'15%'} size="small" align="right">Kategoria</TableCell>
                                <TableCell width={'20%'} size="small" align="right">
                                    Akcje
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <Droppable droppableId="droppable-1">
                            {(provider: any, snapshot: any) => (
                                <TableBody className={'vooh-table-body'}
                                           ref={provider.innerRef} {...provider.droppableProps}
                                           style={draggingOverStyle(snapshot.isDraggingOver)}>

                                    {scheduleRows.length === 0 && <TableRow>
                                        <TableCell size="small" colSpan={7}></TableCell>

                                    </TableRow>}

                                    {scheduleRows.filter(el => !el._destroy).map((row, index) => (
                                        <Draggable key={row.uuid} draggableId={row.uuid} index={index}>
                                            {(provider, snapshot) => (
                                                <TableRow
                                                    key={row.uuid}
                                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                                    {...provider.draggableProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provider.draggableProps.style,
                                                        row.voohItemType,
                                                        row.categoryId,
                                                    )}
                                                    // style={snapshot.isDragging ? {background: 'red'} : {}}
                                                    ref={provider.innerRef}
                                                >
                                                    <TableCell width={'1%'} size="small"
                                                               component="th"
                                                               scope="row"
                                                               {...provider.dragHandleProps}
                                                    >
                                                        {/*<DragIndicatorIcon/>*/}
                                                    </TableCell>
                                                    <TableCell
                                                        {...provider.dragHandleProps}
                                                        width={'49%'} size="small"
                                                        style={{
                                                            width: snapshot.isDragging ? '70%' : 0,
                                                        }}>{row.voohItemType === VoohItemType.Bumper ? 'BUMPER - ' + row.name : row.name}</TableCell>
                                                    <TableCell
                                                        {...provider.dragHandleProps}
                                                        width={'1%'} size="small"
                                                        style={getVoohListItemCellStyle(
                                                            snapshot.isDragging
                                                        )} align="right">{row.byteSizeHumanized}</TableCell>
                                                    <TableCell
                                                        {...provider.dragHandleProps}
                                                        width={'10%'} size="small"
                                                        style={getVoohListItemCellStyle(
                                                            snapshot.isDragging
                                                        )}
                                                        align="right"> {row.voohItemType !== VoohItemType.Advertisement && getTimePeriod(index)} </TableCell>
                                                    <TableCell
                                                        {...provider.dragHandleProps}
                                                        width={'19%'} size="small"
                                                        style={getVoohListItemCellStyle(
                                                            snapshot.isDragging
                                                        )}
                                                        align="right">{row.voohItemType !== VoohItemType.Advertisement && (row.editableDuration ? (
                                                        <TextField
                                                            size={'small'}
                                                            value={row.duration}
                                                            type={"number"}
                                                            sx={{width: '70px'}}
                                                            label="sek"
                                                            onInput={(event: any) => {
                                                                if (event.target.value < 0) {
                                                                    return
                                                                }

                                                                setVoohItems(scheduleRows.map((el) => {
                                                                    if (el.uuid === row.uuid) {
                                                                        return {
                                                                            ...el,
                                                                            duration: parseInt(event.target.value)
                                                                        }
                                                                    }
                                                                    return el
                                                                }))
                                                            }}
                                                            variant="filled"
                                                        />) : formatDuration(row.duration)) }</TableCell>
                                                    <TableCell
                                                        {...provider.dragHandleProps}
                                                        width={'10%'} size="small"
                                                        style={getVoohListItemCellStyle(
                                                            snapshot.isDragging
                                                        )} align="right">

                                                        {row.voohItemType === VoohItemType.MediabankResource && (
                                                            <FormControl sx={{width: '120px'}}>
                                                                <InputLabel
                                                                    id="demo-simple-select-label">{t('vooh.form.category')}</InputLabel>
                                                                <Select
                                                                    size="small"
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={row.categoryId?.toString() || ''}
                                                                    label={t('vooh.form.category')}
                                                                    onChange={(event: SelectChangeEvent) => {
                                                                        setVoohItems(scheduleRows.map((el => {
                                                                            if (el.uuid === row.uuid) {
                                                                                el.categoryId = event.target.value ? parseInt(event.target.value) : null
                                                                            }
                                                                            return el
                                                                        })))
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        value={''}><em>{t('common.noValue')}</em></MenuItem>
                                                                    {
                                                                        voohCategories?.map((category) => {
                                                                            return (<MenuItem
                                                                                key={category.id}
                                                                                value={category.id}>{category.name}</MenuItem>)
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>)}

                                                    </TableCell>
                                                    <TableCell
                                                        {...provider.dragHandleProps}
                                                        width={'10%'} size="small"
                                                        style={getVoohListItemCellStyle(
                                                            snapshot.isDragging
                                                        )} align="right"><Button
                                                        color={row.voohItemType === VoohItemType.Advertisement ? 'inherit' : 'error'}
                                                        variant="text"
                                                        onClick={() => {
                                                            if (row.voohItemType === VoohItemType.Advertisement && advertisingBlocksNumber === MIN_ADVERTISEMENTS) {
                                                                return
                                                            }
                                                            const newScheduleRows = scheduleRows.filter((el) => el.uuid !== row.uuid)
                                                            setVoohItems(scheduleRows, row)
                                                            if (row.voohItemType === VoohItemType.Advertisement) {
                                                                setRollbackAdvNumber(true)
                                                                setAdvertisingBlocksNumber(newScheduleRows.filter((el) => el.voohItemType === VoohItemType.Advertisement).length)
                                                            }
                                                        }}
                                                    ><strong>{t('common.remove')}</strong></Button></TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provider.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </Table>
                </TableContainer>
                {!hideMediabank && (<Paper sx={showFileExplorer ? {} : {display: 'flex', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <IconButton sx={{marginX: '4px'}} onClick={() => {
                                setShowFileExplorer(!showFileExplorer)
                            }} aria-label="expand">
                                {showFileExplorer ? (<ArrowForwardIosIcon/>) : (<ArrowBackIosIcon/>)}
                            </IconButton>
                        </Box>
                        <Box sx={{display: showFileExplorer ? 'block' : 'none'}}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{padding: '10px'}}>
                                {breadcrumbsForMediabankDialog.length && breadcrumbsForMediabankDialog.map((breadcrumb, index) => {
                                    return index !== breadcrumbsForMediabankDialog.length - 1 ? (
                                        <Link key={breadcrumb.dirName} underline="hover"
                                              color="inherit"
                                              onClick={() => {
                                                  setParentIdForMediabankDialog(breadcrumb.resourceId)
                                                  setBreadcrumbsForMediabankDialog(breadcrumbsForMediabankDialog.slice(0, index + 1))
                                                  setSelected([])
                                              }} component="button">
                                            {breadcrumb.dirName}
                                        </Link>
                                    ) : (<Typography key={breadcrumb.dirName}
                                                     sx={{color: 'text.primary'}}>{breadcrumb.dirName}</Typography>)
                                })}
                            </Breadcrumbs>
                            {
                                (mediabankResources) && (
                                    <>
                                        <TableContainer
                                            sx={{width: '900px', height: `calc(${VOOH_SCHEDULE_HEIGHT} - 102px)`}}>
                                            {isFetching ? (<Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '100%',
                                                alignItems: 'center'
                                            }}>
                                                <CircularProgress size="30px"/>
                                            </Box>) : (<Table stickyHeader={true} aria-label="simple table">
                                                <EnhancedTableHead
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={mediabankResources.filter(el => el.resourceType === ResourceType.File).map((n) => n.id).length}
                                                />

                                                <Droppable droppableId="droppable-2">
                                                    {(provider: any) => (
                                                        <TableBody ref={provider.innerRef} {...provider.droppableProps}
                                                                   className={'mediabank-draggable-list'}>
                                                            {visibleRows.map((row, index) => {
                                                                const isItemSelected = selected.includes(row.id);
                                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                                return (
                                                                    <Draggable key={row.id.toString()}
                                                                               draggableId={row.id.toString()}
                                                                               index={index}>
                                                                        {(provider, snapshot) => (
                                                                            <>
                                                                                {
                                                                                    mediabankDraggableItem(provider, snapshot, row, isItemSelected, labelId, false)
                                                                                }
                                                                                {
                                                                                    snapshot.isDragging ? mediabankDraggableItem(provider, snapshot, row, isItemSelected, labelId, true) : null
                                                                                }

                                                                            </>

                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })}
                                                            {provider.placeholder}
                                                        </TableBody>
                                                    )}
                                                </Droppable>
                                            </Table>)}
                                        </TableContainer>
                                        <Droppable droppableId="droppable-3">
                                            {(provider: any) => (
                                                <div ref={provider.innerRef} {...provider.droppableProps}>
                                                    <Draggable key={'advertisement'}
                                                               draggableId={'advertisement'}
                                                               index={0}
                                                    >
                                                        {(provider, snapshot) => (
                                                            <>
                                                                <ListItemButton
                                                                    component="a" sx={{
                                                                    backgroundColor: ADVERTISEMENTS_COLOR,
                                                                    display: snapshot.isDragging ? 'block' : 'none',
                                                                    marginTop: '10px'
                                                                }}>
                                                                    <ListItemText
                                                                        primary="REKLAMA"/>

                                                                </ListItemButton>
                                                                <ListItemButton
                                                                    ref={provider.innerRef}  {...provider.dragHandleProps} {...provider.draggableProps}
                                                                    component="a" sx={{
                                                                    backgroundColor: ADVERTISEMENTS_COLOR,
                                                                    marginTop: '10px'
                                                                }}>
                                                                    <ListItemText
                                                                        primary="REKLAMA"/>

                                                                </ListItemButton>
                                                            </>
                                                        )}
                                                    </Draggable>
                                                </div>
                                            )}
                                        </Droppable>
                                        {bumper && (<Droppable droppableId="droppable-4">
                                            {(provider: any) => (
                                                <div ref={provider.innerRef} {...provider.droppableProps}>
                                                    <Draggable key={'bumper'}
                                                               draggableId={'bumper'}
                                                               index={0}
                                                    >
                                                        {(provider, snapshot) => (
                                                            <>
                                                                <ListItemButton
                                                                    component="a" sx={{
                                                                    backgroundColor: BUMPER_COLOR,
                                                                    display: snapshot.isDragging ? 'block' : 'none',
                                                                }}>
                                                                    <ListItemText
                                                                        primary={'BUMPER - ' + bumper.name}/>

                                                                </ListItemButton>
                                                                <ListItemButton
                                                                    disabled={scheduleRows.filter(el => el.voohItemType === VoohItemType.Bumper).length === scheduleRows.filter(el => el.voohItemType === VoohItemType.Advertisement || !el.mediabankResourceId).length }
                                                                    ref={provider.innerRef}  {...provider.dragHandleProps} {...provider.draggableProps}
                                                                    component="a" sx={{
                                                                    backgroundColor: BUMPER_COLOR,
                                                                }}>
                                                                    <ListItemText
                                                                        primary={'BUMPER - ' + bumper.name}/>

                                                                </ListItemButton>
                                                            </>
                                                        )}
                                                    </Draggable>
                                                </div>
                                            )}
                                        </Droppable>)}

                                    </>
                                )
                            }</Box>
                    </Box>

                </Paper>)}
            </DragDropContext>)}

        </>

    )
}

export default VoohDragAndDropTable;