import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CreationForm, CreationsFilters} from '../types';
import {RootState} from '../../../store';

export interface CreationsInitialState {
    filters: CreationsFilters;
    creationForm: CreationForm;
}

export const initialCreationFormState: CreationsInitialState = {
    filters: {
        page: 1,
        nameCont: '',
        draftIdEq: null,
        tagsNameIn: [],
        draftMediaFormatIn: [],
        stateIn: [],
    },
    creationForm: {
        id: null,
        name: '',
        startAt: '',
        endAt: '',
        timeOfDay: '',
        weather: '',
        intensity: '',
        creationPropertiesAttributes: [],
        localisablesMediatablesAttributes: [],
        ownershipsAttributes: [],
        possibleEvents: [],
        state: 'draft',
        commentAttributes: {
            body: ''
        },
        adTags: [],
        tagList: []
    }
}


export const creationsSlice = createSlice({
    name: "creations",
    initialState: initialCreationFormState,
    reducers: {
        setCreationsFilters: (state, action: PayloadAction<CreationsFilters>) => {
            state.filters = action.payload
        },
        setCreationForm: (state, action: PayloadAction<CreationForm>) => {
            state.creationForm = action.payload
        }
    }
})
export const { setCreationForm, setCreationsFilters} = creationsSlice.actions;

export const selectCreationForm = (state: RootState) => state.creations.creationForm;
export const selectCurrentCreationsFiltersState = (state: RootState) => state.creations.filters;
