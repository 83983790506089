import React, {useEffect} from 'react';
import {
    useCreateDraftMutation,
    useDeleteDraftMutation,
    useGetDraftsFiltersMetaQuery,
    useGetDraftsQuery
} from "../api/draftsApiSlice";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {DraftListProps, DraftsFilters, FilterItemChip} from "../types";
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Grid,
    IconButton,
    TablePagination,
    TextField, Tooltip, tooltipClasses, TooltipProps, Typography
} from "@mui/material";
import './DraftsList.scss'
import Chip from '@mui/material/Chip';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {styled, Theme, useTheme} from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import {useDispatch, useSelector} from "react-redux";
import {initialDraftFormState, selectCurrentFiltersState, setDraftForm, setFilters} from "../store/draftsSlice";
import {useTranslation} from "react-i18next";
import {Clear} from "@mui/icons-material";
import useDebounce from "../../../hooks/useDebounce";
import {TABLE_ROW_LENGTH_OPTIONS} from "../../../config/table";
import {Link} from 'react-router-dom';
import {enqueueSnackbar} from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import HtmlTooltip from "../../shared/htmlTooltip/htmlTooltip";
import {initialCreationFormState, setCreationsFilters} from "../../creations/store/creationsSlice";

const DraftsList: React.FC<DraftListProps> = ({
                                                  showHeader = true,
                                                  showCrudActions = true,
                                                  selectable = false,
                                                  greyOutNotCompleted = false,
                                                  onRowClick,
                                                  activeDraftId
                                              }) => {

    const [personName, setPersonName] = React.useState<string[]>([]);
    const [searchByName, setSearchByName] = React.useState<string>('');
    const currentFiltersState = useSelector(selectCurrentFiltersState);
    const [skipFetchingDrafts, setSkipFetchingDrafts] = React.useState(false);
    const [rowLength, setRowLength] = React.useState(TABLE_ROW_LENGTH_OPTIONS[0]);
    const page = currentFiltersState.page;
    let params = [`items=${rowLength.toString()}`];
    let filterItemChips: FilterItemChip[] = []
    for (const queryKey in currentFiltersState) {
        const value = currentFiltersState[queryKey as keyof typeof currentFiltersState]
        if (Array.isArray(value)) {
            value.forEach(item => {
                params.push(`q[${queryKey}][]=${item.toString()}`)
                filterItemChips.push({name: queryKey, value: item, isArray: true})
            })
        } else if (queryKey === 'page') {
            params.push(`page=${value.toString()}`)
        } else if (value) {
            params.push(`q[${queryKey}]=${value.toString()}`)
            filterItemChips.push({name: queryKey, value: value.toString(), isArray: false})
        }
    }
    const [deleteDraft, {isLoading: deletingDraft}] = useDeleteDraftMutation()

    const {isLoading, isError, isSuccess, data} =
        useGetDraftsQuery(params.join('&').toString(), {skip: skipFetchingDrafts});

    const {
        isLoading: loadingFiltersMeta,
        isError: isFiltersMetaError,
        isSuccess: isSuccessFiltersMeta,
        data: filtersMetaData
    } =
        useGetDraftsFiltersMetaQuery();

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation(); // not passing any namespace will use the defaultNS (by default set to 'translation')
    const [selectedDraftToRemove, setSelectedDraftToRemove] = React.useState<number | null>(null);

    const handleClickOpenDeleteDraftModal = (draftId: number) => {
        setSelectedDraftToRemove(draftId);
    };

    const handleCloseDeleteDraftModal = (remove: boolean) => {
        if (remove && selectedDraftToRemove) {
            deleteDraftMutation(selectedDraftToRemove);
        }
        setSelectedDraftToRemove(null);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const drafts = data?.data
    const theme = useTheme();

    const incompleteReasons = {
        ['no_draft_file']: 'Brak pliku schematu',
        ['no_media']: 'Brak wybranych nośników'
    }

    function handleChangePage() {

    }

    useEffect(() => {
        return () => {
            dispatch(setFilters({
                ...initialDraftFormState.filters
            }))
        }
    }, []);

    useDebounce(() => {
            dispatch(setFilters({
                ...currentFiltersState,
                nameCont: searchByName,
                page: 1
            }))
        }, [searchByName], 800
    );

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowLength(parseInt(event.target.value, 10))
    }

    const deleteDraftMutation = async (draftId: number) => {
        try {
            const isLastItem = drafts?.length === 1;
            if (isLastItem && page > 0) {
                setSkipFetchingDrafts(true);
            }
            const result = await deleteDraft(draftId)
            if (!result?.error) {
                if (isLastItem && page > 0) {
                    setSkipFetchingDrafts(false);
                    dispatch(setFilters({
                        ...currentFiltersState,
                        page: page - 1
                    }))
                }
                enqueueSnackbar('Pomyślnie usunięto schemat', {variant: 'success'});
            }
        } catch (err: any) {
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }


    function removeFilter(filterElement: FilterItemChip) {
        if (filterElement.isArray) {
            dispatch(setFilters({
                ...currentFiltersState,
                page: 1,
                [filterElement.name]: (currentFiltersState[filterElement.name as keyof DraftsFilters] as string[]).filter(el => el !== filterElement.value)
            }))
        } else {
            if (filterElement.name === 'nameCont') {
                setSearchByName('');
            }
            dispatch(setFilters({
                ...currentFiltersState,
                page: 1,
                [filterElement.name]: ''
            }))
        }
    }


    function getStyles(name: string, personName: readonly string[], theme: Theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    return (
        <Box className="DraftsList">
            {showHeader &&
                <><Box className={'page-header'}>
                    <h1>{t("drafts.schemaSelection")}</h1>
                    <p>Wybierz schemat dla nowej kreacji</p>
                </Box></>
            }

            {(isLoading || loadingFiltersMeta) && (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                    <CircularProgress size={100}/>
                </Box>)}

            {isError && (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                    <span>Error occurred...</span>
                </Box>)}

            {(drafts && filtersMetaData) && (<>
                <Grid container spacing={2}>
                    <Grid item xs={4} alignItems="baseline">
                        <TextField
                            size="small"
                            value={searchByName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchByName(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setSearchByName('')
                                        dispatch(setFilters({
                                            ...currentFiltersState,
                                            nameCont: searchByName
                                        }))
                                    }
                                    }
                                    edge="end"
                                >
                                    {<Clear/>}
                                </IconButton>,
                            }}
                            margin="normal"
                            variant={"outlined"}
                            required
                            fullWidth
                            id="draftsName"
                            label="Nazwa schematu"
                            name="draftsName"
                            autoFocus></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth sx={{marginTop: '15px'}}>
                            <InputLabel shrink id="demo-simple-select-label">Format nośnika</InputLabel>
                            <Select
                                size="small"
                                variant={"outlined"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Format nośnika"
                                multiple
                                value={currentFiltersState.mediaFormatIn || ''}
                                notched
                                onChange={(event) => {
                                    dispatch(setFilters({
                                        ...currentFiltersState,
                                        mediaFormatIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                    }))
                                }}
                            >
                                {filtersMetaData.data.filtering.fields.mediaFormatIn.allowedValues.map((key) => (
                                    <MenuItem
                                        key={key}
                                        value={key}>{key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth sx={{marginTop: '15px'}}>
                            <InputLabel shrink id="demo-simple-select-label">Aglomeracja</InputLabel>
                            <Select
                                size="small"
                                variant={"outlined"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                label="Aglomeracja"
                                value={currentFiltersState.agglomerationsNameIn || ''}
                                notched
                                onChange={(event) => {
                                    dispatch(setFilters({
                                        ...currentFiltersState,
                                        agglomerationsNameIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                    }))
                                }}
                            >
                                {filtersMetaData.data.filtering.fields.agglomerationsNameIn.allowedValues.map((agglomeration) => (
                                    <MenuItem
                                        key={agglomeration}
                                        value={agglomeration}>{agglomeration}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth sx={{marginTop: '15px'}}>
                            <InputLabel shrink id="demo-simple-select-label">Miasto</InputLabel>
                            <Select
                                size="small"
                                variant={"outlined"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                label="Miasto"
                                value={currentFiltersState.citiesNameIn || ''}
                                notched
                                onChange={(event) => {
                                    dispatch(setFilters({
                                        ...currentFiltersState,
                                        citiesNameIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                    }))
                                }}
                            >
                                {filtersMetaData.data.filtering.fields.citiesNameIn.allowedValues.map((city) => (
                                    <MenuItem
                                        key={city}
                                        value={city}>{city}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="demo-simple-select-label">Adres</InputLabel>
                            <Select
                                size="small"
                                variant={"outlined"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                label="Adres"
                                value={currentFiltersState.mediaAddressIn || ''}
                                notched
                                onChange={(event) => {
                                    dispatch(setFilters({
                                        ...currentFiltersState,
                                        mediaAddressIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                    }))
                                }}
                            >
                                {filtersMetaData.data.filtering.fields.mediaAddressIn.allowedValues.map((mediaAddressIn) => (
                                    <MenuItem
                                        key={mediaAddressIn}
                                        value={mediaAddressIn}>{mediaAddressIn}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="demo-simple-select-label">Obiekt</InputLabel>
                            <Select
                                size="small"
                                variant={"outlined"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                label="Obiekt"
                                value={currentFiltersState.buildingsNameIn || ''}
                                notched
                                onChange={(event) => {
                                    dispatch(setFilters({
                                        ...currentFiltersState,
                                        buildingsNameIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                    }))
                                }}
                            >
                                {filtersMetaData.data.filtering.fields.buildingsNameIn.allowedValues.map((buildingsNameIn) => (
                                    <MenuItem
                                        key={buildingsNameIn}
                                        value={buildingsNameIn}>{buildingsNameIn}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="demo-simple-select-label">Numer nośnika</InputLabel>
                            <Select
                                size="small"
                                variant={"outlined"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                label="Numer nośnika"
                                value={currentFiltersState.mediaAsIdIn || ''}
                                notched
                                onChange={(event) => {
                                    dispatch(setFilters({
                                        ...currentFiltersState,
                                        mediaAsIdIn: typeof event.target.value === "string" ? [parseInt(event.target.value + '')] : event.target.value
                                    }))
                                }}
                            >
                                {filtersMetaData.data.filtering.fields.mediaAsIdIn.allowedValues.map((mediaAsIdIn) => (
                                    <MenuItem
                                        key={mediaAsIdIn}
                                        value={mediaAsIdIn}>{mediaAsIdIn}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10}>

                        <FormControl fullWidth>
                            {<Autocomplete
                                multiple
                                size={'small'}
                                id="tags-filled"
                                options={filtersMetaData.data.filtering.fields.tagsNameIn.allowedValues.map((option) => option)}
                                value={currentFiltersState.tagsNameIn}
                                onChange={(_, value) => {
                                    dispatch(setFilters({
                                        ...currentFiltersState,
                                        tagsNameIn: value
                                    }))
                                }}
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: string, index: number) => {
                                        const {key, ...tagProps} = getTagProps({index});
                                        if (index === 0) {
                                            return (
                                                <Typography sx={{marginLeft: '10px'}}>
                                                    {option}
                                                </Typography>
                                            )
                                        }

                                        return (
                                            <Typography>
                                                {`, ${option}`}
                                            </Typography>
                                        );
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Tagi"
                                    />
                                )}
                            />}
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                    </Grid>
                </Grid>
                <Grid container spacing={2} pb={2}>
                    {filterItemChips.map((filter) => {
                        return (
                            <Grid item key={filter.value}>
                                <Chip
                                    color="info"
                                    label={(<React.Fragment>{t(`filters.keys.${filter.name}`)  + ': '}<strong>{filter.value}</strong></React.Fragment>)}


                                    onClick={() => {
                                    }}
                                    onDelete={() => removeFilter(filter)}
                                />
                            </Grid>)
                    })}
                </Grid>
                <Backdrop
                    sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
                    open={deletingDraft}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nazwa schematu</TableCell>
                                <TableCell align="right">Kompletny</TableCell>
                                <TableCell align="right">Format nośnika</TableCell>
                                <TableCell align="right">Tagi</TableCell>
                                {showCrudActions && (<><TableCell align="right"></TableCell></>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {drafts.map((row) => (
                                <TableRow
                                    selected={row.id === activeDraftId}
                                    key={row.id}
                                    className={(greyOutNotCompleted && !row.completed) ? 'uncompleted' : (selectable ? 'selectable' : undefined)}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    hover={selectable ? row.completed : true}
                                    onClick={() => {
                                        onRowClick && onRowClick(row)
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right" scope="row">
                                        {row.completed ? (<DoneIcon></DoneIcon>) :
                                            <HtmlTooltip title={(row.incompleteReason && row.incompleteReason.length) ?

                                                (<React.Fragment>
                                                    <Box sx={{paddingRight: '20px'}}>
                                                        <ul>
                                                            {(row.incompleteReason as Array<string>).map(el => <li>
                                                                <Typography
                                                                    color="inherit">{incompleteReasons[el as keyof typeof incompleteReasons]}</Typography>
                                                            </li>)}
                                                        </ul>
                                                    </Box>
                                                </React.Fragment>)
                                                : ''


                                            }>
                                                <WarningIcon color={'warning'}></WarningIcon>
                                            </HtmlTooltip>}
                                    </TableCell>
                                    <TableCell align="right">{row.mediaFormat}</TableCell>
                                    <TableCell align="right">{row.tagList.join(', ')}</TableCell>
                                    {showCrudActions && (<><TableCell align="right">
                                        <ButtonGroup variant="text" aria-label="Basic button group">
                                            <Button>Przykładowa kreacja</Button>
                                            <Link to={`/drafts/${row.id}`} className="link"><Button>Edycja
                                                schematu</Button></Link>
                                            <Link onClick={(e) => {
                                                if (!row.completed) {
                                                    e.preventDefault()
                                                }
                                            }} to={`/drafts/${row.id}/new-creation`} className="link"><Button
                                                disabled={!row.completed}>Twórz
                                                kreacje</Button></Link>
                                            <Button color="error" onClick={() => {
                                                handleClickOpenDeleteDraftModal(row.id)
                                            }}>{t('drafts.removeDraft')}</Button>
                                        </ButtonGroup>
                                    </TableCell></>)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={TABLE_ROW_LENGTH_OPTIONS}
                    component="div"
                    count={data.meta.count}
                    rowsPerPage={rowLength}
                    page={data.meta.page - 1}
                    onPageChange={(e, page) => dispatch(setFilters({
                        ...currentFiltersState,
                        page: page + 1
                    }))}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog
                    open={!!selectedDraftToRemove}
                    onClose={handleCloseDeleteDraftModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('drafts.removingDraft')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('drafts.removalConfirmation', {draftName: drafts.filter(draft => draft.id === selectedDraftToRemove)[0]?.name})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={() => {
                            handleCloseDeleteDraftModal(true)
                        }}>{t('common.remove')}</Button>
                        <Button onClick={() => {
                            handleCloseDeleteDraftModal(false)
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)}
        </Box>

    )
}

export default DraftsList;