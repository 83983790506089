export function getUuid(index: number) {
    return (Date.now() + Math.random() + index).toString();
}

export function formatDuration(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hours) {
        return `${hours}g ${minutes}m ${secs}s`;
    } else if (minutes) {
        return `${minutes}m ${secs}s`;
    } else {
        return `${secs}s`
    }
}