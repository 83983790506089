import React, {useEffect, useRef, useState} from 'react';
import {
    AppBar, Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DropResult} from "@hello-pangea/dnd";
import './VoohForm.scss'
import {useTheme} from "@mui/material/styles";
import {MediabankResource, MediabankResourceTableRow} from "../../mediabank/types";
import {
    useCreateMediaTemplateMutation,
    useCreateVoohPlaylistMutation,
    useDeleteMediaTemplateMutation,
    useGetMediaTemplatesQuery,
    useGetVoohPlaylistQuery,
    useLazyGetResourcesMetaDataQuery,
    useUpdateVoohPlaylistMutation
} from "../api/voohApiSlice";
import {ResourceMetaData, ScheduleRow, VoohCategory, VoohItem, VoohItemType} from "../types";
import {useGetCmEnumsQuery} from "../../../app/appApiSlice";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {initialVoohFormState, selectVoohForm, setVoohForm} from "../store/voohSlice";
import {useGetMediaTreeQuery, useGetTagsQuery} from "../../drafts/api/draftsApiSlice";
import {Media, MediaNode} from "../../drafts/types";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import CheckboxTree from "react-checkbox-tree";
import {ClearIcon, DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {DEFAULT_ADVERTISEMENTS, MIN_ADVERTISEMENTS} from "../../../config/vooh";
import useDebounce from "../../../hooks/useDebounce";
import {getUuid} from "../../../config/general";

import CloseIcon from '@mui/icons-material/Close';
import {enqueueSnackbar} from "notistack";
import ConfirmDialog from "../../shared/confirmDialog/ConfirmDialog";
import OwnershipSelect from "../../shared/ownershipSelect/OwnershipSelect";
import {useNavigate, useParams} from "react-router-dom";
import {ApiTags, baseApi} from "../../../app/api/baseApi";
import VoohDragAndDropTable from "../voohDragAndDropTable/VoohDragAndDropTable";
import MediabankFileSelectDialog from "../../shared/mediabankFileSelectDialog/MediabankFileSelectDialog";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {Thumbnail} from "../../../config/images";
import Link from "@mui/material/Link";
import {useLazyGetMediabankResourceQuery} from "../../mediabank/api/mediabankResourcesApiSlice";
import {setDraftForm} from "../../drafts/store/draftsSlice";
import usedMediaTable from "../usedMediaTable/usedMediaTable";
import UsedMediaTable from "../usedMediaTable/usedMediaTable";

type Order = 'asc' | 'desc';


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof MediabankResourceTableRow) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof MediabankResourceTableRow;
    label: string;
    numeric: boolean;
    sort: boolean;
    width: string;
}

const VoohForm = () => {

    const mediabankBreadcrumbsInitialState = [{
        dirName: 'Katalog główny',
        resourceId: null
    }]

    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Nazwa folderu',
            sort: true,
            width: '34'
        },
        {
            id: 'byteSize',
            numeric: false,
            disablePadding: true,
            label: 'Rozmiar',
            sort: true,
            width: '3'
        },
        {
            id: 'metadata',
            numeric: false,
            disablePadding: true,
            label: 'Rozdzielczość',
            sort: false,
            width: '8'
        },
        {
            id: 'tagList',
            numeric: true,
            disablePadding: false,
            label: 'Tagi',
            sort: false,
            width: '15'
        },
        {
            id: 'categoryId',
            numeric: true,
            disablePadding: false,
            label: 'Kategoria',
            sort: false,
            width: '20'
        },
        {
            id: 'updatedAt',
            numeric: true,
            disablePadding: false,
            label: 'Data wgrania',
            sort: true,
            width: '15'
        },
        {
            id: 'used',
            numeric: true,
            disablePadding: false,
            label: '',
            sort: true,
            width: '4'
        },
    ];


    const {t} = useTranslation();
    const [scheduleRows, setScheduleRows] = useState<ScheduleRow[]>([]);
    const navigate = useNavigate();

    const handleDragEnd = (result: DropResult, visibleRows: MediabankResource[], mediabankResources: MediabankResource[], selectedMediabankFiles: number[]) => {

        try {
            const {source, destination} = result;
            if (destination?.droppableId === 'droppable-2' || !mediabankResources) {
                return
            }
            if (!result.destination) {
                return;
            }
            if (source.droppableId === destination?.droppableId) {
                let tempData = Array.from(scheduleRows);
                let [source_data] = tempData.splice(result.source.index, 1);
                tempData.splice(result.destination.index, 0, source_data);
                setVoohItems(tempData);
            } else {
                if (result.draggableId === 'bumper' && selectedBumper) {
                    getFilesMeta([selectedBumper?.id]).then(async (res: ResourceMetaData[]) => {
                        const bumper = {
                            ...res[0],
                            uuid: getUuid(0),
                            name: selectedBumper.name,
                            voohItemType: VoohItemType.Bumper,
                        }
                        let tempData = Array.from(scheduleRows);
                        tempData.splice(destination?.index || 0, 0, bumper)
                        setVoohItems(tempData);
                        setRollbackAdvNumber(true)
                        setAdvertisingBlocksNumber(advertisingBlocksNumber + 1)

                    })
                    return
                }


                if (result.draggableId === 'advertisement') {
                    let tempData = Array.from(scheduleRows);
                    tempData.splice(destination?.index || 0, 0, {
                        name: 'REKLAMA',
                        voohItemType: VoohItemType.Advertisement,
                        uuid: getUuid(0),
                        duration: 0,
                        categoryId: null
                    })
                    setVoohItems(tempData);
                    setRollbackAdvNumber(true)
                    setAdvertisingBlocksNumber(advertisingBlocksNumber + 1)
                    return;
                }

                const draggedItem = visibleRows[result.source.index];

                const newRows = selectedMediabankFiles.find(el => el === draggedItem.id) ? selectedMediabankFiles : [draggedItem.id];

                getFilesMeta(newRows).then(async (res: ResourceMetaData[]) => {
                    const newScheduleRows: ScheduleRow[] = res.map((resourceMetaData, index) => {
                        return {
                            ...resourceMetaData,
                            name: mediabankResources?.find(el => el.id === resourceMetaData.mediabankResourceId)?.name || '',
                            uuid: getUuid(index),
                            categoryColor: voohCategories?.filter(el => el.id === resourceMetaData.categoryId)[0]?.color || '',
                            categoryName: voohCategories?.filter(el => el.id === resourceMetaData.categoryId)[0]?.name || '',
                            voohItemType: VoohItemType.MediabankResource
                        }
                    })
                    let tempData = Array.from(scheduleRows.filter(el => !el._destroy));
                    tempData.splice(destination?.index || 0, 0, ...newScheduleRows)
                    setVoohItems(tempData.concat(Array.from(scheduleRows.filter(el => el._destroy))).map((el, index) => {
                        return {
                            ...el,
                            position: index
                        }
                    }));
                })

            }
        } catch {

        }


    };
    const dispatch = useDispatch();

    const theme = useTheme();
    const [checkedNodes, setCheckedNodes] = useState<string[]>([]);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [templateName, setTemplateName] = useState<string | null>(null);
    const [advertisingBlocksNumber, setAdvertisingBlocksNumber] = useState(DEFAULT_ADVERTISEMENTS);
    const [advertisingBlocksNumberPrevValue, setAdvertisingBlocksNumberPrevValue] = useState(DEFAULT_ADVERTISEMENTS);
    const [nodes, setNodes] = useState([]);
    const [treeState, setTreeState] = useState({key: Math.random()});
    const [openMediaTreeModal, setOpenMediaTreeModal] = useState(false);
    const [openUsedMediaTreeModal, setOpenUsedMediaTreeModal] = useState(false);

    const checkboxTreeRef = useRef<any>()
    const [filterNodesText, setFilterNodesText] = useState('')
    const [expanded, setExpanded] = useState([]);
    const [openWarningAfterChangingAdvNumber, setOpenWarningAfterChangingAdvNumber] = React.useState<boolean>(false);
    const [openSaveTemplateModal, setOpenSaveTemplateModal] = React.useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<number | string>('')
    const [openRemoveTemplateConfirmation, setOpenRemoveTemplateConfirmation] = useState<number | null>(null);
    const [rollbackAdvNumber, setRollbackAdvNumber] = useState<boolean>(true);
    const [filterByIds, setFilterByIds] = useState<string | null>(null);
    const [fullScreen, setFullScreen] = useState<boolean>(false);

    const [mediaLength, setMediaLength] = useState<number | null>(null);
    const voohForm = useSelector(selectVoohForm);

    const {isLoading, isError, isSuccess, data: mediaTemplatesData} =
        useGetMediaTemplatesQuery();
    const [createMediaTemplate, {isLoading: creatingMediaTemplate}] = useCreateMediaTemplateMutation()
    const [createVoohPlaylist, {isLoading: creatingVoohPlaylist}] = useCreateVoohPlaylistMutation()
    const [updateVoohPlaylist, {isLoading: updatingVoohPlaylist}] = useUpdateVoohPlaylistMutation()

    const [deleteMediaTemplate, {isLoading: deletingMediaTemplate}] = useDeleteMediaTemplateMutation()
    const [selectedBumper, setSelectedBumper] = React.useState<MediabankResource | null>(null);
    const [openSelectBumperDialog, setOpenSelectBumperDialog] = useState<boolean>(false);

    const [getFile, {data: FileData}] = useLazyGetMediabankResourceQuery()


    let checkedMediaOnFilterById: string[] = []

    const [formErrors, setFormErrors] = useState({
        name: false,
        startAt: false,
        endAt: false,
    })
    const {id: urlId} = useParams()

    const {isLoading: loadingMediaTree, data: mediaTree} =
        useGetMediaTreeQuery('MOVE_TV' || '');


    const [getFilesMetaData, {data: filesMetaData}] = useLazyGetResourcesMetaDataQuery()

    const {isLoading: loadingCmEnums, data: cmEnums} =
        useGetCmEnumsQuery();

    const {isLoading: loadingTags, data: tagsData} =
        useGetTagsQuery();

    const {isLoading: loadingVoohDetails, data: voohDetails} =
        useGetVoohPlaylistQuery(urlId || '', {skip: !urlId});

    const voohCategories: VoohCategory[] | undefined = cmEnums && cmEnums?.filter(el => el.name === 'categories')[0].values as VoohCategory[]


    useEffect(() => {
        if (voohDetails && urlId) {
            const {voohItems, media, ownerships, ...rest} = voohDetails;
            const items = voohItems.map((voohItem, index) => {
                return {
                    ...voohItem,
                    position: voohItem.position,
                    id: voohItem.id,
                    uuid: getUuid(index),
                    voohItemType: voohItem.mediabankResourceId ? VoohItemType.MediabankResource : VoohItemType.Advertisement,
                    name: voohItem.mediabankResourceId ? voohItem.resourceName : 'REKLAMA'
                }
            }).sort((a, b) => a.position - b.position)
            setScheduleRows(items);
            dispatch(setVoohForm({
                ...rest,
                voohItemsAttributes: voohItems.map((el) => {
                    return {
                        id: el.id,
                        mediabankResourceId: el.mediabankResourceId,
                        position: el.position,
                        duration: el.duration,
                        categoryId: el.categoryId,
                        itemType: el.voohItemType
                    }
                }).sort((a, b) => a.position - b.position),
                localisablesMediatablesAttributes: media.map((mediaItem) => {
                    return {localisableId: mediaItem.id, localisableType: 'Medium'}
                }),
                ownershipsAttributes: ownerships
            }))
            const checkedNodes = media.map((mediaElement: Media) => {
                return mediaElement.id + '_Medium'
            }) as any
            setCheckedNodes(checkedNodes)


            setAdvertisingBlocksNumber(voohItems.filter(el => !el.mediabankResourceId).length);
            setRollbackAdvNumber(true)

        } else {
            dispatch(setVoohForm(initialVoohFormState.voohForm));
            setAdvertisingBlocksNumber(DEFAULT_ADVERTISEMENTS)
            setRollbackAdvNumber(false)
            const advertisements: ScheduleRow[] = [...Array(DEFAULT_ADVERTISEMENTS).keys()].map((_, index) => {
                return {
                    name: 'REKLAMA',
                    uuid: getUuid(index),
                    voohItemType: VoohItemType.Advertisement,
                    duration: 0,
                    categoryId: null
                }
            })
            setScheduleRows(advertisements)
            setFilterByIds(null)
        }

    }, [voohDetails, urlId])


    const setVoohItems = (scheduleRows: ScheduleRow[], destroyedElement?: ScheduleRow) => {
        if (destroyedElement?.id && voohDetails) {

            let destroyedElements: VoohItem[] = []
            let elements: VoohItem[] = []

            const elementsWithDestroy = voohForm.voohItemsAttributes.forEach((el, index) => {
                if (el.id === destroyedElement.id) {
                    destroyedElements.push({...el, _destroy: true})
                } else {
                    elements.push(el)
                }
            })

            dispatch(setVoohForm({
                ...voohForm,
                voohItemsAttributes: [...elements, ...destroyedElements].map((el, index) => {
                    return {...el, position: index}
                }),
            }))

            let destroyedScheduleRows: ScheduleRow[] = []
            let existingScheduleRows: ScheduleRow[] = []
            scheduleRows.forEach((el) => {
                if (destroyedElement?.id === el.id) {
                    destroyedScheduleRows.push({...el, _destroy: true})
                } else {
                    existingScheduleRows.push(el)
                }
            })
            setScheduleRows([...existingScheduleRows, ...destroyedScheduleRows].map((el, index) => {
                return {
                    ...el,
                    position: index,
                }
            }));
            return;
        }


        if (destroyedElement) {
            const voohItems: VoohItem[] = scheduleRows.filter(el => el.uuid !== destroyedElement.uuid).map((element, position) => {
                let voohItem: VoohItem = {
                    mediabankResourceId: element.mediabankResourceId,
                    position,
                    duration: element.duration,
                    categoryId: element.categoryId,
                    itemType: element.voohItemType,
                }
                voohItem = element._destroy ? {...voohItem, _destroy: element._destroy} : {...voohItem}

                return element.id ? {id: element.id, ...voohItem} : {...voohItem}
            })

            dispatch(setVoohForm({
                ...voohForm,
                voohItemsAttributes: voohItems,
            }));
            setScheduleRows(scheduleRows.filter(el => el.uuid !== destroyedElement.uuid));
            return;
        }

        const voohItems: VoohItem[] = scheduleRows.sort(el => el._destroy ? 1 : -1).map((element, position) => {
            let voohItem: any = {
                mediabankResourceId: element.mediabankResourceId,
                position,
                duration: element.duration,
                categoryId: element.categoryId,
                itemType: element.voohItemType,
                _destroy: element._destroy,
            }


            return element.id ? {id: element.id, ...voohItem, position: position} : {...voohItem, position: position}
        })


        dispatch(setVoohForm({
            ...voohForm,
            voohItemsAttributes: voohItems.sort(el => el._destroy ? 1 : -1),
        }));
        setScheduleRows(scheduleRows.sort(el => el._destroy ? 1 : -1));
    }


    let mediaSize = 0

    const onSelectMediaById = (event: any) => {
        setFilterByIds(event.target.value)
    }

    const createTemplate = async () => {
        if (!templateName) {
            return;
        }

        try {
            const payload = {
                name: templateName,
                localisablesMediatablesAttributes: checkedNodes.map((el) => {
                    return {
                        localisableId: parseInt(el.split('_')[0]),
                        localisableType: el.split('_')[1]
                    }

                })
            }
            const result = await createMediaTemplate(payload).unwrap()
            setOpenSaveTemplateModal(false);
            setTemplateName(null)
        } catch (err: any) {
            if (err?.status && err.status === 422) {
                enqueueSnackbar('Wystąpił błąd - nazwa już istnieje w bazie, proszę wybrać inną', {variant: 'error'})
            } else {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
            setOpenSaveTemplateModal(false);
            setTemplateName(null)
        }
    }

    const removeTemplate = async (templateId: number) => {
        try {
            const result = await deleteMediaTemplate(templateId).unwrap()
            setSelectedTemplate('')
        } catch (err: any) {
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }

    const filterNodesByIds = (filtered: any, node: any) => {
        const filterByIdsArr = (filterByIds || '').split(/,| |;/)
        const nodeId = node.label.split('-')[0].trim()
        if (
            // Node's label matches the search string
            filterByIdsArr.some(filteredNodeId => filteredNodeId === nodeId)
        ) {
            if (node.value.includes('_Medium')) {
                checkedMediaOnFilterById.push(node.value)
            }

            filtered.push(node)
            return filtered;
        }

        if (node.children && node.children.length > 0) {
            const children = (node.children || []).reduce(filterNodesByIds, []);
            if (children.length > 0) {
                filtered.push({...node, children});
            }
        }
        return filtered;
    }

    function getSelectedMediaArrayFromTree(entireObj: MediaNode[]) {
        let foundArr: MediaNode[] = [];
        JSON.stringify(entireObj, (_, nestedValue) => {
            if (nestedValue && checkedNodes.some(el => el.split('_')[0] === nestedValue.id?.toString())) {
                foundArr.push(nestedValue);
            }
            return nestedValue;
        });
        return foundArr;
    };


    const onFilterNodeChange = (event: any) => {
        setFilterNodesText(event.target.value)
    }
    const filterNodes = (filtered: any, node: any) => {
        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().match(filterNodesText.toString().toLocaleLowerCase())
        ) {
            filtered.push(node)
            return filtered;
        }

        if (node.children && node.children.length > 0) {
            const children = (node.children || []).reduce(filterNodes, []);
            if (children.length > 0) {
                filtered.push({...node, children});
            }
        }
        return filtered;
    }

    const buildMediaTreeCheckboxNodes: any = (mediaTree: MediaNode[]) => {
        return mediaTree.map((element: MediaNode) => {
            if (element.children && element.children.length > 0) {
                return {
                    value: element.id + '_' + element.type,
                    label: element.name + ' ' + `${element.type === 'Media::Agglomeration' ? '(Aglomeracja)' : '(Miasto)'}`,
                    children: buildMediaTreeCheckboxNodes(element.children),
                }
            } else {
                mediaSize += 1;
                return {
                    value: element.id + '_' + element.type,
                    label: element.asId ? element.asId + ' - ' + element.name : element.name,
                }
            }
        })
    }

    useEffect(() => {
        if (mediaTree) {
            mediaSize = 0;
            const mediaTreeCheckboxNodes = buildMediaTreeCheckboxNodes(mediaTree)
            setTimeout(() => {
                setNodes(mediaTreeCheckboxNodes)
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: mediaTreeCheckboxNodes
                }] as any)
                setMediaLength(mediaSize)
            }, 200)
        }


    }, [mediaTree]);


    const updateMediaInForm = (checkedNodes: string[]) => {
        const checkedMedia = checkedNodes.filter(el => el !== 'all').map((node: string) => {
            return node.split('_')[0]
        })

        const mediaToAdd = checkedMedia.map((checkedMediaElement) => {
            return {localisableId: checkedMediaElement, localisableType: 'Medium'}
        })
        dispatch(setVoohForm({
            ...voohForm,
            localisablesMediatablesAttributes: [...mediaToAdd as []]
        }));
    }


    useDebounce(() => {
            // Reset nodes back to unfiltered state
            if (!filterNodesText) {
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes
                }] as any);
                return;
            }
            setFilterByIds('')
            setFilteredNodes(
                [{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes.reduce(filterNodes, [])
                }] as any)
            checkboxTreeRef.current.onExpandAll()
        }, [filterNodesText], 100
    );

    useDebounce(() => {
            if (filterByIds !== null) {
                checkedMediaOnFilterById = []
                if (filterByIds === '') {
                    setCheckedNodes([])
                }
                nodes.reduce(filterNodesByIds, [])
                setCheckedNodes(checkedMediaOnFilterById)
                updateMediaInForm(checkedMediaOnFilterById)
            }
        }, [filterByIds], 100
    );

    useDebounce(() => {
            const customScheduleRows = scheduleRows.find(el => el.voohItemType !== VoohItemType.Advertisement)
            if (customScheduleRows && advertisingBlocksNumber >= MIN_ADVERTISEMENTS && !rollbackAdvNumber) {
                setOpenWarningAfterChangingAdvNumber(true)
            } else {
                setAdvertisingBlocksNumberPrevValue(advertisingBlocksNumber)
                if (!rollbackAdvNumber && advertisingBlocksNumber >= MIN_ADVERTISEMENTS) {
                    const advertisements: ScheduleRow[] = [...Array(advertisingBlocksNumber).keys()].map((_, index) => {
                        return {
                            name: 'REKLAMA',
                            uuid: getUuid(index),
                            voohItemType: VoohItemType.Advertisement,
                            duration: 0,
                            categoryId: null
                        }
                    })
                    setVoohItems(advertisements)
                    setSelectedBumper(null)
                }
                setRollbackAdvNumber(false)
            }
        }, [advertisingBlocksNumber], 800
    );

    async function getFilesMeta(ids: number[]) {
        let query = ids.map((id) => {
            return `mediabank_resource_ids[]=${id}`
        }).join('&');
        return getFilesMetaData(query).unwrap()
    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function getStyles() {
        return {
            fontWeight: theme.typography.fontWeightMedium
        };
    }


    const handleOpenNodesModal = () => {
        setOpenMediaTreeModal(true)
    };
    const handleCloseNodesModal = () => {
        setOpenMediaTreeModal(false)
    };

    const handleSelectTemplate = (templateId: number) => {
        setSelectedTemplate(templateId);
        const selectedTemplate = mediaTemplatesData!.find(template => template.id === templateId);
        const media = selectedTemplate!.media.map(el => el + '_Medium')
        setCheckedNodes(media);
        updateMediaInForm(media);
    }

    const addAuthorizedPerson = (authorizedPerson: number | null, authorizedPersonRights: string) => {
        if (!authorizedPerson) {
            return;
        }

        dispatch(setVoohForm({
            ...voohForm,
            ownershipsAttributes: [...voohForm.ownershipsAttributes, {
                userId: authorizedPerson,
                accessLevel: authorizedPersonRights
            }]
        }));
    }

    function openImageFile(mediabankResource: MediabankResource) {
        if (mediabankResource.resourceFileUrl) {
            openLink(mediabankResource.resourceFileUrl)
        } else {
            getFile(mediabankResource.id).unwrap().then(async (res) => {
                if (res.data.resourceFileUrl) {
                    openLink(res.data.resourceFileUrl);
                }
            })
        }
    };

    function openLink(url: string) {
        const link = document.createElement("a");
        link.href = url
        link.target = '_blank'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }

    const saveForm = async () => {
        setFormErrors({
            name: !voohForm.name.trim(),
            endAt: !voohForm.endAt,
            startAt: !voohForm.startAt,
        })

        if (!voohForm.name || !voohForm.endAt || !voohForm.endAt) {
            enqueueSnackbar('Formularz zawiera błędy', {variant: 'error'});
            return;
        }


        try {
            if (urlId) {
                const voohItems = voohForm.voohItemsAttributes.map((el, index) => {
                    return {
                        ...el,
                        position: index
                    }
                })
                // const updatedForm = {
                //     ...voohForm,
                //     voohItemsAttributes: voohForm.voohItemsAttributes.filter(el => !voohDetails?.voohItems.some(item => item.id === el.id)),
                // }
                await updateVoohPlaylist({...voohForm, voohItemsAttributes: voohItems}).unwrap()
                dispatch(baseApi.util.invalidateTags([ApiTags.VoohDetails]));

            } else {
                await createVoohPlaylist(voohForm).unwrap()
            }

            navigate('/emissions/vooh-list')
            enqueueSnackbar(`Pomyślnie ${urlId ? 'zaktualizowano' : 'utworzono'} ramówke`, {variant: 'success'});
            setVoohForm(initialVoohFormState.voohForm)
        } catch (err: any) {
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }

    }
    return <>
        <Box className={'VoohForm'}>
            <Box className={'page-header'} sx={{paddingBottom: '20px'}}>
                <h1>{t('vooh.scheduleVOOH')}</h1>
                <p>Uzupenij parametry ramówki</p>
            </Box>
        </Box>
        <form autoComplete="off">

            <TextField
                value={voohForm.name}
                label="Nazwa ramówki"
                onChange={(event) => {
                    setFormErrors({...formErrors, name: !event.target.value.trim()})
                    dispatch(setVoohForm({
                        ...voohForm,
                        name: event.target.value
                    }));
                }}
                required
                variant="outlined"
                sx={{mb: 3}}
                fullWidth
                error={formErrors.name}/>

            <FormControl fullWidth sx={{marginTop: '15px', paddingBottom: '20px'}}>
                <InputLabel shrink id="demo-simple-select-label">Format nośnika</InputLabel>
                <Select
                    variant={"outlined"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Format nośnika"
                    value={voohForm.mediaFormat || ''}
                    notched
                    onChange={(event) => {
                        dispatch(setVoohForm({
                            ...voohForm,
                            mediaFormat: event.target.value
                        }));
                    }}
                >
                    <MenuItem
                        key={'MOVE_TV'}
                        value={'MOVE_TV'}>Vooh</MenuItem>
                </Select>
            </FormControl>

            <Button disabled={loadingMediaTree} variant="contained"
                    onClick={handleOpenNodesModal} sx={{marginTop: '20px'}}>{t('draftForm.mediaPicker')}&nbsp;
                {(loadingMediaTree) && voohForm.mediaFormat ? <CircularProgress sx={{marginLeft: '10px'}}
                                                                                size={20}/> : `${checkedNodes.length}/${mediaLength || 0}`}</Button>

            <Button disabled={loadingMediaTree} variant="text"
                    onClick={() => {
                        setOpenUsedMediaTreeModal(true)
                        if (mediaTree) {
                            console.log(getSelectedMediaArrayFromTree(mediaTree))
                        }

                    }} sx={{marginTop: '20px', marginLeft: '20px'}}>Używane nośniki</Button>
            <Dialog
                open={openMediaTreeModal}
                onClose={handleCloseNodesModal}
                fullWidth={true}
                maxWidth="md"
            >

                <DialogTitle id="alert-dialog-title">
                    {t("draftForm.chooseMedia")}
                </DialogTitle>
                <DialogContent>

                    {(loadingMediaTree) && (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                            <CircularProgress size={100}/>
                        </Box>)}

                    <Box sx={{minHeight: '300px'}}>
                        {mediaTree && (
                            <><TextField
                                value={filterNodesText}
                                label="Wyszukaj..."
                                onInput={onFilterNodeChange}
                                variant="outlined"
                                sx={{mt: 1, mb: 3}}
                                fullWidth
                            />
                                <TextField
                                    value={filterByIds}
                                    label="Zaznacz nośniki podając ID klubów"
                                    onInput={onSelectMediaById}
                                    helperText={"Dozwolone separatory: spacja, średnik, przecinek"}
                                    variant="outlined"
                                    sx={{mt: 1, mb: 3}}
                                    fullWidth
                                />

                                <FormControl fullWidth sx={{marginBottom: '30px'}}>
                                    <InputLabel shrink id="demo-simple-select-label">Szablony</InputLabel>
                                    <Select
                                        size="small"
                                        variant={"outlined"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Szablony"
                                        value={selectedTemplate}
                                        renderValue={(selected) => (
                                            <span>{mediaTemplatesData && mediaTemplatesData.filter(el => el.id === selected)[0].name}</span>
                                        )}
                                        notched
                                    >
                                        {mediaTemplatesData?.length === 0 && (<MenuItem value="" onClick={() => {
                                            setSelectedTemplate('')
                                        }}>
                                            <em>Brak szablonów</em>
                                        </MenuItem>)}
                                        {mediaTemplatesData && mediaTemplatesData.map(template => (

                                            <MenuItem value={template.id} onClick={() => {
                                                handleSelectTemplate(template.id)
                                            }} key={template.id}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}>
                                                    <span>{template.name}</span>
                                                    <IconButton color={'error'} edge="end" aria-label="remove"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    setOpenRemoveTemplateConfirmation(template.id)
                                                                }}>
                                                        <CloseIcon/>
                                                    </IconButton>
                                                </Box>
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                                {openRemoveTemplateConfirmation && (<ConfirmDialog title={'Usuwanie szablonu'}
                                                                                   description={`Czy na pewno chcesz usunąć szablon ${mediaTemplatesData?.filter(el => el.id === openRemoveTemplateConfirmation as number)[0].name}?`}
                                                                                   confirmText={'usuń'}
                                                                                   open={!!openRemoveTemplateConfirmation}
                                                                                   onConfirm={() => {
                                                                                       removeTemplate(openRemoveTemplateConfirmation as number)
                                                                                       setOpenRemoveTemplateConfirmation(null)
                                                                                   }}
                                                                                   onCancel={() => {
                                                                                       setOpenRemoveTemplateConfirmation(null)
                                                                                   }}></ConfirmDialog>)}


                                <CheckboxTree
                                    ref={checkboxTreeRef}
                                    key={treeState.key}
                                    showNodeTitles={true}
                                    nodes={filteredNodes}
                                    checked={checkedNodes}
                                    expanded={expanded}
                                    showExpandAll={true}
                                    onCheck={(checked: any) => {
                                        setCheckedNodes(checked)
                                        updateMediaInForm(checked)
                                        setTreeState({key: Math.random()})
                                        setSelectedTemplate('')
                                    }}
                                    onExpand={(expanded: any) => {
                                        setExpanded(expanded)
                                    }}
                                /></>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button disabled={checkedNodes.length === 0} variant={'contained'} onClick={() => {
                        setOpenSaveTemplateModal(true)
                    }}>Zapisz jako szablon</Button>
                    <Button onClick={handleCloseNodesModal}>{t('common.closeWindow')}</Button>

                </DialogActions>


            </Dialog>

            <Dialog
                open={openUsedMediaTreeModal}
                onClose={() => {
                    setOpenUsedMediaTreeModal(false)
                }}
                fullWidth={true}
                maxWidth="md">

                <DialogTitle id="alert-dialog-title">
                    Używane nośniki
                </DialogTitle>
                <DialogContent>

                    {(loadingMediaTree) && (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                            <CircularProgress size={100}/>
                        </Box>)}

                    <Box sx={{minHeight: '300px'}}>
                        {(mediaTree && checkedNodes.length > 0) ?  (
                            <UsedMediaTable usedMedia={getSelectedMediaArrayFromTree(mediaTree)}></UsedMediaTable>
                        ): <p>Brak zaznaczonych nośników...</p>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenUsedMediaTreeModal(false)
                    }}>{t('common.closeWindow')}</Button>
                </DialogActions>
            </Dialog>


            {openSaveTemplateModal && (<Dialog
                open={openSaveTemplateModal}
                onClose={(value) => {

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Zapisz nowy szablon
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={templateName}
                        label="Nazwa szablonu"
                        onChange={(event) => {
                            setTemplateName(event.target.value)
                        }}
                        required
                        InputProps={{inputProps: {min: 1}}}
                        type="text"
                        variant="outlined"
                        sx={{my: 3, width: '250px'}}></TextField>
                </DialogContent>
                <DialogActions>
                    <Button disabled={!templateName} variant={'contained'} onClick={() => {
                        createTemplate()
                    }}>{t('common.save')}</Button>
                    <Button onClick={() => {
                        setOpenSaveTemplateModal(false)
                    }} autoFocus>
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>)}


            <FormControl fullWidth sx={{marginTop: '45px', paddingBottom: '20px'}}>
                {tagsData && (<Autocomplete
                    multiple
                    id="tags-filled"
                    options={tagsData.map((option) => option.name)}
                    value={voohForm.tagList}
                    onChange={(_, value) => {
                        dispatch(setVoohForm({
                            ...voohForm,
                            tagList: value
                        }));
                    }}
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => {
                            const {key, ...tagProps} = getTagProps({index});
                            return (
                                <Chip sx={{marginY: '10px'}} color={"info"} variant="filled"
                                      label={option}
                                      key={key} {...tagProps} />
                            );
                        })
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Tagi"
                            placeholder={tagsData.length === 0 ? 'Brak utworzonych tagów... wpisz nowy i zatwierdź enterem' : 'Wybierz z listy lub wpisz nowy i zatwierdź enterem'}
                        />
                    )}
                />)}
            </FormControl>
            <Divider sx={{paddingY: '20px'}} textAlign="left"><Typography
                sx={{color: 'text.secondary'}}>Uzupełnij schemat ramówki</Typography></Divider>
            <Box
                sx={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', marginBottom: '20px'}}
            ><TextField
                size={'small'}
                value={advertisingBlocksNumber}
                label="Ilość bloków reklamowych w ramówce"
                onChange={(event) => {
                    setAdvertisingBlocksNumber(parseInt(event.target.value))
                }}
                required
                InputProps={{inputProps: {min: 4}}}
                type="number"
                variant="outlined"
                sx={{width: '250px'}}
                helperText={(advertisingBlocksNumber >= MIN_ADVERTISEMENTS) ? '' : `Ilość bloków musi być większa niż ${MIN_ADVERTISEMENTS}`}
                error={advertisingBlocksNumber < MIN_ADVERTISEMENTS}/>
                <Button variant="outlined"
                        sx={{marginX: '15px'}}
                        onClick={() => {
                            setOpenSelectBumperDialog(true)
                        }}>{'Wybierz bumper'}</Button>


                {selectedBumper && (<Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    {
                        selectedBumper &&
                        (!selectedBumper.thumbnailUrl ? (
                                <DescriptionOutlinedIcon
                                    fontSize="large"/>) : (
                                <Box sx={{
                                    height: Thumbnail.width,
                                    width: Thumbnail.width,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: Thumbnail.background
                                }}><img
                                    width={Thumbnail.width}
                                    height={Thumbnail.width}
                                    src={`${selectedBumper.thumbnailUrl}`}
                                    alt={selectedBumper.name}
                                    loading="lazy"/>
                                </Box>)
                        )
                    }
                    {
                        selectedBumper && (
                            <Link onClick={(event) => {
                                event.preventDefault()
                                openImageFile(selectedBumper)
                            }
                            }

                                  component={'button'}>{selectedBumper.name}</Link>
                        )
                    }
                    {selectedBumper && (<Button variant="contained"
                                                sx={{marginX: '15px'}}
                                                onClick={() => {
                                                    setVoohItems([]);

                                                    getFilesMeta([selectedBumper.id]).then(async (res: ResourceMetaData[]) => {
                                                        const bumper = {
                                                            ...res[0],
                                                            name: selectedBumper.name,
                                                            voohItemType: VoohItemType.Bumper,
                                                        }
                                                        let newRows: ScheduleRow[] = [];

                                                        scheduleRows.forEach((scheduleRow, index) => {
                                                            if (scheduleRow.voohItemType === VoohItemType.Advertisement && !scheduleRow._destroy) {
                                                                newRows.push({...bumper, uuid: getUuid(index)})
                                                                newRows.push(scheduleRow)
                                                            } else if (scheduleRow.voohItemType === VoohItemType.Bumper) {
                                                                newRows.push({...scheduleRow, _destroy: true})
                                                            } else {
                                                                newRows.push(scheduleRow)
                                                            }

                                                        })
                                                        setVoohItems(newRows)
                                                    })
                                                    // setOpenSelectBumperDialog(true)
                                                }}>{'Dodaj przed reklamy'}</Button>)}


                </Box>)}
                {
                    selectedBumper && (
                        <IconButton
                            color={'error'} aria-label="delete" onClick={() => {
                            setVoohItems([])
                            setTimeout(function () {
                                setVoohItems(scheduleRows.map((el) => {
                                    if (el.voohItemType === VoohItemType.Bumper) {
                                        return {
                                            ...el,
                                            _destroy: true
                                        }
                                    } else {
                                        return el
                                    }
                                }))

                            }, 0)
                            setSelectedBumper(null)
                        }}>
                            <ClearIcon/>
                        </IconButton>
                    )
                }
            </Box>

            {openSelectBumperDialog && (
                <MediabankFileSelectDialog onSelectMediabankFile={(bumperFile) => {
                    setSelectedBumper(bumperFile);
                    setOpenSelectBumperDialog(false)

                }}
                                           openMediabankDialog={openSelectBumperDialog}
                                           selectedMediabankResource={selectedBumper}
                                           onClose={() => {
                                               setOpenSelectBumperDialog(false)
                                           }}
                ></MediabankFileSelectDialog>
            )}


            <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                <VoohDragAndDropTable
                    bumper={selectedBumper}
                    hideMediabank={false}
                    onFullScreenClick={() => {
                        setFullScreen(true)
                    }}
                    handleDragEnd={handleDragEnd}
                    scheduleRows={scheduleRows}
                    setVoohItems={setVoohItems}
                    setRollbackAdvNumber={setRollbackAdvNumber}
                    setAdvertisingBlocksNumber={setAdvertisingBlocksNumber}
                    voohCategories={voohCategories}
                    advertisingBlocksNumber={advertisingBlocksNumber}></VoohDragAndDropTable>


            </Box>
            {fullScreen && (<Dialog
                fullScreen
                open={fullScreen}
                onClose={(value) => {

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AppBar sx={{position: 'relative', marginBottom: '30px'}} color="default">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setFullScreen(false)
                            }}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <VoohDragAndDropTable
                    bumper={selectedBumper}
                    fullScreenMode={true}
                    hideMediabank={true}
                    onFullScreenClick={() => {
                        setFullScreen(true)
                    }}
                    handleDragEnd={handleDragEnd}
                    scheduleRows={scheduleRows}
                    setVoohItems={setVoohItems}
                    setRollbackAdvNumber={setRollbackAdvNumber}
                    setAdvertisingBlocksNumber={setAdvertisingBlocksNumber}
                    voohCategories={voohCategories}
                    advertisingBlocksNumber={advertisingBlocksNumber}></VoohDragAndDropTable>
            </Dialog>)}


            {openWarningAfterChangingAdvNumber && (<Dialog
                open={openWarningAfterChangingAdvNumber}
                onClose={(value) => {

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Ostrzeżenie
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{color: 'text.primary', paddingY: '10px'}}>Zmiana ilość bloków reklamowych usunie dodane
                        ramówki, czy na pewno chesz kontynuować?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={() => {
                        setVoohItems([])
                        setOpenWarningAfterChangingAdvNumber(false);
                        setAdvertisingBlocksNumberPrevValue(advertisingBlocksNumber)
                        const advertisements: ScheduleRow[] = [...Array(advertisingBlocksNumber).keys()].map((_, index) => {
                            return {
                                name: 'REKLAMA',
                                uuid: getUuid(index),
                                voohItemType: VoohItemType.Advertisement,
                                duration: 0,
                                categoryId: null
                            }
                        })
                        setTimeout(() => {
                            setVoohItems([...advertisements, ...scheduleRows.map((el) => {
                                return {
                                    ...el,
                                    _destroy: true
                                }
                            })])
                        }, 0)

                    }}>Tak, zmień ilość</Button>
                    <Button onClick={() => {
                        setOpenWarningAfterChangingAdvNumber(false);
                        setAdvertisingBlocksNumber(advertisingBlocksNumberPrevValue)
                        setRollbackAdvNumber(true)
                    }} autoFocus>
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>)}

            <Divider sx={{paddingY: '10px'}}></Divider>


            <Box sx={{display: 'flex', gap: '40px', alignItems: 'center', paddingTop: '40px'}}>
                <Box sx={{width: '100%'}}>
                    <DatePicker
                        minDate={dayjs(new Date())}
                        maxDate={voohForm.endAt ? dayjs(new Date(voohForm.endAt)) : undefined}
                        value={dayjs(voohForm.startAt)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: 'outlined',
                                error: formErrors.startAt
                            },
                        }}
                        label={t('creations.steps.fillInCreationParameters.emissionStart')}
                        onChange={(event) => {
                            setFormErrors({...formErrors, startAt: false})
                            dispatch(setVoohForm({
                                ...voohForm,
                                startAt: dayjs(event).date() ? dayjs(event).set('hour', 0).set('minute', 0).format() : ''
                            }))
                        }}/></Box>
                <span>-</span>
                <Box sx={{width: '100%'}}>
                    <DatePicker
                        minDate={voohForm.startAt ? dayjs(voohForm.startAt) : dayjs(new Date())}
                        value={dayjs(voohForm.endAt)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: 'outlined',
                                error: formErrors.endAt
                            },
                        }}
                        label={t('creations.steps.fillInCreationParameters.emissionEnd')}
                        onChange={(event) => {
                            setFormErrors({...formErrors, endAt: false})
                            dispatch(setVoohForm({
                                ...voohForm,
                                endAt: dayjs(event).date() ? dayjs(event).set('hour', 23).set('minute', 59).format() : ''
                            }))
                        }}/></Box>
            </Box>

            <Box sx={{marginTop: '20px'}}><OwnershipSelect ownershipsAttributes={voohForm.ownershipsAttributes}
                                                           onDelete={(val) => {
                                                               const removingFromDb = undefined
                                                               dispatch(setVoohForm({
                                                                   ...voohForm,
                                                                   ownershipsAttributes: voohForm.ownershipsAttributes.filter(person => person.userId !== parseInt(val))
                                                               }));
                                                           }}

                                                           onAdd={addAuthorizedPerson}
                                                           onDeleteAll={() => {
                                                               dispatch(setVoohForm({
                                                                   ...voohForm,
                                                                   ownershipsAttributes: []
                                                               }));
                                                           }}
            ></OwnershipSelect></Box>


            <Box sx={{display: 'flex', justifyContent: 'end', padding: '20px'}}>
                <Button variant={'outlined'} onClick={saveForm}>{t('common.save')}</Button>
            </Box>

        </form>


    </>
}

export default VoohForm;



