import React, {useEffect, useMemo, useRef, useState} from 'react';
import './App.scss';
import {Route, Routes, useLocation} from "react-router-dom";
import DraftsList from "../features/drafts/list/DraftsList";
import Login from "../features/auth/Login";
import {useDispatch, useSelector} from "react-redux";
import Layout from "../components/Layout";
import RequireAuth from "../features/auth/RequireAuth";
import {AuthInitialState} from "../features/auth/authSlice";
import CmSidebar from "../components/CmSidebar/CmSidebar";
import CmTopBar from "../components/CmTopBar/CmTopBar";
import DraftForm from "../features/drafts/form/DraftForm";
import CreationForm from '../features/creations/form/CreationForm';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pl';
import {LocalizationProvider} from '@mui/x-date-pickers';
import CreationsList from "../features/creations/list/creationsList";
import MediaList from "../features/mediabank/list/MediaList";
import EmissionsList from "../features/emissions/list/emissionsList";
import CreationPreview from "../features/creations/preview/CreationPreview";
import VoohForm from "../features/vooh/form/VoohForm";
import VoohList from "../features/vooh/list/VoohList";
import {FilePond} from "react-filepond";
import ClearIcon from '@mui/icons-material/Clear';
import {
    selectCurrentMediabankResourcesFiltersState,
    selectUploudBoxVisibility,
    setVisibleUploudBox
} from "../features/mediabank/store/mediabankResourcesSlice";
import UseApiV1 from "../hooks/useApiV1";
import {MediabankFile} from "../features/mediabank/types";
import {Card, CardActions, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ApiTags, baseApi} from "./api/baseApi";
import ConfirmDialog from "../features/shared/confirmDialog/ConfirmDialog";
import {FilePondFile, FileStatus} from "filepond";
import NotFound from "../features/notFound/NotFound";

function App() {
    const user = JSON.parse(localStorage.getItem('user') || "{}") as AuthInitialState;
    const location = useLocation();
    const showUploudBox = useSelector(selectUploudBoxVisibility)
    const currentMediabankListFiltersState = useSelector(selectCurrentMediabankResourcesFiltersState);
    const [mediabankFilesToUploud, setMediabankFilesToUploud] = React.useState<MediabankFile[]>([]);
    const dispatch = useDispatch();
    const filepondRef = useRef<any>()
    const cardContent = useRef<any>()
    const cardHeader = useRef<any>()
    const [showUploud, setShowUploud] = React.useState<boolean>(true);
    const [finishedUplouding, setFinishedUplouding] = React.useState<boolean>(true);
    const [openAbortDownloadConfirmationDialog, setOpenAbortDownloadConfirmationDialog] = useState<boolean>(false);

    const [uploudingFilesLength, setUploudingFilesLength] = React.useState<number>(0);
    const [successfullyUploadedFilesLength, setSuccessfullyUploadedFilesLength] = React.useState<number>(0);
    const [server, setServer] = React.useState<String>(`${process.env.REACT_APP_DSP_BACKEND_API + UseApiV1('mediabank_resources_bulk')}`);

    let onLeavePageGuard: any;

    useEffect(() => {
        if (filepondRef.current) {
            setSuccessfullyUploadedFilesLength(filepondRef.current.getFiles().filter((file: FilePondFile) => file.status === FileStatus.PROCESSING_COMPLETE).length)
            if (!filepondRef.current.getFiles().find((el: FilePondFile) => el.status === FileStatus.PROCESSING) && !filepondRef.current.getFiles().find((el: FilePondFile) => el.status === FileStatus.INIT)) {
                setTimeout(() => setFinishedUplouding(true), 0)
                window.removeEventListener('beforeunload', beforeLeaveFunc)
                onLeavePageGuard = undefined;
                dispatch(baseApi.util.invalidateTags([ApiTags.MediabankResourcesList]));
            }
        }
    }, [filepondRef.current && filepondRef.current.getFiles()]);

    useEffect(() => {
        setFinishedUplouding(false)
    }, [mediabankFilesToUploud]);


    const alertUser = (e: any) => {
        e.preventDefault()
        e.returnValue = ''
    }
    const beforeLeaveFunc = useMemo(() => alertUser, []);

    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
            <Routes>
                <Route path='/creations/:creationId/preview' element={<CreationPreview/>}></Route>
            </Routes>
            {!location.pathname.includes('preview') && (<CmTopBar></CmTopBar>)}
            {!location.pathname.includes('preview') && (
                <div className={(location.pathname !== '/login') ? 'App_container--with-sidebar' : ''}>
                    {(user && location.pathname !== '/login' && !location.pathname.includes('preview')) && !location.pathname.includes('not-found') &&  !(location.key === 'default' && location.pathname !== '/')  && (
                        <CmSidebar></CmSidebar>)}

                    <section className={'App__content-container'}>
                        {(showUploudBox && location.pathname !== '/login') && (
                            <Card sx={{
                                width: 500,
                                position: 'absolute',
                                bottom: 0,
                                right: '1rem',
                                opacity: 1,
                                zIndex: 2
                            }}>
                                <CardHeader
                                    ref={cardHeader}
                                    action={<>
                                        <IconButton aria-label="collapse" onClick={() => {
                                            // cardContent.current.style.display = 'none'
                                            if (!cardContent.current.style.display) {
                                                cardContent.current.style.display = 'none'
                                            } else {
                                                cardContent.current.style.display = ''
                                            }
                                            setShowUploud(!showUploud)

                                        }}>
                                            {showUploud ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                        </IconButton>
                                        <IconButton aria-label="close" onClick={() => {
                                            if (finishedUplouding) {
                                                dispatch(setVisibleUploudBox(false))
                                                setMediabankFilesToUploud([])
                                            } else {
                                                setOpenAbortDownloadConfirmationDialog(true);
                                            }
                                        }}>
                                            <ClearIcon/>
                                        </IconButton>
                                    </>
                                    }
                                    title={finishedUplouding ?
                                        <Typography style={{fontWeight: 'bold'}} color={'success.main'}>Przesyłanie
                                            ukończone
                                            ({successfullyUploadedFilesLength}/{mediabankFilesToUploud.length})</Typography> :
                                        <Typography>Przesyłanie plików...
                                            ({successfullyUploadedFilesLength}/{mediabankFilesToUploud.length})</Typography>}
                                />
                                <CardContent ref={cardContent} sx={{maxHeight: '160px', overflowY: 'auto'}}>
                                    <FilePond
                                        ref={filepondRef}
                                        allowMultiple={true} maxFiles={100}
                                        allowBrowse={false}
                                        maxParallelUploads={5}
                                        allowDrop={false}
                                        labelTapToCancel={'zatrzymaj'}
                                        onprocessfiles={() => {
                                            console.log('onprocessfiles!!');
                                            setFinishedUplouding(true)
                                            window.removeEventListener('beforeunload', beforeLeaveFunc)
                                            onLeavePageGuard = undefined;
                                            dispatch(baseApi.util.invalidateTags([ApiTags.MediabankResourcesList]));
                                        }}
                                        onprocessfile={(error, file) => {
                                            dispatch(baseApi.util.invalidateTags([ApiTags.MediabankResourcesList]));
                                            if (!error) {
                                                setSuccessfullyUploadedFilesLength(successfullyUploadedFilesLength + 1);
                                            }
                                            // console.log(successfullyUploadedFilesLength)

                                        }}
                                        onerror={(error, file, status) => {
                                            console.log(error, file, status);
                                        }}

                                        name={'mediabank_resources[]'}
                                        files={mediabankFilesToUploud.map(el => el.file)}
                                        onaddfile={(error, file) => {
                                            setSuccessfullyUploadedFilesLength(0)
                                            let mediabankFile = mediabankFilesToUploud.find(el => el.file === file.file)
                                            if (mediabankFile && currentMediabankListFiltersState.parentIdEq) {
                                                file.setMetadata('category_id', mediabankFile.categoryId)
                                                file.setMetadata('parent_id', currentMediabankListFiltersState.parentIdEq!.toString())
                                                file.setMetadata('tag_list', mediabankFile.tagList)
                                                file.setMetadata('ownerships', mediabankFile.ownershipsAttributes)
                                            }

                                            window.addEventListener('beforeunload', beforeLeaveFunc)
                                        }}
                                        onremovefile={(error, file) => {
                                            setMediabankFilesToUploud(mediabankFilesToUploud.filter(el => el.file !== file.file))
                                            if (filepondRef.current.getFiles().length === 0) {
                                                dispatch(setVisibleUploudBox(false))
                                                setMediabankFilesToUploud([])
                                            }
                                        }}
                                        server={{
                                            url: `${process.env.REACT_APP_DSP_BACKEND_API + UseApiV1('mediabank_resources_bulk')}`,
                                            headers: {
                                                'authorization': `Bearer ${JSON.parse(localStorage.getItem("user") || '').accessToken}`
                                            },
                                            remove: (src, load, error) => {
                                                load()
                                            }
                                        }}/>
                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>)}

                        {openAbortDownloadConfirmationDialog && (<ConfirmDialog title={'Zatrzymanie pobierania'}
                                                                                description={`Czy na pewno chcesz przerwać pobieranie plików?`}
                                                                                confirmText={'tak, przerwij pobieranie'}
                                                                                open={!!openAbortDownloadConfirmationDialog}
                                                                                onConfirm={() => {
                                                                                    dispatch(setVisibleUploudBox(false))
                                                                                    setMediabankFilesToUploud([])
                                                                                    setOpenAbortDownloadConfirmationDialog(false)
                                                                                    onLeavePageGuard = undefined;
                                                                                    setTimeout(() => {
                                                                                        window.removeEventListener('beforeunload', beforeLeaveFunc)
                                                                                    }, 200)
                                                                                }}
                                                                                onCancel={() => {
                                                                                    setOpenAbortDownloadConfirmationDialog(false)

                                                                                }}></ConfirmDialog>)}


                        <Routes>
                            <Route id={'notFound'} path='*' element={<NotFound></NotFound>}></Route>
                            <Route id={'notFound'} path='/not-found' element={<NotFound></NotFound>}></Route>

                            <Route path='/' element={<Layout/>}>
                                <Route path='/login' element={<Login/>}></Route>
                                <Route element={<RequireAuth/>}>
                                    <Route path='/' element={<DraftsList/>}></Route>
                                    <Route path='/creations' element={<CreationsList/>}></Route>
                                    <Route path='/new-draft' element={<DraftForm/>}></Route>
                                    <Route path='/new-creation' element={<CreationForm/>}></Route>
                                    <Route path='/creations/:creationId' element={<CreationForm/>}></Route>
                                    <Route path='/drafts/:id' element={<DraftForm/>}></Route>
                                    <Route path='/drafts/:draftId/new-creation' element={<CreationForm/>}></Route>
                                    <Route path='/media-list' element={<MediaList
                                        setMediabankFilesToUploud={setMediabankFilesToUploud}/>}></Route>
                                    <Route path='/emissions' element={<EmissionsList/>}></Route>
                                    <Route path='/emissions/new-schedule' element={<VoohForm/>}></Route>
                                    <Route path='/emissions/vooh-list' element={<VoohList/>}></Route>
                                    <Route path='/emissions/vooh-list/:id' element={<VoohForm/>}></Route>
                                </Route>
                            </Route>

                        </Routes>
                    </section>
                </div>)}
        </LocalizationProvider>

    );
}

export default App;
