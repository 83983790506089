import {BaseQueryApi, createApi, FetchArgs, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {logOut, selectCurrentUser, selectRefreshToken, setCredentials} from "../../features/auth/authSlice";
import {RootState} from "../../store";
import {camelizeKeys, decamelize, decamelizeKeys} from "humps";
import {AuthResponse, UserBasicData} from "../../features/auth/types";
import {Mutex} from 'async-mutex';
import {createBrowserHistory} from 'history';

var Navigation = require('react-router').Navigation;

interface RefreshTokenResult {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    created_at: number;
    profile: AuthResponse["profile"];
}

export enum ApiTags {
    DraftDetails = 'DraftDetails',
    CreationDetails = 'CreationDetails',
    DraftsList = 'DraftsList',
    VoohList='VoohList',
    VoohDetails = 'VoohDetails',
    CreationsList = 'CreationsList',
    MediabankResourcesList = 'MediabankResourcesList',
    MediabankResource = 'MediabankResource',
    EmissionsList = 'EmissionsList',
    MediaTemplates = 'MediaTemplates',
}

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_DSP_BACKEND_API,
    credentials: "same-origin",
    prepareHeaders: (headers, {getState}) => {
        let token = ''
        if (localStorage.getItem("user")) {
            token = JSON.parse(localStorage.getItem("user") || '').accessToken;
        }
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers;
    },
})

const mutex = new Mutex();
const history = createBrowserHistory();

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) => {
    const urlSearchParam = new URLSearchParams((args as FetchArgs).url);

    let urlWithDecamilizedParams = '';
    Array.from(urlSearchParam.keys()).forEach((param, index) => {
        if (index === 0) {
            const urlWithParams = param.split('?')
            urlWithDecamilizedParams = urlWithParams[0]
            if (urlWithParams[1]) {
                urlWithDecamilizedParams += `?${decamelize(urlWithParams[1])}=${urlSearchParam.get(param)}`
            }
        } else {
            urlWithDecamilizedParams += `&${decamelize(param)}=${Array.from(urlSearchParam.values())[index]}`
        }
    })

    if ((args as FetchArgs)?.body) {
        const body = (args as FetchArgs)?.body;
        (args as FetchArgs).body = decamelizeKeys(body)
    }

    (args as FetchArgs).url = urlWithDecamilizedParams


    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);


    if (result?.error?.status === 400) {
        api.dispatch(logOut());
        Navigation.location = '/login'
    }

    if ( result?.error?.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()

            try {
                const refreshToken = selectRefreshToken(api.getState() as RootState) || JSON.parse(localStorage.getItem('user')  || "{}").refreshToken as UserBasicData || {};
                if (refreshToken) {
                    const refreshResult = await baseQuery({
                        url: '/oauth/token',
                        method: 'POST',
                        body: {refresh_token: refreshToken, grant_type: 'refresh_token'}
                    }, api, extraOptions);
                    if (refreshResult?.data) {
                        const {refreshToken, accessToken, expiresIn, createdAt, profile} = (camelizeKeys(refreshResult?.data) as AuthResponse);
                        const user = selectCurrentUser(api.getState() as RootState) || '';
                        api.dispatch(setCredentials({ accessToken, refreshToken, expiresIn, createdAt, profile}));
                        result = await baseQuery(args, api, extraOptions);
                    } else {
                        api.dispatch(logOut());
                        Navigation.location = '/login'
                    }
                } else {
                    api.dispatch(logOut());
                    Navigation.location = '/login'
                }
            } finally {
                release();
            }

        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);

        }
    }
    if (result.data) {
        result.data = camelizeKeys(result.data);
    }
    return result
}
export const baseApi = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [ApiTags.DraftDetails, ApiTags.DraftsList, ApiTags.CreationsList, ApiTags.EmissionsList, ApiTags.MediabankResourcesList, ApiTags.MediabankResource, ApiTags.CreationDetails, ApiTags.MediaTemplates, ApiTags.VoohList, ApiTags.VoohDetails],
    endpoints: builder => ({})
})
