import React, {useEffect} from 'react';
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, TablePagination,
    TextField, Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {TABLE_ROW_LENGTH_OPTIONS} from "../../../config/table";
import {
    initialCreationFormState,
    selectCurrentCreationsFiltersState,
    setCreationsFilters
} from "../store/creationsSlice";
import {Draft, DraftsFilters, FilterItemChip, Tag} from "../../drafts/types";
import {
    useDeleteCreationMutation,
    useGetCreationsFiltersMetaQuery,
    useGetCreationsQuery, useLazyGetCreationDetailsQuery
} from "../api/creationsApiSlice";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import useDebounce from "../../../hooks/useDebounce";
import {Clear} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import {Link} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import DialogContentText from "@mui/material/DialogContentText";
import './creationsList.scss'
import CreationsTable from "../../shared/creationsTable/creationsTable";
import {useNavigate} from "react-router-dom";
import {CreationDetails, CreationsFilters} from "../types";
import {mediaFormatDimensions} from "../../../config/mediaFormatDimensions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {setDraftForm, setFilters} from "../../drafts/store/draftsSlice";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { useGetTagsQuery} from "../../drafts/api/draftsApiSlice";
import {useGetCmEnumsQuery} from "../../../app/appApiSlice";
import {autocompleteClasses} from '@mui/material/Autocomplete';

const CreationsList = () => {
    const [searchByName, setSearchByName] = React.useState<string>('');
    const currentFiltersState = useSelector(selectCurrentCreationsFiltersState);
    const [rowLength, setRowLength] = React.useState(TABLE_ROW_LENGTH_OPTIONS[0]);
    const [selectedCreationToRemove, setSelectedCreationToRemove] = React.useState<number | null>(null);
    const [skipFetchingCreations, setSkipFetchingCreations] = React.useState(false);
    const navigate = useNavigate();

    const [getCreationDetails, {data: creationDetailsData}] = useLazyGetCreationDetailsQuery();

    const page = currentFiltersState.page;

    let params = [`items=${rowLength.toString()}`];
    let filterItemChips: FilterItemChip[] = []
    for (const queryKey in currentFiltersState) {
        const value = currentFiltersState[queryKey as keyof typeof currentFiltersState]
        if (Array.isArray(value)) {
            value.forEach(item => {
                params.push(`q[${queryKey}][]=${item.toString()}`)
                filterItemChips.push({name: queryKey, value: item, isArray: true})
            })
        } else if (queryKey === 'page') {
            params.push(`page=${value?.toString()}`)
        } else if (value) {
            params.push(`q[${queryKey}]=${value.toString()}`)
            filterItemChips.push({name: queryKey, value: value.toString(), isArray: false})
        }
    }

    const [deleteCreation, {isLoading: deletingCreation}] = useDeleteCreationMutation()

    const {isLoading: loadingTags, data: tagsData} =
        useGetTagsQuery();

    const {isLoading: loadingCmEnums, data: cmEnums} =
        useGetCmEnumsQuery();


    const deleteCreationMutation = async (creationId: number) => {
        try {
            const isLastItem = creations?.length === 1;
            if (isLastItem && page > 0) {
                setSkipFetchingCreations(true);
            }
            const result = await deleteCreation(creationId).unwrap()
            if (!result?.error) {
                if (isLastItem && page > 0) {
                    setSkipFetchingCreations(false);
                    dispatch(setCreationsFilters({
                        ...currentFiltersState,
                        page: page - 1
                    }))
                }
                enqueueSnackbar('Pomyślnie usunięto kreacje', {variant: 'success'});
            }
        } catch (err: any) {
            console.log(err)
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }

    const handleClickOpenDeleteCreationModal = (creationId: number) => {
        setSelectedCreationToRemove(creationId);
    };

    const handleCloseDeleteCreationModal = (remove: boolean) => {
        if (remove && selectedCreationToRemove) {
            deleteCreationMutation(selectedCreationToRemove);
        }
        setSelectedCreationToRemove(null);
    };

    const {isLoading, isError, isSuccess, data} =
        useGetCreationsQuery(params.join('&').toString(), {skip: skipFetchingCreations});

    const {
        isLoading: loadingFiltersMeta,
        isError: isFiltersMetaError,
        isSuccess: isSuccessFiltersMeta,
        data: filtersMetaData
    } = useGetCreationsFiltersMetaQuery()

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation(); // not passing any namespace will use the defaultNS (by default set to 'translation')

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const creations = data?.data

    useEffect(() => {
        return () => {
            dispatch(setCreationsFilters({
                ...initialCreationFormState.filters
            }))
        }
    }, []);

    useDebounce(() => {
            dispatch(setCreationsFilters({
                ...currentFiltersState,
                nameCont: searchByName,
                page: 1
            }))
        }, [searchByName], 800
    );

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowLength(parseInt(event.target.value, 10))
    }

    function removeFilter(filterElement: FilterItemChip) {
        if (filterElement.isArray) {
            dispatch(setCreationsFilters({
                ...currentFiltersState,
                page: 1,
                [filterElement.name]: (currentFiltersState[filterElement.name as keyof CreationsFilters] as string[]).filter(el => el !== filterElement.value)
            }))
        } else {
            if (filterElement.name === 'nameCont') {
                setSearchByName('');
            }
            dispatch(setCreationsFilters({
                ...currentFiltersState,
                page: 1,
                [filterElement.name]: initialCreationFormState.filters[filterElement.name as keyof CreationsFilters]
            }))
        }
    }


    function handleOpenCreationPreview(creationId: number | null) {
        if (!creationId) {
            return;
        }
        getCreationDetails(creationId.toString()).unwrap().then((creationDetails: CreationDetails) => {
            const fileUrl = encodeURIComponent(creationDetails.creationFileUrl);
            const playerPreviewPropsLink = encodeURIComponent(creationDetails.playerPreviewPropsLink)
            window.open(`/creations/${creationId}/preview?datajson=${playerPreviewPropsLink}&datapath=${process.env.REACT_APP_DSP_BACKEND_API}&fileUrl=${fileUrl}&mediaFormat=${creationDetails.mediaFormat}`, "_blank", `location=yes,height=${mediaFormatDimensions[creationDetails.mediaFormat as keyof typeof mediaFormatDimensions][1] + 300},width=${mediaFormatDimensions[creationDetails.mediaFormat as keyof typeof mediaFormatDimensions][0] + 300},scrollbars=yes,status=yes`);
        })
    }

    return (
        <Box className="CreationsList">
            <Box className={'page-header'}>
                <h1>Lista kreacji</h1>
            </Box>

            {(isLoading || loadingFiltersMeta) && (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                    <CircularProgress size={100}/>
                </Box>)}

            {/*{isError && (*/}
            {/*    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>*/}
            {/*        <span>Error occurred...</span>*/}
            {/*    </Box>)}*/}

            {(creations && filtersMetaData) && (<>
                <Grid container spacing={2}>
                    <Grid item xs={4} alignItems="baseline">
                        <TextField
                            size="small"
                            value={searchByName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchByName(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setSearchByName('')
                                        dispatch(setCreationsFilters({
                                            ...currentFiltersState,
                                            nameCont: searchByName
                                        }))
                                    }
                                    }
                                    edge="end"
                                >
                                    {<Clear/>}
                                </IconButton>,
                            }}
                            margin="normal"
                            variant={"outlined"}
                            required
                            fullWidth
                            id="creationsName"
                            label="Nazwa kreacji"
                            name="creationsName"
                            autoFocus></TextField>
                    </Grid>
                    <Grid item xs={4} sx={{marginTop: '15px'}}>
                        <FormControl fullWidth>
                            {tagsData && (<Autocomplete
                                multiple
                                size={'small'}
                                id="tags-filled"
                                options={tagsData.map((option) => option.name)}
                                value={currentFiltersState.tagsNameIn}
                                onChange={(_, value) => {
                                    dispatch(setCreationsFilters({
                                        ...currentFiltersState,
                                        tagsNameIn: value
                                    }))
                                }}
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: string, index: number) => {
                                        const {key, ...tagProps} = getTagProps({index});
                                        if (index === 0) {
                                            return (
                                                <Typography sx={{marginLeft: '10px'}}>
                                                    {option}
                                                </Typography>
                                            )
                                        }

                                        return (
                                            <Typography>
                                                {`, ${option}`}
                                            </Typography>
                                        );
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Tagi"
                                    />
                                )}
                            />)}
                        </FormControl>

                    </Grid>
                    <Grid item xs={4}>
                        {filtersMetaData && (<Box sx={{marginTop: '15px'}}><Autocomplete
                            disablePortal
                            onChange={(_, value) => {
                                dispatch(setCreationsFilters({
                                    ...currentFiltersState,
                                    draftIdEq: value ? value[1] : null
                                }))
                            }}
                            value={currentFiltersState.draftIdEq ? [filtersMetaData.data.filtering.fields.draftIdEq.allowedValues.filter(el => el[1] === currentFiltersState.draftIdEq)[0][0], currentFiltersState.draftIdEq ] as [string, number] : null}
                            size="small"
                            options={filtersMetaData.data.filtering.fields.draftIdEq.allowedValues}
                            getOptionLabel={(draftsAllowedValues) => {
                                return `${draftsAllowedValues[0]}`;
                            }}
                            renderOption={(props, option, state, ownerState) => {
                                const {key, ...optionProps} = props;

                                return(<Box
                                    key={key}
                                    component="li"
                                    {...optionProps}
                                >
                                    {option[0]}
                                </Box>)
                            }}
                            sx={{width: 300}}
                            renderInput={(params) => <TextField {...params} label="Schemat"/>}
                        /></Box>)}
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl fullWidth sx={{marginBottom: '15px'}}>
                            <InputLabel shrink id="demo-simple-select-label">Format nośnika</InputLabel>
                            <Select
                                size="small"
                                variant={"outlined"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Format nośnika"
                                multiple
                                value={currentFiltersState.draftMediaFormatIn || ''}
                                notched
                                onChange={(event) => {
                                    dispatch(setCreationsFilters({
                                        ...currentFiltersState,
                                        draftMediaFormatIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                    }))
                                }}
                            >
                                {filtersMetaData && filtersMetaData.data.filtering.fields.draftMediaFormatIn.allowedValues.map((mediaFormat) => (
                                    <MenuItem
                                        key={mediaFormat}
                                        value={mediaFormat}>{mediaFormat}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl fullWidth sx={{marginBottom: '15px'}}>
                            <InputLabel shrink id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                size="small"
                                variant={"outlined"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Status"
                                multiple
                                value={currentFiltersState.stateIn || ''}
                                notched
                                onChange={(event) => {
                                    dispatch(setCreationsFilters({
                                        ...currentFiltersState,
                                        stateIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                    }))
                                }}
                            >
                                {filtersMetaData.data.filtering.fields.stateIn.allowedValues.map((status) => (
                                    <MenuItem
                                        key={status}
                                        value={status}>{t(`creations.status.${status}`)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
                <Grid container spacing={2} pb={2}>
                    {filterItemChips.map((filter) => {

                        let chipValue;

                        if (filter.name === 'draftIdEq') {
                            chipValue = filtersMetaData.data.filtering.fields.draftIdEq.allowedValues.filter(el => el[1] === parseInt(filter.value as string))[0][0]
                        } else if (filter.name === 'stateIn') {
                            chipValue = t(`creations.status.${filter.value}`)
                        } else {
                            chipValue = filter.value
                        }

                        return (
                            <Grid item key={filter.value}>
                                <Chip
                                    color="info"
                                    label={(<React.Fragment>{t(`filters.keys.${filter.name}`)  + ': '}<strong>{chipValue}</strong></React.Fragment>)}
                                    onClick={() => {
                                    }}
                                    onDelete={() => removeFilter(filter)}
                                />
                            </Grid>)
                    })}
                </Grid>
                <Backdrop
                    sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
                    open={deletingCreation || isLoading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <CreationsTable creationsQueryResult={data} buttonGroup={(rowId) => {
                    return <ButtonGroup variant="text" aria-label="Basic button group">
                        <Link to={`/creations/${rowId}`} className="link"><Button>Edycja
                            kreacji</Button></Link>
                        <Button onClick={() => {
                            handleOpenCreationPreview(rowId);
                        }}>Podgląd kreacji</Button>
                        <Button color="error" onClick={() => {
                            rowId && handleClickOpenDeleteCreationModal(rowId)
                        }}>Usun kreacje</Button>
                    </ButtonGroup>
                }}></CreationsTable>
                <TablePagination
                    rowsPerPageOptions={TABLE_ROW_LENGTH_OPTIONS}
                    component="div"
                    count={data.meta.count}
                    rowsPerPage={rowLength}
                    page={data.meta.page - 1}
                    onPageChange={(e, page) => dispatch(setCreationsFilters({
                        ...currentFiltersState,
                        page: page + 1
                    }))}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog
                    open={!!selectedCreationToRemove}
                    onClose={handleCloseDeleteCreationModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Usuwanie kreacji
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'Usunąć kreacje ' + creations.filter(creation => creation.id === selectedCreationToRemove)[0]?.name + '?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={() => {
                            handleCloseDeleteCreationModal(true)
                        }}>{t('common.remove')}</Button>
                        <Button onClick={() => {
                            handleCloseDeleteCreationModal(false)
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)}
        </Box>

    )
}

export default CreationsList;