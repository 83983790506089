import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {VoohForm, VoohItemType, VoohPlayListsFilters} from "../types";
import {DEFAULT_ADVERTISEMENTS} from "../../../config/vooh";
import dayjs from "dayjs";

export interface VoohInitialState {
    voohForm: VoohForm,
    filters: VoohPlayListsFilters
}

export const initialVoohFormState: VoohInitialState = {
    voohForm: {
        id: null,
        name: '',
        mediaFormat: 'MOVE_TV',
        tagList: [],
        startAt: '',
        endAt: '',
        localisablesMediatablesAttributes: [],
        voohItemsAttributes: [...Array(DEFAULT_ADVERTISEMENTS).keys()].map((element, position) => {
            return {
                position,
                duration: 0,
                categoryId: null,
                itemType: VoohItemType.Advertisement
            }
        }),
        ownershipsAttributes: []
    },
    filters: {
        page: 1,
        nameCont: '',
        mediaFormatIn: [],
        tagsNameIn: [],
        voohPlaylistStatusEq: [],
        startAtLteq: '',
        endAtQteq: '',
    }
}

export const voohSlice = createSlice({
    name: "vooh",
    initialState: initialVoohFormState,
    reducers: {
        setFilters: (state, action: PayloadAction<VoohPlayListsFilters>) => {
            state.filters = action.payload
        },
        setVoohForm: (state, action: PayloadAction<VoohForm>) => {
            state.voohForm = action.payload
        }
    }
})

export const {setFilters, setVoohForm} = voohSlice.actions;

export const selectVoohForm = (state: RootState) => state.vooh.voohForm;
export const selectCurrentFiltersState = (state: RootState) => state.vooh.filters;

