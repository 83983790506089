import {
    Alert,
    AlertTitle,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {initialDraftFormState, selectDraftForm, setDraftForm} from "../store/draftsSlice";
import {CustomProp, Media, MediaNode, PropertyAttribute, PropType} from "../types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import Chip from "@mui/material/Chip";
import {
    useCreateDraftMutation,
    useDeleteDraftMutation,
    useGetDraftQuery,
    useGetMediaTreeQuery,
    useGetTagsQuery,
    useUpdateDraftMutation
} from "../api/draftsApiSlice";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useGetCmEnumsQuery} from "../../../app/appApiSlice";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import useDebounce from '../../../hooks/useDebounce';
import Link from '@mui/material/Link';
import DialogContentText from '@mui/material/DialogContentText';
import {VisuallyHiddenInput} from "../../../config/visuallyHiddenInput";
import DoneIcon from "@mui/icons-material/Done";
import {DragDropContext, Draggable, Droppable, DropResult} from "@hello-pangea/dnd";
import {draggingOverStyle} from "../../../config/table";
import {getUuid} from "../../../config/general";


const DraftForm = () => {


    const [createDraft, {isLoading}] = useCreateDraftMutation()
    const [updateDraft, {isLoading: updatingDraft}] = useUpdateDraftMutation()
    const {isLoading: loadingCmEnums, data: cmEnums} =
        useGetCmEnumsQuery();
    const {isLoading: loadingTags, data: tagsData} =
        useGetTagsQuery();
    const [openMediaTreeModal, setOpenMediaTreeModal] = useState(false);
    let fetchMediaTree = false
    const draftForm = useSelector(selectDraftForm);
    const {isLoading: loadingMediaTree, data: mediaTree} =
        useGetMediaTreeQuery(draftForm.mediaFormat || '', {skip: !draftForm.mediaFormat});
    const {id: urlId} = useParams()
    const {isLoading: loadingDraftDetails, data: draftDetails} =
        useGetDraftQuery(urlId || '', {skip: !urlId});
    const [expanded, setExpanded] = useState([]);
    const [checkedNodes, setCheckedNodes] = useState([]);
    const [treeState, setTreeState] = useState({key: Math.random()});
    const [nodes, setNodes] = useState([]);

    const [filteredNodes, setFilteredNodes] = useState([]);
    const [nameError, setNameError] = useState(false)
    const [attrNameError, setAttrNameError] = useState(false)
    const [attrKeyError, setAttrKeyError] = useState(false)

    const [filterNodesText, setFilterNodesText] = useState('')
    const {t, i18n} = useTranslation(); // not passing any namespace will use the defaultNS (by default set to 'translation')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [customProp, setCustomProp] = useState<CustomProp>({
        key: '',
        name: '',
        propType: PropType.String,
        required: false
    });
    const [skipped, setSkipped] = useState(new Set<number>());
    const steps = [t("draftForm.steps.schemaParameters.title"), t(urlId ? "draftForm.steps.createSchema.updateTitle" : "draftForm.steps.createSchema.createTitle")];
    const [file, setFile] = useState<File | null>(null);
    const [mediaLength, setMediaLength] = useState<number | null>(null);
    const [draftFileUrl, setDraftFileUrl] = useState<string | null>(null);
    const [openRemovalModal, setOpenRemovalModal] = React.useState<boolean>(false);
    const [deleteDraft, {isLoading: deletingDraft}] = useDeleteDraftMutation()

    const initialRectangles = [
        {
            x: 10,
            y: 10,
            width: 100,
            height: 100,
            fill: 'red',
            id: 'rect1',
        },
        {
            x: 150,
            y: 150,
            width: 100,
            height: 100,
            fill: 'green',
            id: 'rect2',
        },
    ];

    const [rectangles, setRectangles] = React.useState(initialRectangles);
    const [selectedId, selectShape] = React.useState(null);

    const incompleteReasons = {
        ['no_draft_file']: 'Brak pliku schematu',
        ['no_media']: 'Brak wybranych nośników'
    }

    function generateShapes() {
        return [...Array(10)].map((_, i) => ({
            id: i.toString(),
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            rotation: Math.random() * 180,
            isDragging: false,
        }));
    }

    const INITIAL_STATE = generateShapes();
    const [stars, setStars] = React.useState(INITIAL_STATE);

    const handleDragStart = (e: any) => {
        const id = e.target.id();
        setStars(
            stars.map((star) => {
                return {
                    ...star,
                    isDragging: star.id === id,
                };
            })
        );
    };

    const checkboxTreeRef = useRef<any>()

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const handleOpenNodesModal = () => {
        setOpenMediaTreeModal(true)
    };
    const handleCloseNodesModal = () => {
        setOpenMediaTreeModal(false)
    };

    const deleteDraftMutation = async (draftId: number) => {
        try {
            const result = await deleteDraft(draftId)
            if (!result?.error) {
                navigate('/')
                enqueueSnackbar('Pomyślnie usunięto schemat', {variant: 'success'});
            } else {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        } catch (err: any) {
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }

    const handleCloseDeleteDraftModal = (remove: boolean) => {
        if (remove && draftDetails && draftDetails.id) {
            deleteDraftMutation(draftDetails.id);
        }
        setOpenRemovalModal(false);
    };

    let mediaSize = 0

    const buildMediaTreeCheckboxNodes: any = (mediaTree: MediaNode[]) => {
        return mediaTree.map((element: MediaNode) => {
            if (element.children && element.children.length > 0) {
                return {
                    value: element.id + '_' + element.type,
                    label: element.name + ' ' + `${element.type === 'Media::Agglomeration' ? '(Aglomeracja)' : '(Miasto)'}`,
                    children: buildMediaTreeCheckboxNodes(element.children),
                }
            } else {
                mediaSize += 1;
                return {
                    value: element.id + '_' + element.type,
                    label: element.asId ? element.asId + ' - ' + element.name : element.name,
                }
            }
        })
    }

    useEffect(() => {
        if (mediaTree) {
            mediaSize = 0;
            const mediaTreeCheckboxNodes = buildMediaTreeCheckboxNodes(mediaTree)
            setTimeout(() => {
                setNodes(mediaTreeCheckboxNodes)
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: mediaTreeCheckboxNodes
                }] as any)
                setMediaLength(mediaSize)
            }, 200)
        }


    }, [mediaTree]);

    useEffect(() => {
        const checkedMedia = checkedNodes.filter(el => el !== 'all').map((node: string) => {
            return node.split('_')[0]
        })

        const mediaToAdd = checkedMedia.map((checkedMediaElement) => {
            return {localisableId: checkedMediaElement, localisableType: 'Medium'}
        })
        dispatch(setDraftForm({
            ...draftForm,
            localisablesMediatablesAttributes: [...mediaToAdd as []]
        }));
    }, [checkedNodes])


    useEffect(() => {
        if (draftDetails && urlId) {
            const {draftProperties, media, incompleteReason, adTags, ...rest} = draftDetails;
            dispatch(setDraftForm({
                ...rest,
                draftPropertiesAttributes: draftProperties.map((el, index) => {
                    return {
                        ...el,
                        uuid: getUuid(index)
                    }
                }).sort((a, b) => a.position - b.position),
                adTags: adTags.map(el => el.id),
                localisablesMediatablesAttributes: media.map((mediaItem) => {
                    return {localisableId: mediaItem.id, localisableType: 'Medium'}
                })
            }));
            setCheckedNodes(media.map((mediaElement: Media) => {
                return mediaElement.id + '_Medium'
            }) as any)
            setDraftFileUrl(draftDetails.draftFileUrl)
        } else {
            setActiveStep(0)
            dispatch(setDraftForm({
                ...initialDraftFormState.draftForm
            }));
            setCheckedNodes([])
        }
    }, [draftDetails, urlId])

    useDebounce(() => {
            // Reset nodes back to unfiltered state
            if (!filterNodesText) {
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes
                }] as any);
                return;
            }
            setFilteredNodes(
                [{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes.reduce(filterNodes, [])
                }] as any)
            checkboxTreeRef.current.onExpandAll()
        }, [filterNodesText], 100
    );


    const handleDragEnd = (result: DropResult) => {
        try {
            const {source, destination} = result;

            if (!result.destination) {
                return;
            }

            if (destination?.droppableId !== "draftPropsDroppable") {
                return
            }

            let tempData = Array.from(draftForm.draftPropertiesAttributes);
            let [source_data] = tempData.splice(result.source.index, 1);
            tempData.splice(result.destination.index, 0, source_data);
            dispatch(setDraftForm({
                ...draftForm,
                draftPropertiesAttributes: tempData,
            }));
        } catch (error) {
            console.log(error);
        }
    };


    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && draftForm.id) {
            setFile(e.target.files[0]);
            // await uploadDraftFile({draftId: draftForm.id, file: e.target.files[0]})

            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const responseData = JSON.parse(xhr.responseText)
                    setDraftFileUrl(responseData.data.draft_file_url);
                }
            }

            xhr.open('PATCH', `${process.env.REACT_APP_DSP_BACKEND_API}/api/v1/drafts/${draftForm.id}/upload`);
            // xhr.setRequestHeader("Content-Type","multipart/form-data");
            let token = ''
            if (localStorage.getItem("user")) {
                token = JSON.parse(localStorage.getItem("user") || '').accessToken;
            }
            if (token) {
                xhr.setRequestHeader("authorization", `Bearer ${token}`);
            }
            let bodyFormData = new FormData();
            bodyFormData.append('draft[draft_file]', e.target.files[0]);

            xhr.send(bodyFormData)
        }
    };

    const createBasicDraft = async (event: any) => {
        event.preventDefault()

        setNameError(false)

        if (draftForm.name == '') {
            setNameError(true)
        }

        if (draftForm.name) {
            try {
                const {adTags, ...rest} = draftForm
                const result = await createDraft({adTagIds: adTags, ...rest}).unwrap()
                dispatch(setDraftForm({...draftForm, id: result.data.id}))
            } catch (err: any) {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        }
    }


    function nextStep() {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);

        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    const handleNext = async (event: any) => {

        event.preventDefault()

        if (activeStep === steps.length - 1) {
            try {
                const {adTags, draftPropertiesAttributes, ...rest} = draftForm
                await updateDraft({
                    draftPropertiesAttributes: draftPropertiesAttributes.map((el, index) => {
                        const {uuid, ...rest} = el
                        return {
                            ...rest,
                            position: index
                        }
                    }), adTagIds: adTags, ...rest
                }).unwrap()
                dispatch(setDraftForm({...initialDraftFormState.draftForm}))
                setCheckedNodes([])
                navigate('/')
                enqueueSnackbar(`${urlId ? 'Pomyślnie zaktualizowano schemat' : 'Pomyślnie utworzono schemat'}`, {variant: 'success'});
                return
            } catch (err: any) {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
                return
            }
        }


        setNameError(false)

        if (draftForm.name === '') {
            setNameError(true)
        }

        if (draftForm.name && !draftForm.id) {
            try {
                const {adTags, draftPropertiesAttributes, ...rest} = draftForm

                const result = await createDraft({
                    draftPropertiesAttributes: draftPropertiesAttributes.map((el, index) => {
                        const {uuid, ...rest} = el
                        return {
                            ...rest,
                            position: index
                        }
                    }), adTagIds: adTags, ...rest
                }).unwrap()
                dispatch(setDraftForm({...draftForm, id: result.data.id}))
                nextStep()
            } catch (err: any) {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        }


        if (draftForm.id) {
            nextStep();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onFilterNodeChange = (event: any) => {
        setFilterNodesText(event.target.value)
    }

    const filterNodes = (filtered: any, node: any) => {
        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().match(filterNodesText.toString().toLocaleLowerCase())
        ) {
            filtered.push(node)
            return filtered;
        }

        if (node.children && node.children.length > 0) {
            const children = (node.children || []).reduce(filterNodes, []);
            if (children.length > 0) {
                filtered.push({...node, children});
            }
        }
        return filtered;
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    const getItemStyle = (isDragging: any, draggableStyle: any) => {


        return {
            // some basic styles to make the items look a bit nicer
            userSelect: "none",

            // change background colour if dragging
            background: isDragging ? '#e5e6eb' : '#ffffff',
            display: isDragging ? 'table' : 'table-row',
            textAlign: isDragging ? 'right' : 'none',

            // styles we need to apply on draggables
            ...draggableStyle
        }
    }


    const schemaParametersStep = <Box sx={{paddingTop: '40px'}}>
        <form autoComplete="off" onSubmit={createBasicDraft}>
            <TextField
                value={draftForm.name}
                label="Nazwa schematu"
                onChange={(event) => {
                    dispatch(setDraftForm({
                        ...draftForm,
                        name: event.target.value
                    }));
                }}
                required
                variant="outlined"
                sx={{mb: 3}}
                fullWidth
                error={nameError}/>
            <FormControl fullWidth sx={{marginTop: '15px'}}>
                <InputLabel shrink id="demo-simple-select-label">Format nośnika *</InputLabel>
                <Select
                    variant={"outlined"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Format nośnika *"
                    value={draftForm.mediaFormat || ''}
                    notched
                    required
                    onChange={(event) => {
                        setCheckedNodes([])
                        dispatch(setDraftForm({
                            ...draftForm,
                            mediaFormat: event.target.value
                        }));
                    }}
                >
                    <MenuItem value="">
                        <em>Brak</em>
                    </MenuItem>
                    {cmEnums && (cmEnums.find(cmEnum => cmEnum.name === 'mediaFormats')?.values as string[]).map((mediaFormat) => (
                        <MenuItem
                            key={mediaFormat}
                            value={mediaFormat}>{mediaFormat}</MenuItem>
                    ))}
                </Select>
            </FormControl>


            <FormControl fullWidth sx={{marginTop: '45px'}}>
                {tagsData && (<Autocomplete
                    multiple
                    id="tags-filled"
                    options={tagsData.map((option) => option.name)}
                    value={draftForm.tagList}
                    onChange={(_, value) => {
                        dispatch(setDraftForm({
                            ...draftForm,
                            tagList: value
                        }));
                    }}
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => {
                            const {key, ...tagProps} = getTagProps({index});
                            return (
                                <Chip sx={{marginY: '10px'}} color={"info"} variant="filled"
                                      label={option}
                                      key={key} {...tagProps} />
                            );
                        })
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Tagi"
                            placeholder={tagsData.length === 0 ? 'Brak utworzonych tagów... wpisz nowy i zatwierdź enterem' : 'Wybierz z listy lub wpisz nowy i zatwierdź enterem'}
                        />
                    )}
                />)}
            </FormControl>

            {cmEnums && (<FormControl fullWidth sx={{marginTop: '45px'}}>
                <Autocomplete
                    multiple
                    id="tags-filled"
                    options={(cmEnums.find(cmEnum => cmEnum.name === 'adTags')?.values as {
                        id: number;
                        name: string
                    }[]).map(el => el.id)}
                    value={draftForm.adTags}
                    noOptionsText={'Brak ad tagów...'}
                    renderOption={({key, ...props}, option: number) => (
                        <li key={key} {...props}>{(cmEnums.find(cmEnum => cmEnum.name === 'adTags')?.values as {
                            id: number;
                            name: string
                        }[]).filter(el => el.id === option)[0].name}
                        </li>
                    )}
                    onChange={(event, value) => {
                        dispatch(setDraftForm({
                            ...draftForm,
                            adTags: value
                        }));
                    }}
                    filterOptions={(options, {inputValue}) => {
                        return (cmEnums.find(cmEnum => cmEnum.name === 'adTags')?.values as {
                            id: number;
                            name: string
                        }[]).filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase())).map(el => el.id);
                    }}
                    renderTags={(selected) => (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {(selected as any).map((value: any) => (
                                <Chip
                                    color={'primary'}
                                    key={value} label={(cmEnums?.find(cmEnum => cmEnum.name === 'adTags')?.values as {
                                    id: number;
                                    name: string
                                }[]).filter(el => el.id === value)[0].name}/>
                            ))}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Ad tagi"
                        />
                    )}
                />
            </FormControl>)}


            <FormControlLabel sx={{width: '100%', paddingY: '20px'}} control={<Checkbox checked={draftForm.hostContent}
                                                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                                            dispatch(setDraftForm({
                                                                                                ...draftForm,
                                                                                                hostContent: event.target.checked
                                                                                            }));
                                                                                        }}/>} label="Host content"/>

            <Button disabled={loadingMediaTree || !draftForm.mediaFormat} variant="contained"
                    onClick={handleOpenNodesModal} sx={{marginTop: '20px'}}>{t('draftForm.mediaPicker')}&nbsp;
                {(loadingMediaTree) && draftForm.mediaFormat ? <CircularProgress sx={{marginLeft: '10px'}}
                                                                                 size={20}/> : `${checkedNodes.length}/${mediaLength || 0}`}</Button>
            <Dialog
                open={openMediaTreeModal}
                onClose={handleCloseNodesModal}
                fullWidth={true}
                maxWidth="md"
            >

                <DialogTitle id="alert-dialog-title">
                    {t("draftForm.chooseMedia")}
                </DialogTitle>
                <DialogContent>

                    {(loadingMediaTree) && (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                            <CircularProgress size={100}/>
                        </Box>)}

                    <Box sx={{minHeight: '300px'}}>
                        {mediaTree && (
                            <><TextField
                                value={filterNodesText}
                                label="Wyszukaj..."
                                onInput={onFilterNodeChange}
                                variant="outlined"
                                sx={{mt: 1, mb: 3}}
                                fullWidth
                            />
                                <CheckboxTree
                                    ref={checkboxTreeRef}
                                    key={treeState.key}
                                    showNodeTitles={true}
                                    nodes={filteredNodes}
                                    checked={checkedNodes}
                                    expanded={expanded}
                                    showExpandAll={true}
                                    onCheck={(checked: any) => {
                                        setCheckedNodes(checked)
                                        setTreeState({key: Math.random()})
                                    }}
                                    onExpand={(expanded: any) => {
                                        setExpanded(expanded)
                                    }}
                                /></>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNodesModal}>{t('common.closeWindow')}</Button>
                </DialogActions>


            </Dialog>
        </form>
    </Box>


    const createSchemaStep =
        <Box sx={{paddingTop: '40px'}}>
            <form autoComplete="off" onSubmit={createBasicDraft}>
                <h4>{t("draftForm.customProperty.title")}</h4>
                <DragDropContext onDragEnd={(event) => {
                    handleDragEnd(event)
                }}>
                    <TableContainer sx={{width: 650, marginBottom: '30px'}} component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('draftForm.customProperty.key')}</TableCell>
                                    <TableCell>{t('draftForm.customProperty.name')}</TableCell>
                                    <TableCell>{t('draftForm.customProperty.required')}</TableCell>
                                    <TableCell>{t('draftForm.customProperty.propertyType')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <Droppable droppableId="draftPropsDroppable">
                                {(provider: any, snapshot: any) => (
                                    <TableBody ref={provider.innerRef} {...provider.droppableProps}
                                               style={draggingOverStyle(snapshot.isDraggingOver)}>
                                        {draftForm.draftPropertiesAttributes.filter(el => !el._destroy).map((row: PropertyAttribute, index) => (

                                            <Draggable key={row.uuid} draggableId={row.uuid!} index={index}>
                                                {(provider, snapshot) => (
                                                    <TableRow
                                                        key={row.uuid}
                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                        {...provider.draggableProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provider.draggableProps.style,
                                                        )}
                                                        ref={provider.innerRef}

                                                    >
                                                        <TableCell component="th"
                                                                   scope="row" {...provider.dragHandleProps}>
                                                            {row.key}
                                                        </TableCell>
                                                        <TableCell component="th"
                                                                   scope="row" {...provider.dragHandleProps}>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell {...provider.dragHandleProps}>
                                                            {row?.required && (
                                                                <DoneIcon></DoneIcon>)}
                                                        </TableCell>
                                                        <TableCell {...provider.dragHandleProps}>{t(`drafts.propType.${row.propType}`)}</TableCell>
                                                        <TableCell {...provider.dragHandleProps}><Button color="error"
                                                                                                         variant="text"
                                                                                                         onClick={() => {
                                                                                                             dispatch(setDraftForm({
                                                                                                                 ...draftForm,
                                                                                                                 draftPropertiesAttributes:
                                                                                                                     row.id ?
                                                                                                                         [...draftForm.draftPropertiesAttributes.map((prop) => {
                                                                                                                             if (prop.id === row.id) {
                                                                                                                                 return {
                                                                                                                                     ...prop,
                                                                                                                                     _destroy: true
                                                                                                                                 }
                                                                                                                             }
                                                                                                                             return prop
                                                                                                                         })] : [...draftForm.draftPropertiesAttributes.filter(prop => prop !== row)],
                                                                                                             }));
                                                                                                         }}>{t('common.remove')}</Button></TableCell>
                                                    </TableRow>
                                                )}
                                            </Draggable>

                                        ))}
                                        {provider.placeholder}

                                    </TableBody>
                                )}
                            </Droppable>
                        </Table>
                    </TableContainer></DragDropContext>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                    <TextField
                        value={customProp.key}
                        label={t("draftForm.customProperty.key")}
                        onChange={(event) => {
                            setAttrKeyError(false)
                            setCustomProp({...customProp, key: event.target.value});
                        }}
                        required
                        variant="outlined"
                        sx={{mb: 3, mr: 3}}
                        error={attrKeyError}/>
                    <TextField
                        value={customProp.name}
                        label={t("draftForm.customProperty.name")}
                        onChange={(event) => {
                            setAttrNameError(false)
                            setCustomProp({...customProp, name: event.target.value});
                        }}
                        required
                        error={attrNameError}
                        variant="outlined"
                        sx={{mb: 3, mr: 3}}
                    />
                    <FormControl>
                        <InputLabel
                            id="demo-simple-select-label">{t("draftForm.customProperty.propertyType")}</InputLabel>
                        <Select
                            sx={{minWidth: 200}}
                            variant={"outlined"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("draftForm.customProperty.propertyType")}
                            value={customProp.propType}
                            notched
                            onChange={(event) => {
                                setCustomProp({
                                    ...customProp,
                                    propType: event.target.value,
                                    required: event.target.value === 'boolean' ? false : customProp.required
                                });
                            }}
                        >
                            {cmEnums && (cmEnums.find(cmEnum => cmEnum.name === 'propTypes')?.values as string[]).map((propType) => (
                                <MenuItem
                                    key={propType}
                                    value={propType}>
                                    {t(`drafts.propType.${propType}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', paddingX: '10px'}}>
                        <FormControlLabel disabled={customProp.propType === 'boolean'}
                                          control={<Checkbox checked={customProp.required} onChange={(event) => {
                                              setCustomProp({...customProp, required: event.target.checked});
                                          }}/>} label={t('draftForm.customProperty.required')}/>
                    </Box>
                    <Button disabled={!customProp.name} variant="text"
                            sx={{marginLeft: '20px', height: '55px', width: '120px'}} onClick={() => {

                        let errors = false;
                        if (draftForm.draftPropertiesAttributes.some((attr) => attr.name.toLowerCase() === customProp.name.toLowerCase())) {
                            enqueueSnackbar('Wystąpił błąd, zmienna o takiej nazwie już istnieje', {variant: 'error'});
                            setAttrNameError(true)
                            errors = true;
                        }

                        if (draftForm.draftPropertiesAttributes.some((attr) => attr.key.toLowerCase() === customProp.key.toLowerCase())) {
                            enqueueSnackbar('Wystąpił błąd, zmienna o takim kluczu już istnieje', {variant: 'error'});
                            setAttrKeyError(true)
                            errors = true;
                        }

                        if (errors) {
                            return;
                        }

                        dispatch(setDraftForm({
                            ...draftForm,
                            draftPropertiesAttributes: [...draftForm.draftPropertiesAttributes, {
                                uuid: getUuid(0),
                                position: draftForm.draftPropertiesAttributes.length - 1,
                                name: customProp.name,
                                key: customProp.key,
                                required: customProp.required,
                                propType: customProp.propType
                            }],
                        }));
                        setCustomProp({name: '', key: '', required: false, propType: customProp.propType});

                    }}>{t("common.add")}</Button>
                </Box>
                <h4>{t("common.uploadFile")}</h4>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px'}}
                ><Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon/>}
                >
                    {t("draftForm.draftFile")}
                    <VisuallyHiddenInput type="file" accept=".zip,.rar,.7zip" onChange={handleFileChange}/>
                </Button>
                    {(draftFileUrl) && (
                        <Link href={draftFileUrl}>{draftFileUrl.split('/')[draftFileUrl.split('/').length - 1]}</Link>)}
                </Box>
            </form>
        </Box>

    return (
        <>
            <Box className={'page-header'}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>

                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', gap: '40px'}}>
                        <h1>{urlId ? t("draftForm.editSchema") + " " + draftForm.name : t("draftForm.newSchemaCreator")}</h1>
                        {(draftDetails && draftDetails.incompleteReason) && (<Alert severity="warning">
                            <AlertTitle><b>Niekompletny szablon</b></AlertTitle>
                            {draftDetails.incompleteReason.map((el: string, index: number) =>
                                <Typography
                                    key={index}>{incompleteReasons[el as keyof typeof incompleteReasons]}</Typography>)}
                        </Alert>)}
                    </Box>


                    {urlId && (<Button onClick={() => {
                        setOpenRemovalModal(true)
                    }} variant={'contained'} color={'error'}>{t('drafts.removeDraft')}</Button>)}
                </Box>
                <p>{t("draftForm.steps.schemaParameters.title")}</p>
            </Box>
            <Box sx={{width: '100%', paddingTop: '20px'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <>
                    {activeStep === 0 && (schemaParametersStep)}

                    {activeStep === 1 && (createSchemaStep)}

                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        {activeStep !== 0 && (<Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            {t("common.back")}
                        </Button>)
                        }
                        <Box sx={{flex: '1 1 auto'}}/>
                        <Button onClick={handleNext} disabled={!draftForm.name || !draftForm.mediaFormat}>
                            {activeStep === steps.length - 1 ? (urlId ? t("draftForm.steps.createSchema.updateTitle") : t("draftForm.steps.createSchema.createTitle")) : t("common.next")}
                        </Button>
                    </Box>
                </>
            </Box>
            <Dialog
                open={openRemovalModal}
                onClose={handleCloseDeleteDraftModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('drafts.removingDraft')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('drafts.removalConfirmation', {draftName: draftDetails?.name})}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={() => {
                        handleCloseDeleteDraftModal(true)
                    }}>{t('common.remove')}</Button>
                    <Button onClick={() => {
                        handleCloseDeleteDraftModal(false)
                    }} autoFocus>
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/*<TextField*/}
            {/*    value={canvaText}*/}
            {/*    label="Tekst"*/}
            {/*    onChange={(event) => {*/}
            {/*      setCanvaText(event.target.value);*/}
            {/*    }}*/}
            {/*    required*/}
            {/*    variant="outlined"*/}
            {/*    sx={{mb: 3}}*/}
            {/*    fullWidth*/}
            {/*    error={nameError}/>*/}
            {/*<Button variant={'contained'} onClick={() => {*/}
            {/*    addCanvaText()*/}
            {/*}}>{t('common.add')}</Button>*/}
            {/*<Stage width={window.innerWidth} height={window.innerHeight}>*/}
            {/*    <Layer>*/}
            {/*        {canvaTexts.map((text, index) => (*/}
            {/*            <Text*/}
            {/*                fontSize={20}*/}
            {/*                draggable={true}*/}
            {/*                text={text}*/}
            {/*                stroke='black'*/}
            {/*                strokeWidth={1}*/}
            {/*                align="center"*/}
            {/*                // similar coordinates than your Rect component*/}
            {/*                x={480}*/}
            {/*                y={90}*/}
            {/*            />*/}
            {/*        ))}*/}

            {/*        {rectangles.map((rect, i) => {*/}
            {/*            return (*/}
            {/*                <Rectangle*/}
            {/*                    key={i}*/}
            {/*                    shapeProps={rect}*/}
            {/*                    isSelected={rect.id === selectedId}*/}
            {/*                    onSelect={() => {*/}
            {/*                        selectShape(rect.id as any);*/}
            {/*                    }}*/}
            {/*                    onChange={(newAttrs: any) => {*/}
            {/*                        const rects = rectangles.slice();*/}
            {/*                        rects[i] = newAttrs;*/}
            {/*                        setRectangles(rects);*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            );*/}
            {/*        })}*/}


            {/*        {stars.map((star) => (*/}
            {/*            <Star*/}
            {/*                key={star.id}*/}
            {/*                id={star.id}*/}
            {/*                x={star.x}*/}
            {/*                y={star.y}*/}
            {/*                numPoints={5}*/}
            {/*                innerRadius={20}*/}
            {/*                outerRadius={40}*/}
            {/*                fill="#89b717"*/}
            {/*                opacity={0.8}*/}
            {/*                draggable*/}
            {/*                rotation={star.rotation}*/}
            {/*                shadowColor="black"*/}
            {/*                shadowBlur={10}*/}
            {/*                shadowOpacity={0.6}*/}
            {/*                shadowOffsetX={star.isDragging ? 10 : 5}*/}
            {/*                shadowOffsetY={star.isDragging ? 10 : 5}*/}
            {/*                scaleX={star.isDragging ? 1.2 : 1}*/}
            {/*                scaleY={star.isDragging ? 1.2 : 1}*/}
            {/*                onDragStart={handleDragStart}*/}
            {/*                onDragEnd={handleDragEnd}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </Layer>*/}
            {/*</Stage>*/}


        </>
    );
};

export default DraftForm;
