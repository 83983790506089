import {ApiTags, baseApi} from "../../../app/api/baseApi";
import {Draft, DraftDetails, DraftFilteringMeta, DraftForm, DraftPayload, MediaNode, Tag} from "../types";
import {CmQueryResult} from "../../../app/types";


export const draftsApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getDraftsFiltersMeta: builder.query<CmQueryResult<DraftFilteringMeta>, void>({
            query: () => {
                return {
                    reducerPath: 'draftsFiltersMeta',
                    url: `/api/v1/drafts/filters_meta`,
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120
        }),
        getDrafts: builder.query<CmQueryResult<Draft[]>, string>({
            query: (query) => {
                return {
                    reducerPath: 'drafts',
                    url: `/api/v1/drafts?${query}`,
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<Draft[]>) => {
                return {
                    ...response,
                    data: response.data.map((el) => {
                        if (typeof el.incompleteReason === 'string') {
                            return {
                                ...el,
                                incompleteReason: [el.incompleteReason]
                            }
                        }

                        return {
                            ...el
                        }
                    })
                }
            },
            providesTags: (_) => [ApiTags.DraftsList],
            keepUnusedDataFor: 5
        }),
        getDraft: builder.query<DraftDetails, string>({
            query: (draftId) => {
                return {
                    reducerPath: 'draftDetails',
                    url: `/api/v1/drafts/${draftId}`,
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<DraftDetails>) => {
                return {
                    ...response.data, media: response.data.media.filter((obj1, i, arr) =>
                        arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
                    ),
                    draftProperties: response.data.draftProperties.map((el, index) => {
                        return {
                            ...el,
                            position: index
                        }
                    }),
                    incompleteReason: typeof response.data.incompleteReason === "string" ? [response.data.incompleteReason] : response.data.incompleteReason
                }
            },
            providesTags: (_) => [ApiTags.DraftDetails],
        }),
        getTags: builder.query<Tag[], void>({
            query: () => {
                return {
                    reducerPath: 'tags',
                    url: `/api/v1/tags`,
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<Tag[]>) => {
                return response.data
            },
            keepUnusedDataFor: 120
        }),
        getMediaTree: builder.query<MediaNode[], string | void>({
            query: (mediaFormat?) => {
                return {
                    reducerPath: 'mediaTree',
                    url: `/api/v1/media/tree${mediaFormat ? '/?media_format=' + mediaFormat : ''}`,
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<MediaNode[]>) => {
                return response.data
            },
            keepUnusedDataFor: 120
        }),

        createDraft: builder.mutation<any, DraftPayload>({
            query: (draftFormPayload: DraftPayload): any => {
                return {
                    url: '/api/v1/drafts',
                    method: 'POST',
                    body: {...draftFormPayload}
                }
            },
        }),
        deleteDraft: builder.mutation<any, any>({
            query: (draftId: number): any => {
                return {
                    url: `/api/v1/drafts/${draftId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (_) => [ApiTags.DraftsList]
        }),
        updateDraft: builder.mutation<any, DraftPayload>({
            query: (draftFormUpdatePayload: DraftPayload): any => {
                let {id, ...rest} = draftFormUpdatePayload;

                return {
                    url: `/api/v1/drafts/${id}`,
                    method: 'PUT',
                    body: {...rest}
                }
            },
            invalidatesTags: (_) => [ApiTags.DraftDetails, ApiTags.DraftsList]
        }),


        //todo check if possible upload file using rtk

        // uploadDraftFile: builder.mutation<any, FileUpload>({
        //     query: (fileUploadData: FileUpload): any => {
        //
        //         let bodyFormData = new FormData();
        //         bodyFormData.append('draft[draft_file]', 'test' );
        //         return {
        //             url: `/api/v1/drafts/${fileUploadData.draftId}/upload`,
        //             method: 'PATCH',
        //             headers: {
        //                 'Content-Type': 'multipart/form-data;'
        //             },
        //             body:  bodyFormData ,
        //             formData: true
        //         }
        //     },
        // })
    })
})

export const {
    useGetMediaTreeQuery,
    useGetTagsQuery,
    useGetDraftsQuery,
    useGetDraftQuery,
    useGetDraftsFiltersMetaQuery,
    useCreateDraftMutation,
    useDeleteDraftMutation,
    useUpdateDraftMutation
} = draftsApiSlice