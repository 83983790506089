import React from "react";
import {MediabankFileSelectDialogProps} from "./types";
import {
    Box,
    Breadcrumbs,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Link from "@mui/material/Link";
import {ResourceType} from "../../mediabank/types";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {Thumbnail} from "../../../config/images";
import {BreadCrumb} from "../../mediabank/store/mediabankResourcesSlice";
import {useGetMediabankResourcesForSelectQuery} from "../../mediabank/api/mediabankResourcesApiSlice";
const mediabankBreadcrumbsInitialState = [{
    dirName: 'Katalog główny',
    resourceId: null
}]

const MediabankFileSelectDialog: React.FC<MediabankFileSelectDialogProps> = ({
                                                                                 openMediabankDialog,
                                                                                 onSelectMediabankFile,
                                                                                 selectedMediabankResource,
                                                                                 onClose
                                                                             }) => {


    const [breadcrumbsForMediabankDialog, setBreadcrumbsForMediabankDialog] = React.useState<BreadCrumb[]>(mediabankBreadcrumbsInitialState);
    const [parentIdForForMediabankDialog, setParentIdForMediabankDialog] = React.useState<string | null>(null);


    const {isLoading: loadingResourcesForMediabankDialog, data: resourcesDataForMediabankDialog} =
        useGetMediabankResourcesForSelectQuery(parentIdForForMediabankDialog, {skip: !openMediabankDialog});

    const {t} = useTranslation();


    return (
        <Dialog
            open={openMediabankDialog}
            onClose={() => {
                onClose()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Wybierz plik mediabanku
            </DialogTitle>
            <DialogContent>
                <Box sx={{width: 500, bgcolor: 'background.paper'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        {breadcrumbsForMediabankDialog.length && breadcrumbsForMediabankDialog.map((breadcrumb, index) => {
                            return index !== breadcrumbsForMediabankDialog.length - 1 ? (
                                <Link key={breadcrumb.dirName} underline="hover"
                                      color="inherit"
                                      onClick={() => {
                                          setParentIdForMediabankDialog(breadcrumb.resourceId)
                                          setBreadcrumbsForMediabankDialog(breadcrumbsForMediabankDialog.slice(0, index + 1))
                                      }} component="button">
                                    {breadcrumb.dirName}
                                </Link>
                            ) : (<Typography key={breadcrumb.dirName}
                                             sx={{color: 'text.primary'}}>{breadcrumb.dirName}</Typography>)
                        })}
                    </Breadcrumbs>
                    <List>
                        {
                            (resourcesDataForMediabankDialog) && (
                                resourcesDataForMediabankDialog.data
                                    .map((resource) => {
                                        return (<ListItem key={resource.id} disablePadding
                                                          selected={!!selectedMediabankResource && selectedMediabankResource.id === resource.id}
                                                          sx={{height: '50px'}}>
                                            {resource.resourceType === ResourceType.Dir ? (
                                                <ListItemButton onClick={() => {
                                                    setParentIdForMediabankDialog(resource.id.toString())
                                                    setBreadcrumbsForMediabankDialog([...breadcrumbsForMediabankDialog, {
                                                        resourceId: resource.id.toString(),
                                                        dirName: resource.name
                                                    }])
                                                }}>
                                                    <ListItemIcon>
                                                        <FolderOutlinedIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={resource.name}/>
                                                </ListItemButton>) : (
                                                <ListItemButton onClick={() => {
                                                    onSelectMediabankFile(resource)
                                                }}>
                                                    <ListItemIcon>
                                                        {
                                                            !resource.thumbnailUrl ? (
                                                                <DescriptionOutlinedIcon
                                                                    fontSize="large"/>) : (
                                                                <Box sx={{
                                                                    height: Thumbnail.width,
                                                                    width: Thumbnail.width,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: Thumbnail.background
                                                                }}><img
                                                                    width={Thumbnail.width}
                                                                    height={Thumbnail.width}
                                                                    src={`${resource.thumbnailUrl}`}
                                                                    alt={resource.name}
                                                                    loading="lazy"/>
                                                                </Box>)
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={resource.name}/>
                                                </ListItemButton>)}
                                        </ListItem>)
                                    })
                            )
                        }

                        {(resourcesDataForMediabankDialog && resourcesDataForMediabankDialog.data.length === 0) && (
                            <Typography
                                sx={{color: 'text.primary', paddingY: '10px'}}>Brak
                                plików...</Typography>)
                        }
                    </List>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onClose()
                }} autoFocus>
                    {t('common.cancel')}
                </Button>
            </DialogActions>
        </Dialog>

    )
}


export default MediabankFileSelectDialog;