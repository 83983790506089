import {HelpCenterRounded} from "@mui/icons-material";
import {Avatar, Box, Button, Container, CssBaseline, Typography,} from "@mui/material";
import {useNavigate} from "react-router-dom";

const NotFound = () => {


    const navigate = useNavigate();

    return (
        <>
            <Container maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        mt: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <h1>404</h1>
                    <Typography sx={{textAlign: 'center'}} variant="h5">Nie odnaleziono zasobu lub strona o podanym adresie nie istnieje...</Typography>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{mt: 5, mb: 2}}
                        onClick={()=>{navigate('/')}}
                    >
                        Powrót do strony głownej
                    </Button>
                </Box>
            </Container>
        </>
    );
};

export default NotFound;