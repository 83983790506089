import React from 'react';
import {StatusIconBoxProps} from "./types";
import ModeIcon from "@mui/icons-material/Mode";
import {Box, Typography}     from "@mui/material";
import {useTranslation} from "react-i18next";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import HtmlTooltip from "../../features/shared/htmlTooltip/htmlTooltip";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const CmCreationStatusIconBox: React.FC<StatusIconBoxProps> = ({state}) => {
    const {t} = useTranslation();
    const sx = { display: 'flex', alignItems: 'center'}
    
    switch (state) {
        case 'accepted':
            return <Box sx={sx}>
                <HtmlTooltip

                    title={<React.Fragment>
                        <Typography
                            color="inherit">{t('creations.status.accepted')}</Typography>
                    </React.Fragment>}>
                    <div className={'IconBox IconBox--green'}><CheckIcon></CheckIcon></div>
                </HtmlTooltip>
            </Box>;
        case 'rejected':
            return <Box sx={sx}>
                <HtmlTooltip title={<React.Fragment>
                    <Typography
                    color="inherit">{t('creations.status.rejected')}</Typography>
                    </React.Fragment>}>
                    <div className={'IconBox IconBox--red'}><DoDisturbOnIcon></DoDisturbOnIcon></div>
                </HtmlTooltip>
            </Box>;
        case 'completed':
            return <Box sx={sx}>
                <HtmlTooltip title={<React.Fragment>
                    <Typography
                        color="inherit">{t('creations.status.completed')}</Typography>
                </React.Fragment>}>
                    <div className={'IconBox IconBox--green-dark'}><CheckCircleIcon></CheckCircleIcon></div>
                </HtmlTooltip>
            </Box>;
        case 'pending_review':
            return <Box sx={sx}>
                <HtmlTooltip title={<React.Fragment>
                    <Typography
                        color="inherit">{t('creations.status.pending_review')}</Typography>
                </React.Fragment>}>
                    <div className={'IconBox IconBox--blue'}><PendingActionsIcon></PendingActionsIcon></div>
                </HtmlTooltip>
            </Box>;
        default:
            return (<Box sx={sx}>
                <HtmlTooltip title={<React.Fragment>
                    <Typography
                        color="inherit">{t('creations.status.draft')}</Typography>
                </React.Fragment>}>
                    <div className={'IconBox IconBox--purple'}><ModeIcon></ModeIcon></div>
                </HtmlTooltip>
            </Box>);
    }
}

export default CmCreationStatusIconBox;