import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from 'notistack';
import {FilterItemChip} from "../../drafts/types";
import {
    BreadCrumb,
    initialCreationFormState,
    selectBreadCrumbs,
    selectCurrentMediabankResourcesFiltersState,
    setBreadCrumbs,
    setMediabankFilters,
    setVisibleUploudBox
} from "../store/mediabankResourcesSlice";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
    useChangeResourceDataMutation,
    useChangeResourceLocationMutation,
    useCreateResourceMutation,
    useGetMediabankResourcesForSelectQuery,
    useGetMediabankResourcesQuery,
    useLazyGetMediabankResourceQuery
} from "../api/mediabankResourcesApiSlice";
import {
    alpha,
    Autocomplete,
    Badge,
    Box,
    Breadcrumbs,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    SelectChangeEvent,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {Clear} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import useDebounce from "../../../hooks/useDebounce";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import {
    ChangeResourceLocationPayload,
    MediabankFile,
    MediabankResource,
    MediabankResourcesFilters,
    MediabankResourceTableRow,
    ResourcePayload,
    ResourceType
} from "../types";
import {visuallyHidden} from '@mui/utils';
import {useGetCmEnumsQuery, useGetCmTagsQuery} from "../../../app/appApiSlice";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import Link from "@mui/material/Link";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {VisuallyHiddenInput} from "../../../config/visuallyHiddenInput";
import {ApiTags, baseApi} from "../../../app/api/baseApi";
import DialogContentText from "@mui/material/DialogContentText";
import {Thumbnail} from "../../../config/images";
import {VoohCategory} from "../../vooh/types";
import {getUuid} from "../../../config/general";
import 'filepond/dist/filepond.min.css';
import OwnershipSelect from "../../shared/ownershipSelect/OwnershipSelect";
import {OwnershipAttribute} from "../../creations/types";
import {useRefreshTokenMutation} from "../../auth/authApiSlice";


type Order = 'asc' | 'desc';

interface EnhancedTableToolbarProps {
    numSelected: number;
}

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof MediabankResourceTableRow) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof MediabankResourceTableRow;
    label: string;
    numeric: boolean;
    sort: boolean;
}

const MediaList: React.FC<{
    setMediabankFilesToUploud?: (mediabankFiles: MediabankFile[]) => void
}> = ({setMediabankFilesToUploud}) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();

    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Nazwa folderu',
            sort: true
        },
        {
            id: 'byteSize',
            numeric: false,
            disablePadding: true,
            label: 'Rozmiar',
            sort: true
        },
        {
            id: 'metadata',
            numeric: false,
            disablePadding: true,
            label: 'Rozdzielczość',
            sort: false
        },
        {
            id: 'tagList',
            numeric: true,
            disablePadding: false,
            label: 'Tagi',
            sort: false
        },
        {
            id: 'categoryId',
            numeric: true,
            disablePadding: false,
            label: 'Kategoria',
            sort: false
        },
        {
            id: 'updatedAt',
            numeric: true,
            disablePadding: false,
            label: 'Data wgrania',
            sort: true
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: '',
            sort: false
        }
    ];

    const currentFiltersState = useSelector(selectCurrentMediabankResourcesFiltersState);
    const breadCrumbs = useSelector(selectBreadCrumbs)
    const [searchByName, setSearchByName] = React.useState<string>('');
    const [newDirectoryName, setNewDirectoryName] = React.useState<string>('');
    const [tagsForResource, setTagsForResource] = React.useState<string[]>([]);
    const [categoryForResource, setCategoryForResource] = React.useState<string | undefined>('');

    const [selected, setSelected] = React.useState<number[]>([]);
    const [mediabankFiles, setMediabankFiles] = useState<MediabankFile[]>([]);
    const [blobFiles, setBlobFiles] = useState<Blob[]>([]);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof MediabankResourceTableRow>('name');
    const [openAddResource, setOpenAddResource] = React.useState<ResourceType | null>(null);
    const [openAssignAttributesToFiles, setOpenAssignAttributesToFiles] = React.useState<MediabankFile[]>([]);
    const [openRemoveFileConfirmationModal, setOpenRemoveFileConfirmationModal] = React.useState<boolean | number>(false);
    const [openMoveResourcesDialog, setOpenMoveResourcesDialog] = React.useState<boolean>(false);
    const [parentIdForMoveResources, setParentIdForMoveResources] = React.useState<string | null>(null);
    const [breadcrumbsForMoveResources, setBreadcrumbsForMoveResources] = React.useState<BreadCrumb[]>([]);
    const [creatingResource, setCreatingResource] = React.useState<boolean>(false);
    const [anchorElForActionMenu, setAnchorElForActionMenu] = React.useState<null | HTMLElement>(null);
    const [activeRow, setActiveRow] = React.useState<number | null>(null);
    const [openEditResourceMenu, setOpenEditResourceMenu] = React.useState<number | null>(null);

    const [editedResource, setEditedResource] = React.useState<MediabankResource | null>(null);
    const [selectedFilesForAssigningAttributes, setSelectedFilesForAssigningAttributes] = React.useState<string[]>([]);
    const [ownershipsAttributesForResource, setOwnershipsAttributesForResource] = React.useState<OwnershipAttribute[]>([]);


    const openActionMenu = Boolean(anchorElForActionMenu);

    const handleOpenAddResource = (resourceType: ResourceType) => {
        resetAddResourceState()
        setOpenAddResource(resourceType);
    }

    function resetAddResourceState() {
        setOpenAddResource(null)
        setMediabankFiles([])
        setNewDirectoryName('')
        setTagsForResource([])
        setSelectedFilesForAssigningAttributes([])
        setOwnershipsAttributesForResource([])
    }

    const handleCloseAddResource = () => {
        resetAddResourceState();
    };
    useEffect(() => () => {
        dispatch(setMediabankFilters(initialCreationFormState.filters))
        dispatch(setBreadCrumbs(initialCreationFormState.resourceBreadcrumbs))
    }, []);


    const {t} = useTranslation();
    const [createResource] = useCreateResourceMutation()
    const [refreshToken] = useRefreshTokenMutation()
    const [changeResourceLocation, {isLoading: changingResourceLocation}] = useChangeResourceLocationMutation()
    const [changeResourceDataMutation, {isLoading: changingResourceName}] = useChangeResourceDataMutation()

    const {isLoading: loadingCmEnums, data: cmEnums} =
        useGetCmEnumsQuery();

    const voohCategories: VoohCategory[] | undefined = cmEnums && cmEnums?.filter(el => el.name === 'categories')[0].values as VoohCategory[]

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof MediabankResourceTableRow,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllFilesForChangingDirectoryClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && mediabankResources) {
            const newSelected = mediabankResources?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleSelectAllFilesForAssigningAttributes = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && mediabankFiles.length > 0) {
            const newSelected = mediabankFiles?.map((n) => n.uuid);
            setSelectedFilesForAssigningAttributes(newSelected);
            return;
        }
        setSelectedFilesForAssigningAttributes([]);
    };

    const handleOpenMoveResource = () => {
        setParentIdForMoveResources(null)
        setOpenMoveResourcesDialog(true);
    }

    const addResource = async (resourceType: ResourceType) => {
        if (resourceType === ResourceType.Dir) {
            if (!newDirectoryName) {
                return
            }
            try {
                setCreatingResource(true)
                const resourcePayload: ResourcePayload = {
                    name: newDirectoryName,
                    resourceType: ResourceType.Dir,
                    tagList: tagsForResource,
                    ownershipsAttributes: ownershipsAttributesForResource,
                };
                const result = await createResource(currentFiltersState.parentIdEq ? {
                    ...resourcePayload,
                    parentId: currentFiltersState.parentIdEq.toString()
                } : resourcePayload).unwrap()
                setCreatingResource(false)
                resetAddResourceState();
            } catch (err: any) {
                resetAddResourceState();
                setCreatingResource(false)
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        } else {
            if (mediabankFiles.length === 0) {
                return
            }

            const authDataRefreshToken = JSON.parse(localStorage.getItem('user')  || "{}").refreshToken as string || {};

            const result = await refreshToken(authDataRefreshToken as string).unwrap()

            if (!result) {
                return;
            }

            setMediabankFilesToUploud && setMediabankFilesToUploud(mediabankFiles);
            dispatch(setVisibleUploudBox(true))
            resetAddResourceState();
            dispatch(baseApi.util.invalidateTags([ApiTags.MediabankResourcesList]));
            setCreatingResource(false)
        }
    }


    let params = [];
    let filterItemChips: FilterItemChip[] = []
    for (const queryKey in currentFiltersState) {
        const value = currentFiltersState[queryKey as keyof typeof currentFiltersState]
        if (Array.isArray(value)) {
            value.forEach(item => {
                params.push(`q[${queryKey}][]=${item.toString()}`)
                filterItemChips.push({name: queryKey, value: item, isArray: true})
            })
        } else if (value) {
            params.push(`q[${queryKey}]=${value.toString()}`)
            filterItemChips.push({name: queryKey, value: value.toString(), isArray: false})
        }
    }


    const {isLoading, isError, isSuccess, data} =
        useGetMediabankResourcesQuery(params.join('&').toString());

    const {isLoading: loadingResourcesForMovingFiles, data: resourcesDataForMovingFiles} =
        useGetMediabankResourcesForSelectQuery(parentIdForMoveResources, {skip: breadcrumbsForMoveResources.length === 0});

    const {isLoading: loadingTags, isError: tagsReqError, isSuccess: fetchTagsSucccess, data: tags} =
        useGetCmTagsQuery()

    const [getFile, {data: FileData}] = useLazyGetMediabankResourceQuery()

    const mediabankResources = data?.data;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const visibleRows = React.useMemo(
        () =>
            mediabankResources ? [...mediabankResources]
                .sort(getComparator(order, orderBy)) : [],
        [order, orderBy, mediabankResources]
    );


    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator<Key extends keyof any>(order: Order, orderBy: Key,): (a: any, b: any) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }


    useDebounce(() => {
            dispatch(setMediabankFilters({
                ...currentFiltersState,
                nameCont: searchByName
            }))
        }, [searchByName], 800
    );

    function removeFilter(filterElement: FilterItemChip) {
        if (filterElement.isArray) {
            dispatch(setMediabankFilters({
                ...currentFiltersState,
                [filterElement.name]: (currentFiltersState[filterElement.name as keyof MediabankResourcesFilters] as string[]).filter(el => el !== filterElement.value)
            }))
        } else {
            if (filterElement.name === 'nameCont') {
                setSearchByName('');
            }
            dispatch(setMediabankFilters({
                ...currentFiltersState,
                [filterElement.name]: ''
            }))
        }
    }

    const handleCheck = (event: React.MouseEvent<unknown>, id: any, selectingFilesForAssigningAttr = false) => {

        const selectedFiles: any = selectingFilesForAssigningAttr ? selectedFilesForAssigningAttributes : selected;
        const selectedIndex = selectedFiles.indexOf(id);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedFiles, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedFiles.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selectedFiles.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedFiles.slice(0, selectedIndex),
                selectedFiles.slice(selectedIndex + 1),
            );
        }
        selectingFilesForAssigningAttr ? setSelectedFilesForAssigningAttributes(newSelected) : setSelected(newSelected);
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const uploadedFiles = [...Array.from(e.target.files).map((el, index) => {
                return {
                    uuid: getUuid(index),
                    file: el,
                    tagList: [],
                    categoryId: null,
                    ownershipsAttributes: []
                }
            })]
            setOpenAssignAttributesToFiles(uploadedFiles)
            setMediabankFiles([...mediabankFiles, ...uploadedFiles]);
            setSelectedFilesForAssigningAttributes(uploadedFiles.map(el => el.uuid));
        }
    };

    async function removeResources(selectedResource: boolean | number) {
        try {
            const changeResourceLocationPayload: ChangeResourceLocationPayload[] = typeof selectedResource == 'boolean' ? selected.map((id) => {
                return {
                    id,
                    parentId: -1
                }
            }) : [{
                id: selectedResource as number,
                parentId: -1
            }]
            const result = await changeResourceLocation(changeResourceLocationPayload).unwrap()
            setOpenRemoveFileConfirmationModal(false)
            setSelected([])
        } catch (err: any) {
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            setOpenRemoveFileConfirmationModal(false)
        }
    }

    async function changeResourceData() {
        if (editedResource) {
            try {
                const {ownerships, ...rest} = editedResource
                const result = await changeResourceDataMutation(rest).unwrap()
                setEditedResource(null)
                setActiveRow(null)
            } catch (err: any) {
                enqueueSnackbar(`Wystąpił błąd`, {variant: 'error'});
                setEditedResource(null)
                setActiveRow(null)
            }
        }

    }

    async function moveResources(parentId: number | null) {
        try {
            const changeResourceLocationPayload: ChangeResourceLocationPayload[] = selected.map((id) => {
                return {
                    id,
                    parentId
                }
            })
            const result = await changeResourceLocation(changeResourceLocationPayload).unwrap()
            setSelected([])
            setOpenMoveResourcesDialog(false)
            setBreadcrumbsForMoveResources([])
        } catch (err: any) {
            enqueueSnackbar(`Wystąpił błąd ${err.status === 422 ? '- Operacja niedozwolona' : ''}`, {variant: 'error'});
            setOpenMoveResourcesDialog(false)
            setBreadcrumbsForMoveResources([])
        }
    }


    function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
        const {numSelected} = props;
        return (
            <Toolbar
                sx={[
                    {
                        pl: {sm: 2},
                        pr: {xs: 1, sm: 1},
                    },
                    numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    },
                ]}
            >
                {numSelected > 0 && (
                    <Typography
                        sx={{flex: '1 1 100%'}}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} zaznaczono
                    </Typography>
                )}
                {numSelected > 0 && (
                    <>
                        <Button sx={{width: '220px'}} variant={'text'}
                                onClick={() => {
                                    handleOpenMoveResource()
                                }}>
                            Przenieś pliki ({selected.length})</Button>
                        <Button sx={{width: '100px'}} variant={'text'} color="error" onClick={() => {
                            setOpenRemoveFileConfirmationModal(true)
                        }}>Usuń ({selected.length})</Button>
                    </>
                )}
            </Toolbar>
        );
    }

    function EnhancedTableHead(props: EnhancedTableProps) {
        const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
            props;
        const createSortHandler =
            (property: keyof MediabankResourceTableRow) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sort ? (<TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>) : headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const removeFile = (file: MediabankFile) => {
        setMediabankFiles(mediabankFiles.filter(el => el !== file))
    };

    const assignTagsToFiles = () => {
        setMediabankFiles(mediabankFiles.map((file) => {
            if (selectedFilesForAssigningAttributes.some(selectedFile => selectedFile === file.uuid)) {
                return {...file, tagList: tagsForResource}
            }
            return file;
        }))
        setTagsForResource([])
    }

    const assignOwnershipsToFiles = () => {
        setMediabankFiles(mediabankFiles.map((file) => {
            if (selectedFilesForAssigningAttributes.some(selectedFile => selectedFile === file.uuid)) {
                return {...file, ownershipsAttributes: ownershipsAttributesForResource}
            }
            return file;
        }))
        setOwnershipsAttributesForResource([])
    }

    const assignCategoryToFiles = () => {

        setMediabankFiles(mediabankFiles.map((file) => {
            if (selectedFilesForAssigningAttributes.some(selectedFile => selectedFile === file.uuid)) {
                return {...file, categoryId: parseInt(categoryForResource || '')}
            }
            return file;
        }))

    }

    const openEditResource = (event: any, id: number) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorElForActionMenu(null);
        setOpenEditResourceMenu(null)
        getFile(id).unwrap().then(async (res) => {
            setEditedResource({...res.data, ownershipsAttributes: res.data.ownerships, ownerships: res.data.ownerships})
        })

    }

    async function toDataURL(url: string) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }

    async function openImageFile(id: number) {
        getFile(id).unwrap().then(async (res) => {
            if (res.data.resourceFileUrl) {
                if (res.data.metadata?.video) {
                    const parser = new DOMParser();
                    const htmlText = `<video id="video-block" src=` + res.data.resourceFileUrl + ` controls autoplay autoplay></video>`;

                    let content = parser.parseFromString(htmlText, "text/html");

                    let w = window.open("", '_blank');
                    w!.document.write(content.body.innerHTML);
                    w!.document.close();
                } else {
                    window.open(res.data.resourceFileUrl);
                    let image = new Image();
                    image.src = res.data.resourceFileUrl;

                    let w = window.open("", '_blank');
                }


                // const link = document.createElement("a");
                // link.href = res.data.resourceFileUrl
                // link.target = '_blank'
                // link.download = res.data.name;
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link)
            }
        })
    };

    async function downloadFile(id: number) {
        getFile(id).unwrap().then(async (res) => {
            if (res.data.resourceFileUrl) {
                if (res.data.resourceFileUrl.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                    const image = await fetch(res.data.resourceFileUrl)
                    const imageBlog = await image.blob()
                    const imageURL = URL.createObjectURL(imageBlog)

                    const link = document.createElement('a')
                    link.href = imageURL
                    link.download = res.data.name
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                } else {
                    const link = document.createElement("a");
                    link.href = res.data.resourceFileUrl
                    link.target = '_blank'
                    link.download = res.data.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link)
                }
            }
        })
    };

    const getResourceImage = (row: MediabankResource) => {
        if (row.resourceType === ResourceType.Dir) {
            return <FolderOutlinedIcon fontSize="large"/>
        }

        if (row.resourceType === ResourceType.File && !row.thumbnailUrl) {
            return <DescriptionOutlinedIcon fontSize="large"/>
        }

        return <Box sx={{
            height: Thumbnail.width,
            width: Thumbnail.width,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: Thumbnail.background
        }}><img
            width={Thumbnail.width}
            src={`${row.thumbnailUrl}`}
            alt={row.name}
            loading="lazy"/>
        </Box>
    }

    return (

        <Box className="MediaList">
            <Box className={'page-header'}
                 sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                <h1>Mediabank</h1>
            </Box>

            {(isLoading) && (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                    <CircularProgress size={100}/>
                </Box>)}

            {(mediabankResources) && (<>
                <Grid container spacing={2} alignItems="center" paddingY={'20px'}>
                    <Grid item xs={4} alignItems="baseline">
                        <TextField
                            size="small"
                            value={searchByName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchByName(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setSearchByName('')
                                        dispatch(setMediabankFilters({
                                            ...currentFiltersState,
                                            nameCont: searchByName
                                        }))
                                    }
                                    }
                                    edge="end"
                                >
                                    {<Clear/>}
                                </IconButton>,
                            }}
                            variant={"outlined"}
                            fullWidth
                            id="mediabankResourceName"
                            label="Nazwa pliku lub folderu"
                            name="mediabankResourceName"
                            autoFocus></TextField>

                    </Grid>
                    {tags && (<Grid item xs={6} alignItems="baseline">

                        <FormControl fullWidth>
                            {<Autocomplete
                                multiple
                                size={'small'}
                                id="tags-filled"
                                options={tags.map((option) => option.name)}
                                value={currentFiltersState.tagsNameIn}
                                onChange={(_, value) => {
                                    dispatch(setMediabankFilters({
                                        ...currentFiltersState,
                                        tagsNameIn: value
                                    }))
                                }}
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: string, index: number) => {
                                        const {key, ...tagProps} = getTagProps({index});
                                        if (index === 0) {
                                            return (
                                                <Typography sx={{marginLeft: '10px'}}>
                                                    {option}
                                                </Typography>
                                            )
                                        }

                                        return (
                                            <Typography>
                                                {`, ${option}`}
                                            </Typography>
                                        );
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Tagi"
                                    />
                                )}
                            />}
                        </FormControl>
                    </Grid>)}
                </Grid>
                <Grid container spacing={2} pb={2}>
                    {filterItemChips.filter(el => el.name !== 'parentIdEq').map((filter) => {
                        return (
                            <Grid item key={filter.value}>
                                <Chip
                                    color="info"
                                    label={(<React.Fragment>{t(`filters.keys.${filter.name}`)  + ': '}<strong>{filter.value}</strong></React.Fragment>)}
                                    onClick={() => {
                                    }}
                                    onDelete={() => removeFilter(filter)}
                                />
                            </Grid>)
                    })}
                </Grid>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px'}}>
                    <Button onClick={() => {
                        handleOpenAddResource(ResourceType.Dir)
                    }} variant={'contained'}>Utwórz folder</Button>
                    {breadCrumbs.length > 1 && (<Button sx={{marginLeft: 3}} onClick={() => {
                        handleOpenAddResource(ResourceType.File)
                    }} variant={'contained'}>Dodaj plik</Button>)}
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between', paddingY: '15px'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        {breadCrumbs.map((breadcrumb, index) => {
                            return index !== breadCrumbs.length - 1 ? (
                                <Link key={breadcrumb.dirName} underline="hover" color="inherit" onClick={() => {
                                    dispatch(setMediabankFilters({
                                        ...currentFiltersState,
                                        parentIdEq: breadcrumb.resourceId
                                    }))
                                    dispatch(setBreadCrumbs(breadCrumbs.slice(0, index + 1)))
                                    setSelected([])
                                }} component="button">
                                    {breadcrumb.dirName}
                                </Link>
                            ) : (<Typography key={breadcrumb.dirName}
                                             sx={{color: 'text.primary'}}>{breadcrumb.dirName}</Typography>)
                        })}
                    </Breadcrumbs>
                    <Typography
                        sx={{color: 'text.primary'}}>liczba plików i folderów: <b>{visibleRows.length}</b></Typography>
                </Box>
                {(selected.length > 0 && <EnhancedTableToolbar numSelected={selected.length}/>)}
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllFilesForChangingDirectoryClick}
                            onRequestSort={handleRequestSort}
                            rowCount={mediabankResources.length}
                        />
                        <TableBody>
                            {visibleRows
                                .map((row, index) => {
                                    const isItemSelected = selected.includes(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (<TableRow
                                        hover
                                        onClick={(event: React.ChangeEvent<any>) => {
                                            if (event.target.tagName === 'INPUT' ||
                                                event.target.classList.contains('action-menu') ||
                                                event.target.classList.contains('MuiBackdrop-root') ||
                                                event.target.classList.contains('MuiIconButton-root')) {
                                                return
                                            }
                                            if (row.resourceType === ResourceType.File) {
                                                openImageFile(row.id)
                                                return
                                            }
                                            dispatch(setMediabankFilters({
                                                ...currentFiltersState,
                                                parentIdEq: row.id.toString()
                                            }))
                                            dispatch(setBreadCrumbs([...breadCrumbs, {
                                                dirName: row.name,
                                                resourceId: row.id.toString()
                                            }]))
                                            setSelected([])
                                        }}
                                        role="row"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected || row.id === activeRow}
                                        sx={{
                                            "&.MuiTableRow-hover:hover": {
                                                backgroundColor: voohCategories?.filter(el => el.id === row.categoryId)[0]?.color || '#f3f4f7',
                                                opacity: '0.8'
                                            },
                                            cursor: 'pointer',
                                            backgroundColor: voohCategories?.filter(el => el.id === row.categoryId)[0]?.color || 'inherit'
                                        }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                onClick={(event) => handleCheck(event, row.id)}
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" id={labelId}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px'
                                            }}>{getResourceImage(row)}{row.name}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left">{row.byteSizeHumanized}</TableCell>
                                        <TableCell
                                            align="left">{row.metadata?.height && (`${row.metadata?.height} x ${row.metadata?.width} px`)}</TableCell>
                                        <TableCell align="right">{row.tagList.join(', ')}</TableCell>
                                        <TableCell
                                            align="right">{voohCategories?.filter(el => el.id === row.categoryId)[0]?.name}</TableCell>
                                        <TableCell align="right">{row.updatedAt}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="fingerprint" color="success"
                                                        id="long-button"
                                                        onClick={(actionEvent: React.MouseEvent<HTMLElement>) => {
                                                            actionEvent.preventDefault()
                                                            actionEvent.stopPropagation()
                                                            setActiveRow(row.id)
                                                            setOpenEditResourceMenu(row.id)
                                                            setAnchorElForActionMenu(actionEvent.currentTarget);
                                                        }}
                                                        aria-controls={openActionMenu ? 'long-menu' : undefined}
                                                        aria-expanded={openActionMenu ? 'true' : undefined}
                                                        aria-haspopup="true">
                                                <MoreVertIcon className="action-menu"/>
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorElForActionMenu}
                                                open={openEditResourceMenu === row.id}
                                                onClose={() => {
                                                    setActiveRow(null)
                                                    setAnchorElForActionMenu(null);
                                                    setOpenEditResourceMenu(null)
                                                }}
                                                slotProps={{
                                                    paper: {
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: '20ch',
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem onClick={(event) => {
                                                    event.preventDefault()
                                                    event.stopPropagation()
                                                    setAnchorElForActionMenu(null);
                                                    setOpenEditResourceMenu(null)
                                                    openEditResource(event, row.id)
                                                }}>
                                                    Edycja
                                                </MenuItem>
                                                <MenuItem onClick={(event) => {
                                                    event.preventDefault()
                                                    event.stopPropagation()
                                                    setAnchorElForActionMenu(null);
                                                    setOpenEditResourceMenu(null)
                                                    setOpenRemoveFileConfirmationModal(row.id)
                                                }}>
                                                    <Typography
                                                        sx={{color: 'text.danger'}}>Usuń</Typography>
                                                </MenuItem>

                                                <MenuItem onClick={(event) => {
                                                    event.preventDefault()
                                                    event.stopPropagation()
                                                    setAnchorElForActionMenu(null);
                                                    setOpenEditResourceMenu(null)
                                                    downloadFile(row.id)
                                                }}>
                                                    <Typography>Pobierz</Typography>
                                                </MenuItem>

                                            </Menu>
                                        </TableCell>
                                    </TableRow>)
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {mediabankResources.find(resource => resource.id === editedResource?.id) && (<Dialog
                    open={!!mediabankResources.find(resource => resource.id === editedResource?.id)}
                    onClose={() => {
                        setActiveRow(null)
                        setEditedResource(null)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Edycja zasobu {editedResource?.name}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            size={'small'}
                            value={editedResource?.name}
                            label="Nazwa zasobu"
                            onChange={(event) => {
                                setEditedResource({...editedResource as MediabankResource, name: event.target.value})
                            }}
                            required
                            variant="outlined"
                            sx={{my: 3}}
                            fullWidth
                        />
                        {tags && (<Autocomplete
                            multiple
                            id="tags-filled"
                            size={'small'}
                            options={tags.map((option) => option.name)}
                            defaultValue={editedResource?.tagList}
                            onChange={(_, value) => {
                                setEditedResource({...editedResource as MediabankResource, tagList: value})
                            }}
                            freeSolo
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => {
                                    const {key, ...tagProps} = getTagProps({index});
                                    return (
                                        <Chip sx={{marginY: '10px'}} color={"info"} variant="filled"
                                              label={option}
                                              key={key} {...tagProps} />
                                    );
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Tagi"
                                    placeholder={tags.length === 0 ? 'Brak utworzonych tagów... wpisz nowy i zatwierdź enterem' : 'Wybierz z listy lub wpisz nowy i zatwierdź enterem'}
                                />
                            )}
                        />)}
                        {editedResource?.resourceType === ResourceType.File && (
                            <FormControl fullWidth sx={{marginTop: '25px', width: '100%'}}>
                                <InputLabel shrink
                                            id="demo-simple-select-label">{t('vooh.form.category')}</InputLabel>
                                <Select
                                    variant={'outlined'}
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={editedResource?.categoryId ? editedResource?.categoryId.toString() : ''}
                                    label={t('vooh.form.category')}
                                    onChange={(event: SelectChangeEvent) => {
                                        setEditedResource({
                                            ...editedResource as MediabankResource,
                                            categoryId: parseInt(event.target.value)
                                        });
                                    }}
                                >
                                    <MenuItem
                                        value={''}><em>{t('common.noValue')}</em></MenuItem>
                                    {
                                        voohCategories?.map((category) => {
                                            return (<MenuItem
                                                value={category.id}>{category.name}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>)}
                        <Box sx={{marginTop: '25px'}}><OwnershipSelect
                            ownershipsAttributes={editedResource?.ownershipsAttributes || editedResource?.ownerships || []}
                            small={true}
                            disabled={false}
                            onDelete={(val) => {
                                setEditedResource({
                                    ...editedResource as MediabankResource,
                                    ownershipsAttributes: (editedResource?.ownershipsAttributes || []).filter(el => el.userId !== parseInt(val)) || []
                                })
                            }}

                            onAdd={(id, authorizedPersonRights) => {
                                setEditedResource({
                                    ...editedResource as MediabankResource,
                                    ownershipsAttributes: [...editedResource?.ownershipsAttributes || [], {
                                        userId: id as number,
                                        accessLevel: authorizedPersonRights
                                    }]
                                })
                            }}
                            onDeleteAll={() => {
                                setEditedResource({...editedResource as MediabankResource, ownershipsAttributes: []})
                            }}
                        ></OwnershipSelect></Box>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!editedResource?.name} variant={'contained'} onClick={() => {
                            changeResourceData()
                        }}>Zaktualizuj</Button>
                        <Button onClick={() => {
                            setActiveRow(null)
                            setEditedResource(null)
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>)}


                {openAddResource && (<Dialog
                    open={!!openAddResource}
                    onClose={handleCloseAddResource}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: openAddResource === ResourceType.Dir ? "30vw" : "60vw",  // Set your width here
                            },
                        },
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {openAddResource === ResourceType.Dir ? 'Utwórz folder' : 'Dodaj plik'}
                    </DialogTitle>
                    <DialogContent>
                        {
                            openAddResource === ResourceType.Dir ?
                                (<><TextField
                                    value={newDirectoryName}
                                    label="Nazwa folderu"
                                    onChange={(event) => {
                                        setNewDirectoryName(event.target.value)
                                    }}
                                    required
                                    variant="outlined"
                                    sx={{my: 3}}
                                    fullWidth
                                />
                                    {tags && (<Autocomplete
                                        multiple
                                        id="tags-filled"
                                        options={tags.map((option) => option.name)}
                                        defaultValue={[]}
                                        onChange={(_, value) => {
                                            setTagsForResource(value)
                                        }}
                                        freeSolo
                                        renderTags={(value: readonly string[], getTagProps) =>
                                            value.map((option: string, index: number) => {
                                                const {key, ...tagProps} = getTagProps({index});
                                                return (
                                                    <Chip sx={{marginY: '10px'}} color={"info"} variant="filled"
                                                          label={option}
                                                          key={key} {...tagProps} />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Tagi"
                                                placeholder={tags.length === 0 ? 'Brak utworzonych tagów... wpisz nowy i zatwierdź enterem' : 'Wybierz z listy lub wpisz nowy i zatwierdź enterem'}
                                            />
                                        )}
                                    />)}

                                    <Box sx={{marginTop: '20px'}}><OwnershipSelect
                                        ownershipsAttributes={ownershipsAttributesForResource}
                                        disabled={false}
                                        onDelete={(val) => {
                                            setOwnershipsAttributesForResource(ownershipsAttributesForResource.filter(el => el.userId !== parseInt(val)))
                                        }}

                                        onAdd={(id, authorizedPersonRights) => {
                                            setOwnershipsAttributesForResource([...ownershipsAttributesForResource, {
                                                userId: id as number,
                                                accessLevel: authorizedPersonRights
                                            }])
                                        }}
                                        onDeleteAll={() => {
                                            setOwnershipsAttributesForResource([])
                                        }}
                                    ></OwnershipSelect></Box>

                                </>) :
                                <>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        gap: '10px',
                                        marginTop: '10px'
                                    }}>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="outlined"
                                            disabled={creatingResource}
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon/>}
                                        >
                                            Wybierz pliki
                                            <VisuallyHiddenInput type="file"
                                                                 multiple={true}
                                                                 onChange={handleFileChange}/>
                                        </Button>
                                        <Badge badgeContent={selectedFilesForAssigningAttributes.length}
                                               color={"primary"}>
                                            <Button component="label"
                                                    disabled={selectedFilesForAssigningAttributes.length === 0}
                                                    role={undefined}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setOpenAssignAttributesToFiles(mediabankFiles)
                                                    }}
                                                    tabIndex={-1}>
                                                Przypisz atrybuty do plików
                                            </Button>
                                        </Badge>
                                    </Box>
                                    {/*<FilePond name={'customprop'} onaddfile={(error, file) => {*/}
                                    {/*    let mediabankFile = mediabankFiles.find(el => el.file === file.file)*/}
                                    {/*    if (mediabankFile) {*/}
                                    {/*        file.setMetadata('category_id', mediabankFile.categoryId)*/}
                                    {/*        file.setMetadata('parent_id', currentFiltersState.parentIdEq!.toString())*/}
                                    {/*        file.setMetadata('tag_list', mediabankFile.tagList)*/}
                                    {/*    }*/}
                                    {/*}} files={blobFiles} allowMultiple={true} maxFiles={16}*/}
                                    {/*          server={{*/}
                                    {/*              url: `${process.env.REACT_APP_DSP_BACKEND_API + UseApiV1('mediabank_resources_bulk')}`,*/}
                                    {/*              headers: {*/}
                                    {/*                  'authorization': `Bearer ${JSON.parse(localStorage.getItem("user") || '').accessToken}`*/}
                                    {/*              }*/}
                                    {/*          }}*/}

                                    {/*/>*/}

                                    {/*<Box sx={{ width: '100%', paddingY: '30px'}}>*/}
                                    {/*    <LinearProgress />*/}
                                    {/*</Box>*/}
                                    {mediabankFiles && (
                                        <TableContainer>
                                            <Table sx={{width: '100%'}} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                indeterminate={selectedFilesForAssigningAttributes.length > 0 && selectedFilesForAssigningAttributes.length < Array.from(mediabankFiles).length}
                                                                checked={Array.from(mediabankFiles).length > 0 && selectedFilesForAssigningAttributes.length === Array.from(mediabankFiles).length}
                                                                onChange={(event) => {
                                                                    handleSelectAllFilesForAssigningAttributes(event)
                                                                }}
                                                                inputProps={{
                                                                    'aria-label': 'select all desserts',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>Nazwa pliku</TableCell>
                                                        <TableCell align="left">Tagi</TableCell>
                                                        <TableCell align="left">Kategoria</TableCell>
                                                        <TableCell align="left">Uprawnione osoby</TableCell>
                                                        <TableCell align="right"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Array.from(mediabankFiles).map((file, index) => {
                                                        const isItemSelected = selectedFilesForAssigningAttributes.includes(file.uuid);
                                                        const labelId = `enhanced-files-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                key={file.uuid}
                                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                            >
                                                                <TableCell padding="checkbox" style={{width: '5%'}}>
                                                                    <Checkbox
                                                                        color="primary"
                                                                        onClick={(event) => handleCheck(event, file.uuid, true)}
                                                                        checked={isItemSelected}
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row"
                                                                           style={{width: '20%'}}>
                                                                    {file.file.name}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row"
                                                                           style={{width: '20%'}}>
                                                                    {tags && (<Autocomplete
                                                                        size={"small"}
                                                                        disabled={creatingResource}
                                                                        multiple
                                                                        id="tags-filled"
                                                                        options={tags.map((option) => option.name)}
                                                                        defaultValue={[]}
                                                                        value={file.tagList}
                                                                        onChange={(_, value) => {
                                                                            // setTagsForDirectory(value)
                                                                            setMediabankFiles([...mediabankFiles.map((mediabankFile) => {
                                                                                if (file.file === mediabankFile.file) {
                                                                                    return {
                                                                                        ...mediabankFile,
                                                                                        tagList: value
                                                                                    }
                                                                                }

                                                                                return mediabankFile
                                                                            })])
                                                                        }}
                                                                        freeSolo
                                                                        renderTags={(value: readonly string[], getTagProps) =>
                                                                            value.map((option: string, index: number) => {
                                                                                const {
                                                                                    key,
                                                                                    ...tagProps
                                                                                } = getTagProps({index});
                                                                                return (
                                                                                    <Chip sx={{marginY: '10px'}}
                                                                                          color={"info"}
                                                                                          variant="filled"
                                                                                          label={option}
                                                                                          key={key} {...tagProps} />
                                                                                );
                                                                            })
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                label="Tagi"
                                                                                placeholder={tags.length === 0 ? 'Brak utworzonych tagów... wpisz nowy i zatwierdź enterem' : 'Wybierz z listy lub wpisz nowy i zatwierdź enterem'}
                                                                            />
                                                                        )}
                                                                    />)}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row"
                                                                           style={{width: '20%'}}>

                                                                    <FormControl fullWidth>
                                                                        <InputLabel shrink
                                                                                    id="demo-simple-select-label">{t('vooh.form.category')}</InputLabel>
                                                                        <Select
                                                                            variant={'outlined'}
                                                                            size="small"
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            value={(file.categoryId || '').toString()}
                                                                            label={t('vooh.form.category')}
                                                                            onChange={(event: SelectChangeEvent) => {
                                                                                const newFiles = mediabankFiles.map((el) => {
                                                                                    if (el.uuid === file.uuid) {
                                                                                        return {
                                                                                            ...el,
                                                                                            categoryId: parseInt(event.target.value)
                                                                                        }
                                                                                    }
                                                                                    return el
                                                                                })
                                                                                setMediabankFiles(newFiles)
                                                                            }}
                                                                        >
                                                                            <MenuItem
                                                                                value={''}><em>{t('common.noValue')}</em></MenuItem>
                                                                            {
                                                                                voohCategories?.map((category) => {
                                                                                    return (<MenuItem
                                                                                        value={category.id}>{category.name}</MenuItem>)
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell align="right" style={{width: '30%'}}>
                                                                    <OwnershipSelect
                                                                        ownershipsAttributes={file.ownershipsAttributes as OwnershipAttribute[]}
                                                                        disabled={false}
                                                                        small={true}
                                                                        onDelete={(val) => {
                                                                            const newFiles = mediabankFiles.map((el) => {
                                                                                if (el.uuid === file.uuid) {
                                                                                    return {
                                                                                        ...el,
                                                                                        ownershipsAttributes: ownershipsAttributesForResource.filter(el => el.userId !== parseInt(val))
                                                                                    }
                                                                                }
                                                                                return el
                                                                            })
                                                                            setMediabankFiles(newFiles)
                                                                        }}

                                                                        onAdd={(id, authorizedPersonRights) => {
                                                                            const newFiles = mediabankFiles.map((el) => {
                                                                                if (el.uuid === file.uuid) {
                                                                                    return {
                                                                                        ...el,
                                                                                        ownershipsAttributes: [...ownershipsAttributesForResource, {
                                                                                            userId: id as number,
                                                                                            accessLevel: authorizedPersonRights
                                                                                        }]
                                                                                    }
                                                                                }
                                                                                return el
                                                                            })
                                                                            setMediabankFiles(newFiles)
                                                                        }}
                                                                        onDeleteAll={() => {
                                                                            const newFiles = mediabankFiles.map((el) => {
                                                                                if (el.uuid === file.uuid) {
                                                                                    return {
                                                                                        ...el,
                                                                                        ownershipsAttributes: []
                                                                                    }
                                                                                }
                                                                                return el
                                                                            })
                                                                            setMediabankFiles(newFiles)
                                                                        }}
                                                                    ></OwnershipSelect>
                                                                </TableCell>
                                                                <TableCell align="right" style={{width: '5%'}}>
                                                                    <ButtonGroup variant="text"
                                                                                 aria-label="Basic button group">
                                                                        <Button disabled={creatingResource}
                                                                                color="error"
                                                                                onClick={() => {
                                                                                    removeFile(file)
                                                                                }}>Usuń</Button>
                                                                    </ButtonGroup>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                    }
                                </>
                        }</DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseAddResource}>{t('common.closeWindow')}</Button>
                        <Button
                            disabled={openAddResource === ResourceType.Dir && !newDirectoryName || creatingResource}
                            variant={'contained'} onClick={() => {
                            addResource(openAddResource)
                        }}> {creatingResource ? (<><CircularProgress sx={{marginRight: '5px'}}
                                                                     size={20}/>
                            <span>Dodaj</span></>) : ('Dodaj')}</Button>
                    </DialogActions>
                </Dialog>)}


                {openAssignAttributesToFiles && (<Dialog
                    open={openAssignAttributesToFiles.length > 0}
                    onClose={() => {
                        setOpenAssignAttributesToFiles([])
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Dodaj atrybuty do plików
                    </DialogTitle>
                    <DialogContent>

                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center'}}>
                                {tags && (<><Autocomplete
                                    style={{width: '500px', marginTop: '10px'}}
                                    size={'small'}
                                    multiple
                                    id="tags-filled"
                                    options={tags.map((option) => option.name)}
                                    defaultValue={[]}
                                    onChange={(_, value) => {
                                        setTagsForResource(value)
                                    }}
                                    freeSolo
                                    renderTags={(value: readonly string[], getTagProps) =>
                                        value.map((option: string, index: number) => {
                                            const {key, ...tagProps} = getTagProps({index});
                                            return (
                                                <Chip sx={{marginY: '10px'}} color={"info"} variant="filled"
                                                      label={option}
                                                      key={key} {...tagProps} />
                                            );
                                        })
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Tagi"
                                            placeholder={tags.length === 0 ? 'Brak utworzonych tagów... wpisz nowy i zatwierdź enterem' : 'Wybierz z listy lub wpisz nowy i zatwierdź enterem'}
                                        />
                                    )}
                                />
                                    <Button
                                        sx={{marginTop: '5px'}}
                                        variant={'contained'} onClick={() => {
                                        assignTagsToFiles();
                                    }}>Przypisz</Button>
                                </>)}


                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '20px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '20px'
                            }}>
                                {
                                    voohCategories && (
                                        <>
                                            <FormControl fullWidth sx={{marginTop: '25px', width: '500px'}}>
                                                <InputLabel shrink
                                                            id="demo-simple-select-label">{t('vooh.form.category')}</InputLabel>
                                                <Select
                                                    variant={'outlined'}
                                                    size="small"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={categoryForResource}
                                                    label={t('vooh.form.category')}
                                                    onChange={(event: SelectChangeEvent) => {
                                                        setCategoryForResource(event.target.value)
                                                    }}
                                                >
                                                    <MenuItem
                                                        value={''}><em>{t('common.noValue')}</em></MenuItem>
                                                    {
                                                        voohCategories?.map((category) => {
                                                            return (<MenuItem
                                                                value={category.id}>{category.name}</MenuItem>)
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <Button
                                                sx={{marginTop: '22px'}}
                                                variant={'contained'} onClick={() => {
                                                assignCategoryToFiles();
                                            }}>Przypisz</Button>
                                        </>
                                    )
                                }


                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: '25px', alignItems: 'center'}}>
                                <Box sx={{width: '500px'}}><OwnershipSelect
                                    ownershipsAttributes={ownershipsAttributesForResource}
                                    small={true}
                                    disabled={false}
                                    onDelete={(val) => {
                                        setOwnershipsAttributesForResource(ownershipsAttributesForResource.filter(el => el.userId !== parseInt(val)))
                                    }}

                                    onAdd={(id, authorizedPersonRights) => {
                                        setOwnershipsAttributesForResource([...ownershipsAttributesForResource, {
                                            userId: id as number,
                                            accessLevel: authorizedPersonRights
                                        }])
                                    }}
                                    onDeleteAll={() => {
                                        setOwnershipsAttributesForResource([])
                                    }}
                                ></OwnershipSelect></Box>
                                <Button
                                    variant={'contained'} onClick={() => {
                                    assignOwnershipsToFiles();
                                }}>Przypisz</Button>


                            </Box>


                        </Box>


                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenAssignAttributesToFiles([])
                                setTagsForResource([])
                            }}>{t('common.closeWindow')}</Button>
                    </DialogActions>
                </Dialog>)}
                {openRemoveFileConfirmationModal && (<Dialog
                    open={!!openRemoveFileConfirmationModal}
                    onClose={() => {
                        setOpenRemoveFileConfirmationModal(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Usuwanie zasobu
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Usunąć zaznaczone pliki?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={() => {
                            removeResources(openRemoveFileConfirmationModal)
                        }}>{t('common.remove')}</Button>
                        <Button onClick={() => {
                            setOpenRemoveFileConfirmationModal(false)
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>)}

                {openMoveResourcesDialog && (<Dialog
                    open={openMoveResourcesDialog}
                    onClose={() => {
                        setOpenMoveResourcesDialog(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Przenieś pliki ({selected.length})
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{width: 500, bgcolor: 'background.paper'}}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link key={'root'} underline="hover" color="inherit"
                                      onClick={() => {
                                          setBreadcrumbsForMoveResources([])
                                      }} component="button">
                                    Root
                                </Link>
                                {breadcrumbsForMoveResources.length && breadcrumbsForMoveResources.map((breadcrumb, index) => {
                                    return index !== breadcrumbsForMoveResources.length - 1 ? (
                                        <Link key={breadcrumb.dirName} underline="hover" color="inherit"
                                              onClick={() => {
                                                  setParentIdForMoveResources(breadcrumb.resourceId)
                                                  setBreadcrumbsForMoveResources(breadcrumbsForMoveResources.slice(0, index + 1))
                                              }} component="button">
                                            {breadcrumb.dirName}
                                        </Link>
                                    ) : (<Typography key={breadcrumb.dirName}
                                                     sx={{color: 'text.primary'}}>{breadcrumb.dirName}</Typography>)
                                })}
                            </Breadcrumbs>
                            <List sx={{
                                '& .MuiListItemButton-root': {
                                    '.MuiButtonBase-root': {
                                        display: 'none',
                                    },
                                },
                                // hover states
                                '& .MuiListItemButton-root:hover': {
                                    '.MuiButtonBase-root': {
                                        display: 'block',
                                    },
                                },
                            }}>
                                {
                                    (resourcesDataForMovingFiles && breadcrumbsForMoveResources.length > 0) && (
                                        resourcesDataForMovingFiles.data
                                            .filter(el => el.resourceType === ResourceType.Dir && !selected.find(id => el.id === id))
                                            .map((resource) => {
                                                return (<ListItem disablePadding sx={{height: '50px'}}>
                                                    <ListItemButton onClick={() => {
                                                        setParentIdForMoveResources(resource.id.toString())
                                                        setBreadcrumbsForMoveResources([...breadcrumbsForMoveResources, {
                                                            resourceId: resource.id.toString(),
                                                            dirName: resource.name
                                                        }])
                                                    }}>
                                                        <ListItemIcon>
                                                            <FolderOutlinedIcon/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={resource.name}/>
                                                        <Button onClick={() => {
                                                            moveResources(resource.id)
                                                        }}>Przenieś</Button>
                                                    </ListItemButton>
                                                </ListItem>)
                                            })
                                    )
                                }

                                {
                                    (breadcrumbsForMoveResources.length === 0) &&
                                    (<ListItem disablePadding sx={{height: '50px'}}>
                                        <ListItemButton onClick={() => {
                                            setParentIdForMoveResources(null)
                                            setBreadcrumbsForMoveResources([{
                                                resourceId: null,
                                                dirName: 'Katalog główny'
                                            }])
                                        }}>
                                            <ListItemIcon>
                                                <FolderOutlinedIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={'Katalog glowny'}/>
                                            <Button onClick={() => {
                                                moveResources(null)
                                            }}>Przenieś</Button>
                                        </ListItemButton>
                                    </ListItem>)
                                }

                                {(resourcesDataForMovingFiles && resourcesDataForMovingFiles.data.filter(el => el.resourceType === ResourceType.Dir && !selected.find(id => el.id === id)).length === 0) && (
                                    <Typography
                                        sx={{color: 'text.primary', paddingY: '10px'}}>Brak folderów...</Typography>)
                                }
                            </List>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenMoveResourcesDialog(false)
                            setBreadcrumbsForMoveResources([])
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>)}

            </>)}
        </Box>
    )
}

export default MediaList;

