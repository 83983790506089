import React from "react";
import {Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";

const HtmlTooltip: React.FC<TooltipProps> = ({
                                                title,
                                                 className,
                                                 children,

                                             }) => {
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffffff',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: 'none',
            boxShadow: '0px 0px 41px -11px rgba(66, 68, 90, 1)',
        },
    }));

    return (
        <HtmlTooltip title={title}>{children}</HtmlTooltip>

    )
}


export default HtmlTooltip;