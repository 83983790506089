import {CmQueryResult} from "../../../app/types";
import UseApiV1 from "../../../hooks/useApiV1";
import {ApiTags, baseApi} from "../../../app/api/baseApi";
import {Draft, DraftDetails, DraftFilteringMeta, DraftForm, Tag} from "../../drafts/types";
import {
    GetResourcesMetaDataResponse,
    MediaTemplate,
    MediaTemplatePayload,
    MediaTemplateResponse,
    ResourceMetaData, VoohDetails, VoohForm, VoohListItem, VoohListsFilteringMeta
} from "../types";


export const voohApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getMediaTemplates: builder.query<MediaTemplate[], void>({
            query: () => {
                return {
                    reducerPath: 'templates',
                    url: `/api/v1/templates`,
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<MediaTemplateResponse[]>) => {
                return response.data.map((el)=> {
                    return {
                        ...el,
                        media: el.media.map((media: {id: number}) => media.id)
                    }
                })
            },
            providesTags: (_) => [ApiTags.MediaTemplates],
            keepUnusedDataFor: 5
        }),

        createMediaTemplate: builder.mutation<any, MediaTemplatePayload>({
            query: (mediaTemplatePayload: MediaTemplatePayload): any => {
                return {
                    url: '/api/v1/templates',
                    method: 'POST',
                    body: {...mediaTemplatePayload}
                }
            },
            invalidatesTags: (_) => [ApiTags.MediaTemplates],
        }),

        createVoohPlaylist: builder.mutation<any, VoohForm>({
            query: (voohForm: VoohForm): any => {
                return {
                    url: '/api/v1/vooh_playlists',
                    method: 'POST',
                    body: {...voohForm}
                }
            },
        }),

        deleteVooh: builder.mutation<any, any>({
            query: (voohId: string): any => {
                return {
                    url: `/api/v1/vooh_playlists/${voohId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (_) => [ApiTags.VoohList],
        }),

        updateVoohPlaylist: builder.mutation<any, VoohForm>({
            query: (voohForm: VoohForm): any => {
                return {
                    url: `/api/v1/vooh_playlists/${voohForm.id}`,
                    method: 'PUT',
                    body: {...voohForm}
                }
            },
        }),

        getVoohPlaylists: builder.query<CmQueryResult<VoohListItem[]>, string>({
            query: (query) => {
                return {
                    reducerPath: 'voohPlaylists',
                    url: `/api/v1/vooh_playlists?${query}`,
                    method: 'GET',
                }
            },
            providesTags: (_) => [ApiTags.VoohList],
            keepUnusedDataFor: 5
        }),

        getVoohPlaylist: builder.query<VoohDetails, string>({
            query: (id) => {
                return {
                    reducerPath: 'voohDetails',
                    url: `/api/v1/vooh_playlists/${id}`,
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<VoohDetails>) => {
                return response.data
            },
            providesTags: (_) => [ApiTags.VoohDetails],
            keepUnusedDataFor: 5
        }),


        getVoohListsFiltersMeta: builder.query<CmQueryResult<VoohListsFilteringMeta>, void>({
            query: () => {
                return {
                    reducerPath: 'voohPlaylistsFiltersMeta',
                    url: `/api/v1/vooh_playlists/filters_meta`,
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120
        }),

        deleteMediaTemplate: builder.mutation<any, any>({
            query: (mediaTemplateId: number): any => {
                return {
                    url: `/api/v1/templates/${mediaTemplateId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (_) => [ApiTags.MediaTemplates]
        }),

        getResourcesMetaData: builder.query<ResourceMetaData[], string>({
            query: (resourceIdsQuery: string) => {
                return {
                    reducerPath: 'creations',
                    url: UseApiV1(`vooh_playlists/build_vooh_items?${resourceIdsQuery}`),
                    method: 'GET',
                }
            },
            transformResponse: (response: CmQueryResult<GetResourcesMetaDataResponse>) => {
                return response.data.voohItems.map((el) => {
                    if (el.duration) {
                        return {
                            ...el,
                            editableDuration: false
                        }
                    }
                    return {
                        ...el,
                        editableDuration: true
                    }
                })
            },
            keepUnusedDataFor: 5
        }),
    })
})

export const {
    useGetMediaTemplatesQuery,
    useGetVoohPlaylistsQuery,
    useGetVoohListsFiltersMetaQuery,
    useCreateMediaTemplateMutation,
    useUpdateVoohPlaylistMutation,
    useDeleteMediaTemplateMutation,
    useLazyGetResourcesMetaDataQuery,
    useCreateVoohPlaylistMutation,
    useDeleteVoohMutation,
    useGetVoohPlaylistQuery
} = voohApiSlice