import {
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    TablePagination,
    TextField
} from "@mui/material";
import React, {useEffect} from "react";
import {setCreationsFilters} from "../../creations/store/creationsSlice";
import {Clear} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import {Link} from "react-router-dom";
import {TABLE_ROW_LENGTH_OPTIONS} from "../../../config/table";
import {useDispatch, useSelector} from "react-redux";
import {FilterItemChip} from "../../drafts/types";
import {
    useDeleteCreationMutation,
    useLazyGetCreationDetailsQuery,
    useManageStateMutation
} from "../../creations/api/creationsApiSlice";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import useDebounce from "../../../hooks/useDebounce";
import {CreationDetails} from "../../creations/types";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {mediaFormatDimensions} from "../../../config/mediaFormatDimensions";
import {useGetEmissionsFiltersMetaQuery, useGetEmissionsQuery, useStopEmissionMutation} from "../api/emissionsApiSlice";
import CmEmissionsStatus from "../../../components/CmEmissionsStatus/CmEmissionsStatusIconBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {initialEmissionsState, selectCurrentFiltersState, setFilters} from "../store/emissionsSlice";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {useSnackbar} from "notistack";
import {initialDraftFormState} from "../../drafts/store/draftsSlice";

const EmissionsList = () => {
    const [searchByName, setSearchByName] = React.useState<string>('');
    const currentFiltersState = useSelector(selectCurrentFiltersState);
    const [rowLength, setRowLength] = React.useState(TABLE_ROW_LENGTH_OPTIONS[0]);
    const [selectedCreationToRemove, setSelectedCreationToRemove] = React.useState<number | null>(null);
    const [skipFetchingCreations, setSkipFetchingCreations] = React.useState(false);
    const page = currentFiltersState.page;
    const [getCreationDetails, {data: creationDetailsData}] = useLazyGetCreationDetailsQuery();
    const [openActionMenu, setOpenActionMenu] = React.useState<number | null>(null);
    const [anchorElForActionMenu, setAnchorElForActionMenu] = React.useState<null | HTMLElement>(null);

    let params = [`items=${rowLength.toString()}`];
    let filterItemChips: FilterItemChip[] = []
    for (const queryKey in currentFiltersState) {
        const value = currentFiltersState[queryKey as keyof typeof currentFiltersState]
        if (Array.isArray(value)) {
            value.forEach(item => {
                params.push(`q[${queryKey}][]=${item.toString()}`)
                filterItemChips.push({name: queryKey, value: item, isArray: true})
            })
        } else if (queryKey === 'page') {
            params.push(`page=${value.toString()}`)
        } else if (value) {
            params.push(`q[${queryKey}]=${value.toString()}`)
            filterItemChips.push({name: queryKey, value: value.toString(), isArray: false})
        }
    }

    const [deleteCreation, {isLoading: deletingCreation}] = useDeleteCreationMutation()

    const [stopEmission, {isLoading: loadingStopEmission}] = useStopEmissionMutation()
    const {enqueueSnackbar} = useSnackbar();


    const {
        isLoading: loadingFiltersMeta,
        isError: isFiltersMetaError,
        isSuccess: isSuccessFiltersMeta,
        data: filtersMetaData
    } =
        useGetEmissionsFiltersMetaQuery();


    const {isLoading, isError, isSuccess, data} =
        useGetEmissionsQuery(params.join('&').toString(), {skip: skipFetchingCreations});

    // const emissionsQueryResult: CmQueryResult<CreationListItem[]> = {
    //     data: data!.data.filter(el => el.state === 'accepted'),
    //     meta: data!.meta,
    // }


    const dispatch = useDispatch();
    const {t, i18n} = useTranslation(); // not passing any namespace will use the defaultNS (by default set to 'translation')

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const emissions = data?.data
    console.log(emissions)

    const theme = useTheme();


    useEffect(() => {
        return () => {
            dispatch(setFilters({
                ...initialEmissionsState.filters
            }))
        }
    }, []);

    useDebounce(() => {
            dispatch(setFilters({
                ...currentFiltersState,
                nameCont: searchByName,
                page: 1
            }))
        }, [searchByName], 800
    );

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowLength(parseInt(event.target.value, 10))
    }

    function removeFilter(filterElement: FilterItemChip) {
        if (filterElement.name === 'nameCont') {
            setSearchByName('');
        }
        dispatch(setFilters({
            ...currentFiltersState,
            page: 1,
            [filterElement.name]: ''
        }))
    }

    function handleOpenCreationPreview(creationId: number | null) {
        if (!creationId) {
            return;
        }
        let creationDetailsData: CreationDetails;
        getCreationDetails(creationId.toString()).unwrap().then((creationDetails: CreationDetails) => {
            creationDetailsData = creationDetails;
        })
            .catch((error: any) => {
                if (error && error.data && error.data.status === 404) {
                    enqueueSnackbar('Wystąpił błąd, kreacja nie istnieje', {variant: 'error'});
                }
            })
            .finally(() => {
                if (!creationDetailsData) {
                    return
                }

                const fileUrl = encodeURIComponent(creationDetailsData.creationFileUrl);
                const playerPreviewPropsLink = encodeURIComponent(creationDetailsData.playerPreviewPropsLink)
                window.open(`/creations/${creationId}/preview?datajson=${playerPreviewPropsLink}&datapath=${process.env.REACT_APP_DSP_BACKEND_API}&fileUrl=${fileUrl}&mediaFormat=${creationDetailsData.mediaFormat}`, "_blank", `location=yes,height=${mediaFormatDimensions[creationDetailsData.mediaFormat as keyof typeof mediaFormatDimensions][1] + 300},width=${mediaFormatDimensions[creationDetailsData.mediaFormat as keyof typeof mediaFormatDimensions][0] + 300},scrollbars=yes,status=yes`);
            })
    }

    return (<Box className="CreationsList">
        <Box className={'page-header'}>
            <h1>Podgląd emisji</h1>
        </Box>

        {(isLoading || loadingFiltersMeta) && (
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                <CircularProgress size={100}/>
            </Box>)}

        {/*{isError && (*/}
        {/*    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>*/}
        {/*        <span>Error occurred...</span>*/}
        {/*    </Box>)}*/}

        {(emissions && filtersMetaData) && (<>
            <Grid container spacing={2}>
                <Grid item xs={4} alignItems="baseline">
                    <TextField
                        size="small"
                        value={searchByName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchByName(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setSearchByName('')
                                    dispatch(setCreationsFilters({
                                        ...currentFiltersState,
                                        tagsNameIn: [],
                                        draftMediaFormatIn: [],
                                        stateIn: [],
                                        draftIdEq: null,
                                        nameCont: searchByName
                                    }))
                                }
                                }
                                edge="end"
                            >
                                {<Clear/>}
                            </IconButton>,
                        }}
                        margin="normal"
                        variant={"outlined"}
                        fullWidth
                        id="creationsName"
                        label="Nazwa kreacji"
                        name="creationsName"
                        autoFocus></TextField>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{marginTop: '15px'}}>
                        <InputLabel shrink id="demo-simple-select-label">Status emisji</InputLabel>
                        <Select
                            size="small"
                            variant={"outlined"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Status emisji"
                            value={currentFiltersState.emissionStatusEq || ''}
                            notched
                            onChange={(event) => {
                                dispatch(setFilters({
                                    ...currentFiltersState,
                                    emissionStatusEq: event.target.value
                                }))
                            }}
                        >
                            {filtersMetaData.data.filtering.fields.emissionStatusEq.allowedValues.map((key) => (
                                <MenuItem
                                    key={key}
                                    value={key}>{t(`emissions.status.${key}`)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <DatePicker
                        maxDate={dayjs(new Date(currentFiltersState.endAtQteq))}
                        onChange={(event) => {
                            dispatch(setFilters({
                                ...currentFiltersState,
                                startAtLteq: dayjs(event).date() ? dayjs(event).format('YYYY-MM-DD') : ''
                            }))
                        }}
                        value={currentFiltersState.startAtLteq ? dayjs(currentFiltersState.startAtLteq) : null}
                        sx={{marginTop: '14px', width: '100%'}} slotProps={{textField: {size: 'small'}}} label="Od"/>
                </Grid>
                <Grid item xs={2}>
                    <DatePicker
                        minDate={currentFiltersState.startAtLteq ? dayjs(currentFiltersState.startAtLteq) : undefined}
                        onChange={(event) => {
                            dispatch(setFilters({
                                ...currentFiltersState,
                                endAtQteq: dayjs(event).date() ? dayjs(event).format('YYYY-MM-DD') : ''
                            }))
                        }}
                        sx={{marginTop: '14px', width: '100%'}}
                        value={currentFiltersState.endAtQteq ? dayjs(currentFiltersState.endAtQteq) : null}
                        slotProps={{textField: {size: 'small'}}} label="Do"/>
                </Grid>
            </Grid>

            <Grid container spacing={2} pb={2}>
                {filterItemChips.map((filter) => {
                    return (
                        <Grid item key={filter.value}>
                            <Chip
                                color="info"
                                label={(
                                    <React.Fragment>{t(`filters.keys.${filter.name}`) + ': '}<strong>{filter.value}</strong></React.Fragment>)}
                                onClick={() => {
                                }}
                                onDelete={() => removeFilter(filter)}
                            />
                        </Grid>)
                })}
            </Grid>
            <Backdrop
                sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
                open={deletingCreation || isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            {/*{data && (<CreationsTable creationsQueryResult={{*/}
            {/*    data: data!.data.filter(el => el.state === 'accepted'),*/}
            {/*    meta: {...data!.meta, count: data!.data.filter(el => el.state === 'accepted').length},*/}
            {/*}} buttonGroup={(rowId) => {*/}
            {/*    return <ButtonGroup variant="text" aria-label="Basic button group">*/}
            {/*        <Link to={`/creations/${rowId}`} className="link"><Button>Edycja*/}
            {/*            kreacji</Button></Link>*/}
            {/*        <Link to={`/creations/${rowId}`} className="link"><Button>Podgląd*/}
            {/*            kreacji</Button></Link>*/}
            {/*    </ButtonGroup>*/}
            {/*}}></CreationsTable>)}*/}

            {emissions && (<TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa kreacji</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Data emisji</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emissions.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                        <CmEmissionsStatus emissionStatus={row.emissionStatus}></CmEmissionsStatus>
                                    </Box>
                                </TableCell>
                                <TableCell align="right">
                                    <p>
                                        od {new Date(row.startAt).toISOString().split('T')[0]}
                                    </p>
                                    <p>
                                        do {new Date(row.endAt).toISOString().split('T')[0]}
                                    </p>
                                </TableCell>
                                <TableCell align="right">
                                    <ButtonGroup variant="text" aria-label="Basic button group">
                                        <Link to={`/creations/${row.fromCreationId}`} className="link"><Button>Edycja
                                            kreacji</Button></Link>
                                        <Button onClick={() => {
                                            handleOpenCreationPreview(row.fromCreationId);
                                        }}>Podgląd kreacji</Button>
                                        <IconButton
                                            sx={{width: 60}}
                                            aria-label="fingerprint" color="primary"
                                            id="long-button"
                                            onClick={(actionEvent: React.MouseEvent<HTMLElement>) => {
                                                setOpenActionMenu(row.id)
                                                setAnchorElForActionMenu(actionEvent.currentTarget);
                                            }}
                                            aria-controls={openActionMenu ? 'long-menu' : undefined}
                                            aria-expanded={openActionMenu ? 'true' : undefined}
                                            aria-haspopup="true">
                                            <MoreVertIcon className="action-menu"/>
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorElForActionMenu}
                                            open={openActionMenu === row.id}
                                            onClose={() => {
                                                setAnchorElForActionMenu(null);
                                                setOpenActionMenu(null)
                                            }}
                                            slotProps={{
                                                paper: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem onClick={async (event) => {
                                                try {
                                                    if (row.id) {
                                                        await stopEmission(row?.id).unwrap()
                                                        enqueueSnackbar('Pomyślnie zatrzymano emisje', {variant: 'success'});
                                                    }

                                                } catch (err: any) {
                                                    enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
                                                }
                                            }}>
                                                Zatrzymaj emisje
                                            </MenuItem>

                                        </Menu>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>)}

            <TablePagination
                rowsPerPageOptions={TABLE_ROW_LENGTH_OPTIONS}
                component="div"
                count={data.meta.count}
                rowsPerPage={rowLength}
                page={data.meta.page - 1}
                onPageChange={(e, page) => dispatch(setCreationsFilters({
                    ...currentFiltersState,
                    tagsNameIn: [],
                    draftMediaFormatIn: [],
                    stateIn: [],
                    draftIdEq: null,
                    page: page + 1
                }))}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>)}
    </Box>)
};

export default EmissionsList;