import {Box, Button, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Select, TablePagination, TextField} from "@mui/material";
import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DoneIcon from "@mui/icons-material/Done";
import {Link} from "react-router-dom";
import {TABLE_ROW_LENGTH_OPTIONS} from "../../../config/table";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {
    useDeleteVoohMutation,
    useGetVoohListsFiltersMetaQuery,
    useGetVoohPlaylistsQuery,
    useUpdateVoohPlaylistMutation
} from "../api/voohApiSlice";
import {DraftsFilters, FilterItemChip} from "../../drafts/types";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentFiltersState, setFilters} from "../store/voohSlice";
import {useGetDraftsQuery} from "../../drafts/api/draftsApiSlice";
import {Clear} from "@mui/icons-material";

import useDebounce from "../../../hooks/useDebounce";
import {
    IconButton,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import {Theme} from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import {VoohPlayListsFilters} from "../types";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {useDeleteCreationMutation} from "../../creations/api/creationsApiSlice";
import {setCreationsFilters} from "../../creations/store/creationsSlice";
import {enqueueSnackbar} from "notistack";
import ConfirmDialog from "../../shared/confirmDialog/ConfirmDialog";
import {setVisibleUploudBox} from "../../mediabank/store/mediabankResourcesSlice";

const VoohList = () => {


    const [searchByName, setSearchByName] = React.useState<string>('');
    const currentFiltersState = useSelector(selectCurrentFiltersState);

    const [rowLength, setRowLength] = React.useState(TABLE_ROW_LENGTH_OPTIONS[0]);
    const page = currentFiltersState.page;
    let params = [`items=${rowLength.toString()}`];
    let filterItemChips: FilterItemChip[] = []
    for (const queryKey in currentFiltersState) {
        const value = currentFiltersState[queryKey as keyof typeof currentFiltersState]
        if (Array.isArray(value)) {
            value.forEach(item => {
                params.push(`q[${queryKey}][]=${item.toString()}`)
                filterItemChips.push({name: queryKey, value: item, isArray: true})
            })
        } else if (queryKey === 'page') {
            params.push(`page=${value.toString()}`)
        } else if (value) {
            params.push(`q[${queryKey}]=${value.toString()}`)
            filterItemChips.push({name: queryKey, value: value.toString(), isArray: false})
        }
    }

    const {isLoading, isError, isSuccess, data} =
        useGetVoohPlaylistsQuery(params.join('&').toString());

    const [deleteVooh, {isLoading: deletingVooh}] = useDeleteVoohMutation()

    const [skipFetchingVooh, setSkipFetchingVooh] = React.useState(false);
    const [openRemoveVoohDialog, setOpenRemoveVoohDialog] = React.useState<string | null>(null)

    const {
        isLoading: loadingFiltersMeta,
        isError: isFiltersMetaError,
        isSuccess: isSuccessFiltersMeta,
        data: filtersMetaData
    } =
        useGetVoohListsFiltersMetaQuery();

    const dispatch = useDispatch();


    const voohLists = data?.data

    useDebounce(() => {
            dispatch(setFilters({
                ...currentFiltersState,
                nameCont: searchByName,
                page: 1
            }))
        }, [searchByName], 800
    );

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowLength(parseInt(event.target.value, 10))
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function removeFilter(filterElement: FilterItemChip) {
        if (filterElement.isArray) {
            dispatch(setFilters({
                ...currentFiltersState,
                page: 1,
                [filterElement.name]: (currentFiltersState[filterElement.name as keyof VoohPlayListsFilters] as string[]).filter(el => el !== filterElement.value)
            }))
        } else {
            if (filterElement.name === 'nameCont') {
                setSearchByName('');
            }
            dispatch(setFilters({
                ...currentFiltersState,
                page: 1,
                [filterElement.name]: ''
            }))
        }
    }

    const deleteVoohMutation = async (voohId: string) => {
        try {
            const isLastItem = voohLists?.length === 1;
            if (isLastItem && page > 0) {
                setSkipFetchingVooh(true);
            }
            const result = await deleteVooh(voohId).unwrap()
            if (!result?.error) {
                if (isLastItem && page > 0) {
                    setSkipFetchingVooh(false);
                    dispatch(setFilters({
                        ...currentFiltersState,
                        page: page - 1
                    }))
                }
                enqueueSnackbar('Pomyślnie usunięto ramówke', {variant: 'success'});
                setOpenRemoveVoohDialog(null)
            }
        } catch (err: any) {
            console.log(err)
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }




    return (<Box className="VoohList">
        <Box className={'page-header'}>
            <h1>Utworzone ramówki</h1>
        </Box>


        {(voohLists && filtersMetaData) && (<>
            <Grid container spacing={2}>
                <Grid item xs={4} alignItems="baseline">
                    <TextField
                        size="small"
                        value={searchByName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchByName(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setSearchByName('')
                                    dispatch(setFilters({
                                        ...currentFiltersState,
                                        nameCont: searchByName
                                    }))
                                }
                                }
                                edge="end"
                            >
                                {<Clear/>}
                            </IconButton>,
                        }}
                        margin="normal"
                        variant={"outlined"}
                        required
                        fullWidth
                        id="voohPlayListName"
                        label="Nazwa ramówki"
                        name="voohPlayListName"
                        autoFocus></TextField>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{marginTop: '15px'}}>
                        <InputLabel shrink id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            size="small"
                            variant={"outlined"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Status"
                            value={currentFiltersState.voohPlaylistStatusEq || ''}
                            notched
                            onChange={(event) => {
                                dispatch(setFilters({
                                    ...currentFiltersState,
                                    voohPlaylistStatusEq:event.target.value
                                }))
                            }}
                        >
                            {filtersMetaData.data.filtering.fields.voohPlaylistStatusEq.allowedValues.map((key) => (
                                <MenuItem
                                    key={key}
                                    value={key}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <DatePicker
                        maxDate={dayjs(new Date(currentFiltersState.endAtQteq))}
                        onChange={(event) => {
                            dispatch(setFilters({
                                ...currentFiltersState,
                                startAtLteq: dayjs(event).date() ? dayjs(event).format('YYYY-MM-DD') : ''
                            }))
                        }}
                        value={currentFiltersState.startAtLteq ? dayjs(currentFiltersState.startAtLteq) : null}
                        sx={{marginTop: '14px', width: '100%'}} slotProps={{ textField: { size: 'small' } }} label="Od" />
                </Grid>
                <Grid item xs={2}>
                    <DatePicker
                        minDate={currentFiltersState.startAtLteq ? dayjs(currentFiltersState.startAtLteq) : undefined}
                        onChange={(event) => {
                            dispatch(setFilters({
                                ...currentFiltersState,
                                endAtQteq: dayjs(event).date() ? dayjs(event).format('YYYY-MM-DD') : ''
                            }))
                        }}
                        sx={{marginTop: '14px', width: '100%'}} value={currentFiltersState.endAtQteq ? dayjs(currentFiltersState.endAtQteq) : null} slotProps={{ textField: { size: 'small' } }} label="Do" />
                </Grid>
                <Grid item xs={10}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">Tagi</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={currentFiltersState.tagsNameIn}
                            onChange={(event) => {
                                dispatch(setFilters({
                                    ...currentFiltersState,
                                    tagsNameIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                }))
                            }}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}

                            MenuProps={MenuProps}
                        >
                            {filtersMetaData.data.filtering.fields.tagsNameIn.allowedValues.map((tagsNameIn) => (
                                <MenuItem
                                    key={tagsNameIn}
                                    value={tagsNameIn}
                                >
                                    {tagsNameIn}
                                </MenuItem>
                            ))}
                            {
                                filtersMetaData.data.filtering.fields.tagsNameIn.allowedValues?.length === 0 && (
                                    <MenuItem
                                        disabled={true}
                                        value={''}
                                    >
                                        <em>Brak utworzonych tagów</em>
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                </Grid>
            </Grid>
            <Grid container spacing={2} pb={2}>
                {filterItemChips.map((filter) => {
                    return (
                        <Grid item key={filter.value}>
                            <Chip
                                color="info"
                                label={filter.name + ': ' + filter.value}
                                onClick={() => {
                                }}
                                onDelete={() => removeFilter(filter)}
                            />
                        </Grid>)
                })}
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa ramówki</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Tagi</TableCell>
                            <TableCell align="right">Data emisji</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {voohLists.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.voohPlaylistStatus}</TableCell>
                                <TableCell align="right">{row.tagList.join(', ')}</TableCell>
                                <TableCell align="right">
                                    <p>
                                        od {new Date(row.startAt).toISOString().split('T')[0]}
                                    </p>
                                    <p>
                                        do {new Date(row.endAt).toISOString().split('T')[0]}
                                    </p>
                                </TableCell>
                                <TableCell align="right" width={'5%'}>
                                    <ButtonGroup variant="text" aria-label="Basic button group">
                                        <Link to={`${row.id}`} className="link"><Button>Edycja
                                            Ramówki</Button></Link>
                                        <Button color="error" onClick={() => {
                                            setOpenRemoveVoohDialog(row.id.toString())
                                        }}>Usuń ramówkę</Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={TABLE_ROW_LENGTH_OPTIONS}
                component="div"
                count={data.meta.count}
                rowsPerPage={rowLength}
                page={data.meta.page - 1}
                onPageChange={(e, page) => dispatch(setFilters({
                    ...currentFiltersState,
                    page: page + 1
                }))}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {openRemoveVoohDialog && (<ConfirmDialog title={'Usuwanie vooh'}
                                                                    description={`Czy na pewno chcesz przerwać usunąć ramówke?`}
                                                                    confirmText={'Usuń'}
                                                                    open={!!openRemoveVoohDialog}
                                                                    onConfirm={() => {
                                                                        deleteVoohMutation(openRemoveVoohDialog)
                                                                    }}
                                                                    onCancel={() => {
                                                                        setOpenRemoveVoohDialog(null)

                                                                    }}></ConfirmDialog>)}

        </>)}
    </Box>)
}

export default VoohList