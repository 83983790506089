import React from 'react';
import {EmissionsStatusIconBoxProps} from "./types";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import HtmlTooltip from "../../features/shared/htmlTooltip/htmlTooltip";

const CmEmissionsStatus: React.FC<EmissionsStatusIconBoxProps> = ({emissionStatus}) => {
    const {t} = useTranslation();
    const sx = {display: 'flex', alignItems: 'center'}

    if (emissionStatus === 'planned') {
        return <Box sx={sx}>
            <HtmlTooltip title={<React.Fragment>
                <Typography
                    color="inherit">Zaplanowana</Typography>
            </React.Fragment>}>
                <div className={'IconBox IconBox--yellow'}><PlayCircleOutlineIcon></PlayCircleOutlineIcon></div>
            </HtmlTooltip>
        </Box>;
    }

    if (emissionStatus === 'active') {
        return <Box sx={sx}>
            <HtmlTooltip title={<React.Fragment>
                <Typography
                    color="inherit">Aktywna</Typography>
            </React.Fragment>}>
                <div className={'IconBox IconBox--green'}><PlayCircleOutlineIcon></PlayCircleOutlineIcon></div>
            </HtmlTooltip>
        </Box>;
    }

    if (emissionStatus === 'paused' || emissionStatus === 'stopped') {
        return <Box sx={sx}>
            <HtmlTooltip title={<React.Fragment>
                <Typography
                    color="inherit">Zarzymana</Typography>
            </React.Fragment>}>
                <div className={'IconBox IconBox--red'}><PlayCircleOutlineIcon></PlayCircleOutlineIcon></div>
            </HtmlTooltip>
        </Box>;
    }

    return <Box sx={sx}>
        <HtmlTooltip title={<React.Fragment>
            <Typography
                color="inherit">Zakończona</Typography>
        </React.Fragment>}>
            <div className={'IconBox IconBox--purple'}><PlayCircleOutlineIcon></PlayCircleOutlineIcon></div>
        </HtmlTooltip>
    </Box>;
}

export default CmEmissionsStatus;